/* eslint-disable global-require */
/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import globalReducer from 'containers/App/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import { reducer as masterTablesReducer} from 'academons-shared-library/src/Redux/MasterTablesRedux'
import productsReducer from './redux/products/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    products: productsReducer,
    language: languageProviderReducer,
    router: connectRouter(history),
    masterTables: masterTablesReducer,
    auth: require('academons-shared-library/src/Redux/AuthRedux').reducer,
    user: require('academons-shared-library/src/Redux/UserRedux').reducer,
    messages: require('academons-shared-library/src/Redux/MessageRedux').reducer,
    game: require('academons-shared-library/src/Redux/GameRedux').reducer,
    shop: require('academons-shared-library/src/Redux/ShopRedux').reducer,
    ranking: require('academons-shared-library/src/Redux/RankingRedux').reducer,
    challenges: require('academons-shared-library/src/Redux/ChallengesRedux').reducer,
    pokes: require('academons-shared-library/src/Redux/PokesRedux').reducer,
    transient: require('academons-shared-library/src/Redux/TransientDataRedux').reducer,
    statistic: require('academons-shared-library/src/Redux/StatisticRedux')
      .reducer,
    ...injectedReducers,
  });

  return rootReducer;
}
