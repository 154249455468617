/**
 *
 * ActionButtons
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../Button';
import Colors from '../../utils/Colors';

export const Wrapper = styled.div`
  min-height: 50px;
  padding: 10px;
  display: flex;
  width: 100%;
  max-width: 600px;
  justify-content: space-evenly;
`;

const CommonBtn = styled(Button)`
  width: 300px;
  min-width: 100px !important;
  margin: 5px;
  padding: 16px 30px;
  @media only screen and (max-width: 621px) {
    padding: 5px;
  }
`;

const CancelBtn = styled(CommonBtn)`
  background-color: ${Colors.white};
  color: ${Colors.veryLightPink};
  border: 3px solid ${Colors.veryLightPink};

  &:hover {
    color: ${Colors.brownGrey};
    border: 3px solid ${Colors.brownGrey};
  }
  &:active,
  &:focus {
    background: white;
    color: ${Colors.brownGrey};
    border: 3px solid ${Colors.brownGrey};
  }
`;

function ActionButtons({
  doNotShow = false,
  onCancel,
  onSave,
  onSaveText = 'formComp.save',
  onCancelText = 'formComp.cancel',
  style = {},
  btnStyle = {},
  small,
}) {
  return (
    <Wrapper style={style}>
      {!doNotShow && (
        <>
          {onCancel && (
            <CancelBtn
              isButton
              onClick={onCancel}
              txt={onCancelText}
              style={btnStyle}
              small={small}
            />
          )}
          {onSave && (
            <CommonBtn isButton onClick={onSave} txt={onSaveText} css={btnStyle} small={small} />
          )}
        </>
      )}
    </Wrapper>
  );
}

ActionButtons.propTypes = {
  doNotShow: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onSaveText: PropTypes.string,
  style: PropTypes.object,
  btnStyle: PropTypes.object,
};

export default ActionButtons;
