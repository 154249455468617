import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute(props) {
  const { component: Component, auth, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props2 =>
        auth ? <Component {...props2} /> : <Redirect to={`/LoginScreen?redirect=${props.path}`} />
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
const mapStateToProps = state => ({
  auth: state.auth.accessToken,
});
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(PrivateRoute);
