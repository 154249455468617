import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	putTransientData: ['name', 'value'],
	deleteTransientData: ['name'],
});

export const TransientDataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	promotion: null,
});

/* ------------- Reducers ------------- */

// request the data from an api
export const putTransientData = (state, { name, value }) => {
	var o = {};
	o[name] = value;
	return state.merge(o);
};

export const deleteTransientData = (state, { name, value }) => {
	var o = {};
	o[name] = null;
	return state.merge(o);
};
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.PUT_TRANSIENT_DATA]: putTransientData,
	[Types.DELETE_TRANSIENT_DATA]: deleteTransientData,
});
