/**
 *
 * WindowSize
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

function WindowSize({ children, minSize, view, changedSize = () => {} }) {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    changedSize();
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return <>{width > minSize ? children : view}</>;
}

WindowSize.propTypes = {
  minSize: PropTypes.number.isRequired,
  view: PropTypes.any.isRequired,
  changedSize: PropTypes.func,
};

export default WindowSize;
