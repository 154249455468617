// eslint-disable-next-line no-restricted-globals
/**
 *
 * TitledBox
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StackLayout } from '../Layouts';
import Text from '../Text';
import Colors from '../../utils/Colors';

function TitledBox(props) {
  const { children, txt, title, titleBgColor, titleHeight, hideTitle, text } = props;
  return (
    <Box
      height={props.height}
      minHeight={props.minHeight}
      width={props.width}
      minWidth={props.minWidth}
    >
      {!hideTitle && (
        <Title titleBgColor={titleBgColor} height={titleHeight}>
          {title || <Text tagName="h2" preset="title2" textColor={Colors.white} txt={txt} text={text} />}
        </Title>
      )}
      {children}
    </Box>
  );
}

TitledBox.propTypes = {
  children: PropTypes.node,
  height: PropTypes.any,
  minHeight: PropTypes.any,
  width: PropTypes.any,
  minWidth: PropTypes.any,
  txt: PropTypes.string,
  text: PropTypes.string,
  titleBgColor: PropTypes.string,
  title: PropTypes.node,
  titleHeight: PropTypes.string,
  hideTitle: PropTypes.bool,
};

export default TitledBox;
const Title = styled.aside`
  height: ${p => (p.height ? p.height : '70px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0 0;
  background-color: ${p => p.titleBgColor || Colors.rosyPink};
  text-align: center;
`;
const Box = styled.article`
  border-radius: 20px;
  border: solid 1px var(--very-light-pink-two);
  background-color: white;
  flex: 1 0 610px;
  overflow: hidden;
  height: ${props =>
    isNaN(props.height) ? props.height : props.height ? `${props.height}px` : 'auto'};
  min-height: ${props =>
    isNaN(props.minHeight) ? props.minHeight : props.minHeight ? `${props.minHeight}px` : 'auto'};
  width: ${props => (isNaN(props.width) ? props.width : props.width ? `${props.width}px` : 'auto')};
  min-width: ${props =>
    isNaN(props.minWidth) ? props.minWidth : props.minWidth ? `${props.minWidth}px` : 'auto'};
`;
export const Body = styled(StackLayout)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30px;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
`;
