import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	rankingFriendsRequest: ['asignaturaid'],
	rankingFriendsSuccess: ['payload'],
	rankingFriendsFailure: null,
	rankingGlobalRequest: ['asignaturaid'],
	rankingGlobalSuccess: ['payload'],
	rankingGlobalFailure: null
})

export const RankingTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	data: null,
	fetching: null,
	payload: null,
	error: null
})

/* ------------- Reducers ------------- */

export const rankingFriendsRequest = (state) =>
	state.merge({fetchingFriends: true})

export const rankingFriendsSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetchingFriends: false, error: null, friends: payload.ranking})
}

export const rankingFriendsFailure = state =>
	state.merge({fetchingFriends: false, error: true})

export const rankingGlobalRequest = (state) =>
	state.merge({fetchingGlobal: true})

export const rankingGlobalSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetchingGlobal: false, error: null, global: payload.ranking, globalPosition: payload.miranking})
}

export const rankingGlobalFailure = state =>
	state.merge({fetchingGlobal: false, error: true})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.RANKING_FRIENDS_REQUEST]: rankingFriendsRequest,
	[Types.RANKING_FRIENDS_SUCCESS]: rankingFriendsSuccess,
	[Types.RANKING_FRIENDS_FAILURE]: rankingFriendsFailure,
	[Types.RANKING_GLOBAL_REQUEST]: rankingGlobalRequest,
	[Types.RANKING_GLOBAL_SUCCESS]: rankingGlobalSuccess,
	[Types.RANKING_GLOBAL_FAILURE]: rankingGlobalFailure
})
