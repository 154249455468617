const colorCodes = {
  veryhigh: {
    label: 'subject.veryHigh',
    color: 'darkgreen',
    lighten: '#d2eec5',
  },
  high: {
    label: 'subject.high',
    color: '#6bc93f',
    lighten: '#84ff84',
  },
  medium: {
    label: 'subject.medium',
    color: 'orange',
    lighten: '#ffe4b3',
  },
  low: {
    label: 'subject.low',
    color: 'red',
    lighten: '#ffb2b2',
  },
};

export default colorCodes;
