import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	profileSchoolSuccess: ['payload'],
})

export const SchoolTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	classes: [],
	schoolDetails: {},
	error: null,
	userDetails: {},
})


/* ------------- Reducers ------------- */

export const profileSchoolSuccess = (state, action) => {
	const {payload} = action
	return state.merge({
		fetching: false,
		error: null,
		classes: payload.info.classes,
		schoolDetails: payload.info.schoolDetails,
		userDetails: payload.info.userDetails,
		errorMessage: "",
	})
}

export const reducer = createReducer(INITIAL_STATE, {
	[Types.PROFILE_SCHOOL_SUCCESS]: profileSchoolSuccess,
})
