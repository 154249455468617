import { call, put } from 'redux-saga/effects';
import StudentAction from '../Redux/StudentRedux';

export function* deleteStudent(api, action) {
  const { studentId, classId } = action;
  const response = yield call(api.deleteStudent, studentId);
  // success?
  if (response.ok) {
    if (response.data.errno != 0) {
      yield put(StudentAction.studentDeleteFailure('Unsuccessful delete!'));
    } else {
      yield put(StudentAction.studentDeleteSuccess({ studentId, classId }));
    }
  } else {
    yield put(StudentAction.studentDeleteFailure());
  }
}
