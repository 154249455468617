const Colors = {
  tealBlue: 'var(--teal-blue)',
  red: 'var(--red)',
  rosyPink: 'var(--rosy-pink)',
  brownGrey: 'var(--brown-grey)',
  black: 'var(--black)',
  white: 'var(--white)',
  veryLightPink: 'var(--very-light-pink)',
  veryLightPinkTwo: 'var(--very-light-pink-two)',
  veryLightPinkThree: 'var(--very-light-pink-three)',
  mango: 'var(--mango)',
  mangoDark: 'var(--mango-dark)',
  deepSeaBlue: 'var(--deep-sea-blue)',
  alert: 'var(--alert)',

  lightGreen: '#99cc66',
  muddyGreen: '#79aa49',
  lightTeal: '#019BB5',
  darkGrey: '#666666',
  lightGrey: '#f5f4f4',
  normalGrey: '#ccc',
  blueGrey: '#666685',
  ashGrey: '#808080',
  cloudGrey: '#bab9b9',
  pink: '#ED758A',
  colorClouds: '#ecf0f1',
  colorSilver: '#bdc3c7',

  aventurero: '#00aeda',
  calculator: '#339a99',
  geek: '#339a99',
  lenguaraz: '#996533',
  bookworm: '#996533',
  naturalisto: '#6bbc41',
  rockstar: '#6869c9',
  superbrit: '#b10d14',
  junglestar: '#6bc93f',
  backpacker: '#00aeda',

  aventureroComplementary: '#e8bf19',
  calculatorComplementary: '#075b7e',
  geekComplementary: '#075b7e',
  lenguarazComplementary: '#4bbac3',
  bookwormComplementary: '#4bbac3',
  naturalistoComplementary: '#518cbb',
  rockstarComplementary: '#d3cb4a',
  superbritComplementary: '#afca38',
  junglestarComplementary: '#518cbb',
  backpackerComplementary: '#e8bf19',
  refuerzoComplementary: '#c3c7ad',

  aventureroTertiary: '#ffd21b',
  calculatorTertiary: '#08648a',
  geekTertiary: '#08648a',
  lenguarazTertiary: '#54cfd9',
  bookwormTertiary: '#54cfd9',
  naturalistoTertiary: '#5b9ed3',
  rockstarTertiary: '#d3cb4a',
  superbritTertiary: '#c1df3e',
  junglestarTertiary: '#5b9ed3',
  backpackerTertiary: '#ffd21b',

  aventureroDarken: '#006783',
  calculatorDarken: '#1f5c5c',
  geekDarken: '#1f5c5c',
  lenguarazDarken: '#5c3d1f',
  bookwormDarken: '#5c3d1f',
  naturalistoDarken: '#407127',
  rockstarDarken: '#d3cb4a',
  superbritDarken: '#6a080c',
  junglestarDarken: '#407127',
  backpackerDarken: '#006783',
};
export default Colors;
