/* eslint-disable */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import {call, put} from 'redux-saga/effects';
import StatisticAction from '../Redux/StatisticRedux';
import Strings from '../Config/Strings';

export function * getStatistics (api, action) {
	const {childId, subjectId} = action;

	let response;

	if (subjectId) {
		response = yield call(api.requestSpecificStats, childId, subjectId)
	} else {
		response = yield call(api.requestGeneralStats, childId)
	}

	if(!response.ok || response.data.errno != 0){
		yield put(StatisticAction.statisticFailure(Strings.GENERIC_ERROR))
	} else {
		yield put(StatisticAction.statisticSuccess(response.data))
	}
}
