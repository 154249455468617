/* eslint-disable */
import standardApi from "./standardApi";
import Environment from '../Config/Environment';


const create = () => {
	const api = standardApi();
	const getMasterData = (countryCode) =>
		api.get('/plataforma/basico/' + (countryCode || Environment.getCountry()))
	const getCountries = () =>
		api.get('/plataforma/paises/' + Environment.getLanguage())
	const trackEvent = (eventId,deviceid) =>
		api.post('/plataforma/evento/' + eventId,{deviceid})
	const trackShoppingEvent = (payload) =>
		api.post('/plataforma/resultado-compra', payload)

	return {
		apisauce: api,
		getMasterData,
		trackEvent,
		trackShoppingEvent,
		getCountries
	}
}

export default {
	create
}
