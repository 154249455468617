/* eslint-disable */
import standardApi from "./standardApi";


const create = () => {
	const api = standardApi();
	const getProducts = () => api.get('/stripe/products')
	const createCheckoutSession = (priceID, currency) => api.post('/stripe/create-checkout-session', {priceID})
	const createCustomerPortalSession = () => api.get('/stripe/customer-portal')
	return {
		apisauce: api,
		getProducts,
		createCheckoutSession,
		createCustomerPortalSession,
	}
}

export default {
	create
}
