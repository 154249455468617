/* eslint-disable */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import {call, put} from 'redux-saga/effects';
import TopicAction from '../Redux/TopicRedux';
import Strings from '../Config/Strings';

export function * getTopic (api, action) {
	const {classID, topicID} = action;

	const response = yield call(api.requestTopic, classID, topicID)

	if(!response.ok || response.data.errno != 0){
		yield put(TopicAction.topicFailure(Strings.GENERIC_ERROR))
	} else {
		yield put(TopicAction.topicSuccess(response.data))
	}
}

export function * updateMissionStatus (api, action) {
	const {classID, missionID, status} = action;
	const response = yield call(api.missionToggle, classID, missionID, status)

	if(!response.ok || response.data.errno != 0){
		yield put(TopicAction.missionStatusUpdateFailure(Strings.GENERIC_ERROR))
	} else {
		yield put(TopicAction.missionStatusUpdateSuccess({missionID, status}))
	}
}
