import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	statisticRequest: ['childId', 'subjectId'],
	statisticSuccess: ['payload'],
	statisticFailure: ['errorMessage'],
})

export const StatisticTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetching: false,
	error: null,
	name: '',
	id: null,
	diary: [],
	averageTime: '0.00',
	averageGrade: '0.00',
	averageForAllUsers: 0,
	weeklyTimeChart: [],
	resultsBar: [],
	errorMessage: "",
})
const pad = num => (num < 10 ? '0' : '') + num;

/* ------------- Reducers ------------- */

export const statisticRequest = (state, {childId, subjectId}) => state.merge({fetching: true, error: false, childId, subjectId})

export const statisticSuccess = (state, action) => {
	const {payload} = action
	const {nombre, porcentaje_diario, tiempo_medio, nota_media, tiempo_semanal, resultados, id, matches_day_avg_allusers} = payload.estadisticas

	const date = new Date();
	date.setDate(tiempo_semanal[0].dia);

	return state.merge({
		fetching: false,
		error: null,
		id,
		name: nombre,
		diary: porcentaje_diario,
		averageTime: tiempo_medio,
		averageGrade: nota_media,
		weeklyTimeChart: tiempo_semanal.map(item => {
			const obj = {
				x: `${pad(date.getDate())}/${pad(date.getMonth() + 1)}`,
				y: item.valor,
			};
			date.setDate(date.getDate() + 1);
			return obj;
		}),
		averageForAllUsers: matches_day_avg_allusers,
		resultsBar: resultados,
		errorMessage: ""
	})
}

export const statisticFailure = (state, action) =>
	state.merge({...INITIAL_STATE, fetching: false, error: true, errorMessage:action.errorMessage})

export const reducer = createReducer(INITIAL_STATE, {
	[Types.STATISTIC_REQUEST]: statisticRequest,
	[Types.STATISTIC_SUCCESS]: statisticSuccess,
	[Types.STATISTIC_FAILURE]: statisticFailure,
})
