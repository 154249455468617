/**
 *
 * CommonWrapper
 *
 */

import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import Text from '../../../components/Text';
import { FullHeightStackLayout } from '../../../components/Layouts';
import Img from '../../../components/Img';
import IMAGES from '../../../images';
import Colors from '../../../utils/Colors';
import ActionButtons from '../../../components/ActionButtons';
import Modal from '../../../components/Modal';
import BuyModal, { CategoryLimitHeader } from '../../BuyModal';
import ScrollTop from '../../../components/GoToTop';

const Tile = ({ id, onClick, icon, name, selected, tileSize, proUser, useGreyScale }) => {
  const [active, setActive] = useState(selected.includes(id));

  const onTileClick = () => {
    onClick(id);
    if (!proUser && selected.length > 0) {
      return;
    }
    setActive(!active);
  };

  useEffect(() => {
    setActive(selected.includes(id));
  }, [selected]);

  return (
    <Selector onClick={onTileClick}>
      <Icon
        usegreyscale={useGreyScale ? 'true' : 'false'}
        style={active ? ActiveCss : {}}
        src={IMAGES.categories[icon]}
        alt={name}
        tilesize={tileSize}
      />
      <Text preset="textXS" css={label}>
        {name}
      </Text>
    </Selector>
  );
};

function SelectCategoriesStep(props) {
  const {
    data,
    onNext,
    values = [],
    multiSelect = false,
    hideMainTitle = false,
    subTitle,
    onCancel,
    buttonVisible,
    wrapper,
    tileSize,
    proUser,
    onDisplayTop = null,
  } = props;
  const [valuesArr, setValuesArr] = useState([]);
  const [payModal, setPayModal] = useState(false);

  const onItemClick = id => {
    if (multiSelect) {
      const temp = valuesArr;

      if (!proUser) {
        setPayModal(true);
        return;
      }

      if (temp.includes(id)) {
        const index = temp.indexOf(id);
        temp.splice(index, 1);
        setValuesArr(temp);
      } else {
        temp.push(id);
        setValuesArr(temp);
      }
      // props.onUpdate(valuesArr);
    } else {
      onNext(id);
    }
  };

  useEffect(() => {
    setValuesArr(values);
  }, [values]);
  const El = wrapper || Container;
  return (
    <ScrollTop active={onDisplayTop}>
      <El direction="column">
        {!hideMainTitle && (
          <Text
            preset="title1"
            textColor={Colors.rosyPink}
            tagName="h2"
            txt="registration.subjectTitle2"
            txtValues={{ name: props.name }}
          />
        )}
        <Text
          preset="text"
          txtValues={{ name: props.name }}
          txt={subTitle || 'registration.subjectSubtitle2'}
        />
        <SelectorContainer>
          {data.map(c => (
            <Tile
              tileSize={tileSize}
              key={c.id}
              id={c.id}
              useGreyScale={multiSelect}
              onClick={onItemClick}
              icon={c.clasetrofeo}
              name={c.nombre}
              selected={values}
              proUser={proUser}
            />
          ))}
        </SelectorContainer>
        <ActionButtons
          onSave={() => onNext(valuesArr)}
          onCancel={onCancel}
          doNotShow={!buttonVisible}
        />
        <Modal modalIsOpen={payModal} onRequestClose={() => setPayModal(false)}>
          <BuyModal
            footer="buyProScreen2.subjectChangeFooter"
            header={<CategoryLimitHeader />}
            onRequestClose={() => setPayModal(false)}
          />
        </Modal>
      </El>
    </ScrollTop>
  );
}

SelectCategoriesStep.propTypes = {
  data: PropTypes.array.isRequired,
  onNext: PropTypes.func,
  name: PropTypes.string,
  values: PropTypes.array,
  hideMainTitle: PropTypes.bool,
  proUser: PropTypes.bool,
  subTitle: PropTypes.string,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
  buttonVisible: PropTypes.bool,
  multiSelect: PropTypes.bool,
  tileSize: PropTypes.number,
};

export default memo(SelectCategoriesStep);

const Container = styled(FullHeightStackLayout)`
  ${media.lessThan('medium')`
    padding: 20px 20px;
  `}
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 600px;
  margin-top: 20px;
  > * {
    margin: 0 15px 45px;
  }
  ${media.lessThan('medium')`
    width: 100%
    > * {
      margin: 0 8px 30px;
    }
  `}
`;

const Selector = styled.button`
  background-color: transparent;
  color: var(--black);
  text-align: center;
  padding: 0;
  outline: 0 none;
  border: 0 none;
  cursor: pointer;
  transition: opacity 300ms linear;
  &:hover,
  &:active {
    opacity: 0.5;

    img {
      filter: grayscale(0);
    }
  }
`;
const Icon = styled(Img)`
  width: ${p => `${p.tilesize}px` || '112px'};
  height: ${p => `${p.tilesize}px` || '112px'};
  filter: ${p => (p.usegreyscale === 'true' ? 'grayscale(100%)' : '')};
  opacity: ${p => (p.usegreyscale === 'true' ? 0.4 : 1)};

  ${media.lessThan('medium')`
    width: 80px;
    height: 80px;
  `}
`;
const label = css`
  margin-top: 10px;
`;
// eslint-disable-next-line no-unused-vars

const ActiveCss = {
  opacity: 1,
  filter: 'grayscale(0)',
};
