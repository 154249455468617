import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
import Colors from '../Config/ClassColorCodes';
import { missionStatusUpdateFailure, missionStatusUpdateReq, missionStatusUpdateSuccess } from './TopicRedux';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	subjectRequest: ['classID', 'subjectID'],
	subjectSuccess: ['payload'],
	subjectFailure: ['errorMessage'],
	subjectReset: null,
	topicStatusUpdateReq: ['classID', 'topicID', 'status'],
	topicStatusUpdateSuccess: ['payload'],
	topicStatusUpdateFailure: ['errorMessage'],
})

export const SubjectTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetching: false,
	error: null,
	errorMessage: "",
	id: null,
	name: '',
	icon: '',
	pieChart: [],
	barChart: [],
	minMaxChart: [],
	toggleChart: [],
	averageOfAboveAverage: 0,
	updatingTopic: false,
})


/* ------------- Reducers ------------- */

export const topicStatusUpdateReq = (state, {classID, topicID}) => state.merge({updatingTopic: true, error: false, topicID, classID})

export const topicStatusUpdateSuccess = (state, action) => {
	const {payload} = action;
	const {topicID, status} = payload;

	return state.merge({
		updatingTopic: false,
		error: null,
		toggleChart: JSON.parse(JSON.stringify(state.toggleChart)).map(item => {
			if (topicID === item.id) {
				item.status = !!status
			}
			return item
		}),
		errorMessage: ""})
}

export const topicStatusUpdateFailure = (state, action) =>
	state.merge({updatingTopic: false, error: true, errorMessage: 'TOPIC_STATUS_UPDATE_FAILED'})

export const subjectRequest = (state, {classID, subjectID}) => state.merge({fetching: true, error: false, classID, subjectID})

export const subjectSuccess = (state, action) => {
	const {payload} = action
	const {id, name, levelOfPrecission, individualAverageScore, clasetrofeo} = payload.info.subjectDetails;

	return state.merge({
		fetching: false,
		error: null,
		id,
		name,
		icon: clasetrofeo,
		pieChart: Object.keys(levelOfPrecission).map(key => ({
			angle: levelOfPrecission[key],
			label: levelOfPrecission[key] > 0 ? `${levelOfPrecission[key]}%` : '',
			color: Colors[key].color,
			legend: Colors[key].label,
		})),
		barChart: individualAverageScore,
		averageOfAboveAverage: levelOfPrecission.veryhigh + levelOfPrecission.high,
		toggleChart: payload.info.topics.map(({ id, name, status, students }) => {
			const played = students.reduce(
				(n, val) => n + (val.bplayed > 0 ? 1 : 0),
				0,
			);
			const avg = students.reduce((a, b) => ({
				averageScore: a.averageScore + b.averageScore,
			})).averageScore;

			return {
				label: name,
				id,
				status: !!status,
				value: played ? avg / played : null,
			}
		}),
		// minMaxChart: payload.info.topics.map(({ name, students }) => {
		// 	const played = students.reduce(
		// 		(n, val) => n + (val.bplayed > 0 ? 1 : 0),
		// 		0,
		// 	);
		// 	const avg = students.reduce((a, b) => ({
		// 		averageScore: a.averageScore + b.averageScore,
		// 	})).averageScore;
		//
		// 	return {
		// 		label: name,
		// 		value: played ? avg / played : null,
		// 		active: !!played,
		// 	};
		// }),
		errorMessage: ""})
}

export const subjectFailure = (state, action) =>
	state.merge({...INITIAL_STATE, fetching: false, error: true, errorMessage:action.errorMessage})

export const subjectReset = () => INITIAL_STATE

export const reducer = createReducer(INITIAL_STATE, {
	[Types.SUBJECT_REQUEST]: subjectRequest,
	[Types.SUBJECT_SUCCESS]: subjectSuccess,
	[Types.SUBJECT_FAILURE]: subjectFailure,
	[Types.SUBJECT_RESET]: subjectReset,
	[Types.TOPIC_STATUS_UPDATE_REQ]: topicStatusUpdateReq,
	[Types.TOPIC_STATUS_UPDATE_SUCCESS]: topicStatusUpdateSuccess,
	[Types.TOPIC_STATUS_UPDATE_FAILURE]: topicStatusUpdateFailure,
})
