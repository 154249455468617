/**
 *
 * BuyModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Text, { presets } from '../../components/Text';
import Img from '../../components/Img';
import Colors from '../../utils/Colors';

import Button from '../../components/Button';
import ilustracionEstadisticas from './ilustracion-estadisticas.png';
import ilustracionJuegos from './ilustracion-juegos-ilimitados.png';

import ilustracionFamilia from './ilustracion-familia.png';
import close from './aspa-de-cierre.png';
import cross from './x.png';
import check from './check.png';
import profile from '../UpgradesPage/icono-perfiles/icono-perfiles.png';
import gameLimit from '../UpgradesPage/icono-juegos-ilimitados/icono-juegos-ilimitados.png';
import limit from '../UpgradesPage/icono-candado/icono-candado.png';
import english from '../UpgradesPage/icono-ingles/icono-ingles.png';
import stats from '../UpgradesPage/icono-informes/icono-informes.png';
import dumbel from '../UpgradesPage/icono-mancuernas/icono-mancuernas.png';
import { subscribeRequest } from '../../redux/products/actions';
import AppConfig from '../../config/AppConfig';

function AbsHeader(props) {
  return (
    <Header>
      <Img src={props.image} alt="" />
      <div>
        <HeaderTitle preset="textXLBold" tagName="h2" textColor={Colors.white} txt={props.title} />
        <Text preset="textS" textColor={Colors.white} txt={props.text} />
      </div>
    </Header>
  );
}
export function StatisticsHeader() {
  return (
    <AbsHeader
      image={ilustracionEstadisticas}
      title="buyProScreen2.statsViewTitle"
      text="buyProScreen2.statsViewSubTitle"
    />
  );
}
export function GoProHeader() {
  return (
    <AbsHeader
      image={ilustracionJuegos}
      title="buyProScreen2.missionTitle"
      text="buyProScreen2.missionSubTitle"
    />
  );
}
export function GameLimitHeader() {
  return (
    <AbsHeader image={ilustracionJuegos} title="buyProScreen2.title2" text="buyProScreen2.txt2" />
  );
}
export function CategoryLimitHeader() {
  return (
    <AbsHeader
      image={ilustracionJuegos}
      title="buyProScreen2.subjectChangeTitle"
      text="buyProScreen2.subjectChangeSubtitle"
    />
  );
}
export function MultipleChildrenHeader() {
  return (
    <AbsHeader image={ilustracionFamilia} title="buyProScreen2.title4" text="buyProScreen2.txt4" />
  );
}
function BuyModal(props) {
  const { products } = props;
  const closeHandler = e => {
    e.preventDefault();
    props.onRequestClose();
  };
  const subscribe = priceId => {
    props.subscribe(priceId);
  };
  const monthly = products.filter(x => x.type === AppConfig.stripeMonthlyPlanId)[0];
  const yearly = products.filter(x => x.type === AppConfig.stripeYearlyPlanId)[0];
  const moneyFormatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: monthly.currency,
    currencyDisplay: 'code',
  });
  return (
    <ContainerWrapper>
      <Container>
        {props.header}
        <Body>
          <Table>
            <thead>
              <tr>
                <th />
                <Text tagName="th" preset="textXSBold" txt="buyProScreen2.th1" />
                <Text tagName="th" preset="textXSBold" txt="buyProScreen2.th2" />
              </tr>
            </thead>
            <tbody css={presets.textXXS}>
              <tr>
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td11" />
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td12" />
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td13" />
              </tr>
              <tr>
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td21" />
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td22" />
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td23" />
              </tr>
              <tr>
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td31" />
                <td>
                  <Img src={cross} alt="No incluido" />
                </td>
                <td>
                  <Img src={check} alt="Incluido" />
                </td>
              </tr>
              <tr>
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td41" />
                <td>
                  <Img src={cross} alt="No incluido" />
                </td>
                <td>
                  <Img src={check} alt="Incluido" />
                </td>
              </tr>
              <tr>
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td51" />
                <td>
                  <Img src={cross} alt="No incluido" />
                </td>
                <td>
                  <Img src={check} alt="Incluido" />
                </td>
              </tr>
              <tr>
                <Text tagName="td" preset="textXXS" txt="buyProScreen2.td61" />
                <td>
                  <Img src={cross} alt="No incluido" />
                </td>
                <td>
                  <Img src={check} alt="Incluido" />
                </td>
              </tr>
            </tbody>
          </Table>
          <div>
            <PlanBox className="best-offer">
              <Text preset="textXS" tagName="h3" txt="buyProScreen2.annual" />
              <Precio>
                {`${moneyFormatter.format(yearly.priceDec / 12)} `}
                <Text
                  preset="text"
                  textColor={Colors.black}
                  tagName="span"
                  txt="buyProScreen2.month"
                />
              </Precio>
              <Text
                preset="textXXS"
                textColor="#666666"
                txt="buyProScreen2.every"
                txtValues={{ price: moneyFormatter.format(yearly.priceDec) }}
              />
              <Button isButton small txt="buyProScreen2.try" onClick={() => subscribe(yearly.id)} />
              <TagLabel>
                <Text preset="textXXS" tagName="span" txt="buyProScreen2.save" /> 50%
              </TagLabel>
            </PlanBox>
            <PlanBox>
              <Text preset="textXS" tagName="h3" txt="buyProScreen2.monthly" />
              <Precio>
                {`${moneyFormatter.format(monthly.priceDec)} `}
                <Text
                  preset="text"
                  textColor={Colors.black}
                  tagName="span"
                  txt="buyProScreen2.month"
                />
              </Precio>
              <Button
                isButton
                small
                txt="buyProScreen2.try"
                onClick={() => subscribe(monthly.id)}
              />
            </PlanBox>
          </div>
        </Body>
        <ContentWrapper>
          <PayCon>
            <PayConInner>
              <ValuePointsPay>
                <div>
                  <div>
                    <picture>
                      <source media="(min-width:465px)" srcSet={profile} />
                      <Img src={check} alt="profiles" />
                    </picture>
                  </div>
                  <PayedText preset="textXS" txt="upgradePage.payedPlanOpt1" />
                  <PayedTextSmall preset="textXS" txt="upgradePage.payedPlanOpt1Small" />
                </div>
                <div>
                  <div>
                    <picture>
                      <source media="(min-width:465px)" srcSet={gameLimit} />
                      <Img src={check} alt="gameLimit" />
                    </picture>
                  </div>
                  <PayedText preset="textXS" txt="upgradePage.payedPlanOpt2" />
                  <PayedTextSmall preset="textXS" txt="upgradePage.payedPlanOpt2Small" />
                </div>
                <div>
                  <div>
                    <picture>
                      <source media="(min-width:465px)" srcSet={limit} />
                      <Img src={check} alt="limited" />
                    </picture>
                  </div>
                  <PayedText preset="textXS" txt="upgradePage.payedPlanOpt3" />
                  <PayedTextSmall preset="textXS" txt="upgradePage.payedPlanOpt3Small" />
                </div>
              </ValuePointsPay>
              <PlanBoxWrapper>
                <PlanBox className="best-offer">
                  <Text preset="textXS" tagName="h3" txt="buyProScreen2.annual" />
                  <div>
                    <Precio>
                      {`${moneyFormatter.format(yearly.priceDec / 12)} `}
                      <Text
                        preset="text"
                        textColor={Colors.black}
                        tagName="span"
                        txt="buyProScreen2.month"
                      />
                    </Precio>
                    <BuyProText
                      preset="textXXS"
                      textColor="#666666"
                      txt="buyProScreen2.every"
                      txtValues={{ price: moneyFormatter.format(yearly.priceDec) }}
                    />
                  </div>
                  <Btn
                    isButton
                    small
                    txt="buyProScreen2.try"
                    onClick={() => subscribe(yearly.id)}
                  />
                  <TagLabel>
                    <Text preset="textXXS" tagName="span" txt="buyProScreen2.save" /> 50%
                  </TagLabel>
                </PlanBox>
              </PlanBoxWrapper>
            </PayConInner>
            <PayConInner>
              <ValuePointsPay>
                <div>
                  <div>
                    <picture>
                      <source media="(min-width:465px)" srcSet={english} />
                      <Img src={check} alt="english" />
                    </picture>
                  </div>
                  <PayedText preset="textXS" txt="upgradePage.payedPlanOpt4" />
                  <PayedTextSmall preset="textXS" txt="upgradePage.payedPlanOpt4Small" />
                </div>
                <div>
                  <div>
                    <picture>
                      <source media="(min-width:465px)" srcSet={stats} />
                      <Img src={check} alt="stats" />
                    </picture>
                  </div>
                  <PayedText preset="textXS" txt="upgradePage.payedPlanOpt5" />
                  <PayedTextSmall preset="textXS" txt="upgradePage.payedPlanOpt5Small" />
                </div>
                <div>
                  <div>
                    <picture>
                      <source media="(min-width:465px)" srcSet={dumbel} />
                      <Img src={check} alt="dumbel" />
                    </picture>
                  </div>
                  <PayedText preset="textXS" txt="upgradePage.payedPlanOpt6" />
                  <PayedTextSmall preset="textXS" txt="upgradePage.payedPlanOpt6Small" />
                </div>
              </ValuePointsPay>
              <PlanBoxWrapper>
                <PlanBox>
                  <Text preset="textXS" tagName="h3" txt="buyProScreen2.monthly" />
                  <Precio>
                    {`${moneyFormatter.format(monthly.priceDec)}`}
                    <Text
                      preset="text"
                      textColor={Colors.black}
                      tagName="span"
                      txt="buyProScreen2.month"
                    />
                  </Precio>
                  <Btn
                    isButton
                    small
                    txt="buyProScreen2.try"
                    onClick={() => subscribe(monthly.id)}
                  />
                </PlanBox>
              </PlanBoxWrapper>
            </PayConInner>
          </PayCon>
        </ContentWrapper>
        <Footext
          preset="textXS"
          txt={props.footer || 'buyProScreen2.footext'}
          textColor={Colors.ashGrey}
        />
        <CloseButton>
          <Button unstyled isButton onClick={closeHandler}>
            <Img src={close} alt="Cerrar" />
          </Button>
        </CloseButton>
      </Container>
    </ContainerWrapper>
  );
}

BuyModal.propTypes = {
  onRequestClose: PropTypes.func,
};
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    subscribe(priceId) {
      dispatch(subscribeRequest(priceId));
    },
  };
}
const mapStateToProps = state => ({
  language: state.language,
  products: state.products.products,
});
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(BuyModal);

const ContainerWrapper = styled.div`
  max-width: 840px;

  @media only screen and (max-width: 825px) {
    max-width: 500px;
  }
`;

const Container = styled.div`
  border-radius: 20px;
  background-color: var(--very-light-pink-three);
  position: relative;
`;
const Header = styled.header`
  border-radius: 20px 20px 0 0;
  background-color: var(--teal-blue);
  height: 122px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  > div {
    flex: 1 1 auto;
    text-align: center;
    margin-left: 10px;
    margin-right: 40px;
  }

  @media only screen and (max-width: 892px) {
    height: auto;
    justify-content: center;
    flex-wrap: wrap;

    img {
      padding-top: 15px;
      max-height: 80px;
    }

    > div {
      margin: 0;

      h2 {
        font-size: 20px;
      }

      p {
        padding-bottom: 10px;
      }
    }
  }
`;
const HeaderTitle = styled(Text)`
  margin-bottom: 10px;
`;
const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
`;
const Body = styled.div`
  padding: 40px 40px 20px 40px;
  display: flex;
  flex-direction: row;
  > :first-child {
    margin-right: 15px;
  }
  > :last-child {
    margin-left: 15px;
  }
  > div {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    > * {
      flex: 1 1 100%;
    }
    > :first-child {
      margin-bottom: 10px;
    }
    > :last-child {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 825px) {
    display: none;
  }

  @media only screen and (max-height: 675px) {
    display: none;
  }
`;

const Table = styled.table`
  border-radius: 10px;
  border: solid 1px var(--very-light-pink-two);
  background-color: var(--white);
  width: 380px;

  > thead {
    border-bottom: 1px solid var(--very-light-pink-two);
    th {
      padding: 22px 0;
    }
  }
  td:first-child {
    text-align: right;
    padding: 0 20px;
  }
  > tbody {
    text-align: center;
    tr:last-child td {
      padding-bottom: 30px;
    }
    td {
      padding: 15px 15px;
      vertical-align: center;
    }
  }
`;

const Footext = styled(Text)`
  text-align: center;
  padding: 0 10px 10px 10px;

  @media only screen and (max-width: 500px) {
    font-size: 10px;
  }
`;

const TagLabel = styled.div`
  border-radius: 0 10px 10px 0;
  background-color: var(--teal-blue);
  position: absolute;
  top: 15px;
  left: 0;
  padding: 3px 8px;
  font-size: 9px;
  color: white;

  span {
    font-size: 9px;
  }

  @media only screen and (max-width: 736px) {
    span {
      display: none;
    }
  }
`;
const ContentWrapper = styled.div`
  display: none;
  > div {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  @media only screen and (max-width: 825px) {
    display: block;
  }

  @media only screen and (max-height: 675px) {
    display: block;
  }
`;

const PlanBoxWrapper = styled.div`
  width: 100%;
  padding: 10px;
  align-self: flex-end;
  height: 190px;
`;

const Btn = styled(Button)`
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: bold;

  @media only screen and (max-width: 465px) {
    padding: 5px 15px 5px 15px;
  }
`;

const PlanBox = styled.section`
  padding: 15px;
  object-fit: contain;
  border-radius: 10px;
  border: solid 1px var(--very-light-pink-two);
  background-color: var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  position: relative;
  &.best-offer {
    border: solid 1px var(--teal-blue);
  }

  @media only screen and (max-width: 465px) {
    padding: 5px;
  }
`;

const PayCon = styled.div`
  @media only screen and (max-width: 676px) {
    height: auto !important;
  }
`;

const PayConInner = styled.div`
  width: 50%;
  height: 413px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 676px) {
    height: auto;
  }
`;

const ValuePointsPay = styled.div`
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 0 0 0;
  @media only screen and (max-width: 465px) {
    height: 95px;
    margin-bottom: 10px !important;
  }

  > div {
    display: flex;
    padding: 0 20px;
    align-items: center;

    div {
      height: 40px;
      width: 40px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 465px) {
        height: 20px;
        width: 20px;
      }

      picture {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 465px) {
          height: 20px;
          width: 20px;
        }

        img {
          max-height: 40px;
          max-width: 40px;

          @media only screen and (max-width: 465px) {
            max-height: 15px;
            max-width: 15px;
          }
        }
      }
    }

    > p {
      height: fit-content;
    }
  }

  @media only screen and (max-width: 500px) {
    margin: 10px 0 0 0;

    > div {
      padding: 0 10px;
    }
  }
`;

const Precio = styled(Text)`
  font-family: var(--text-fonts);
  font-size: 30px;
  color: var(--teal-blue);

  @media only screen and (max-width: 465px) {
    font-size: 20px;
  }
`;

const PayedText = styled(Text)`
  @media only screen and (max-width: 465px) {
    display: none;
  }
`;

const PayedTextSmall = styled(Text)`
  display: none;

  @media only screen and (max-width: 465px) {
    display: block;
  }
`;

const BuyProText = styled(Text)`
  @media only screen and (max-width: 465px) {
    font-size: 10px;
  }
`;
