/**
 *
 * Avatar
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IMAGES from '../../images';
// import styled from 'styled-components';

function Avatar(props) {
  const { model, ...rest } = props;
  const bodyImg = IMAGES.avatar.body[model?.body?.toString() + model?.color?.toString()];
  const legsImg = IMAGES.avatar.legs[model?.legs?.toString()];
  const eyesImg = IMAGES.avatar.eyes[model?.eyes?.toString()];
  const mouthImg = IMAGES.avatar.mouth[model?.mouth?.toString()];
  const backgroundImage = `url(${legsImg}),
                      url(${eyesImg}),
                      url(${mouthImg}),
                      url(${bodyImg})
  `;
  const styles = {
    width: props.size,
    height: props.size,
    backgroundImage,
    backgroundSize: 'contain',
  };
  return <div style={styles} {...rest} />;
}

Avatar.propTypes = {
  size: PropTypes.number.isRequired,
  model: PropTypes.object,
};
Avatar.defaultProps = {
  model: {
    body: 0,
    color: 0,
    eyes: 0,
    mouth: 0,
    legs: 0,
  },
};
export default memo(Avatar);
