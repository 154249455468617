/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectCategoryId = state => state.game.category;
const selectVisibleCategories = state => state.game.player ? state.game.player.asignaturas_visibles : [];

const makeSelectCurrentCategory = () =>
  createSelector(
    [selectCategoryId, selectVisibleCategories],
    (selectedCategoryId, categories) => {
      const catId = selectedCategoryId || categories[0]?.id;
      const category = categories.filter(val => val?.id == catId)[0]
      return category;
    },
  );

export { selectVisibleCategories, makeSelectCurrentCategory };
