const Environment = {
	init(config) {
		console.log("Environment configured")
		this.config = config;
	},
	getPlatform() {
		return this.config.getPlatform();
	},
	getOS() {
		return this.config.getOS();
	},
	getCountry() {
		return this.config.getCountry();
	},
	getLanguage() {
		return this.config.getLanguage();
	},
	getApiMonitor() {
		return this.config.getApiMonitor();
	},
	getDeviceId() {
		return this.config.getDeviceId();
	},
	createNavigationAction(route) {
		return this.config.createNavigationAction(route);
	},
	createBackNavigationAction() {
		return this.config.createBackNavigationAction();
	},
	createReplaceNavigationAction(key, routeName) {
		return this.config.createReplaceNavigationAction(key, routeName);
	},
	getStartupAction() {
		return this.config.getStartupAction();
	},
	getPostLoginAction() {
		return this.config.getPostLoginAction();
	}
}

export default Environment;
