import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	profileRequest: ['role'],
	profileSuccess: ['payload'],
	profileFailure: null,
	fatherRegistrationRequest: ['name', 'email', 'password','username', 'course', 'categories', 'childid', 'country'],
	fatherRegistrationSuccess: ['payload'],
	fatherRegistrationFailure: ['errorMessage'],
	fatherUpdateRequest: ['name', 'email','frecuencia','password'],
	fatherUpdateSuccess: ['payload'],
	fatherUpdateFailure: ['errorMessage'],
	fatherDeleteRequest: ['id'],
	fatherDeleteSuccess: ['payload'],
	fatherDeleteFailure: ['errorMessage'],
	childUpdateRequest: ['id','name', 'categories','skipRedirect'],
	childUpdateSuccess: ['payload'],
	childUpdateFailure: ['errorMessage'],
	childRegistrationRequest: ['username', 'course','categories','redirect'],
	childRegistrationSuccess: ['payload'],
	childRegistrationFailure: ['errorMessage'],
	childDeleteRequest: ['idusuario'],
	childDeleteSuccess: ['payload'],
	childDeleteFailure: ['errorMessage'],
	updateAvatarRequest: ['idusuario','body','color','eyes','mouth','legs'],
	updateAvatarSuccess: ['payload'],
	updateAvatarFailure: ['errorMessage'],
	friendshipRequestRequest: ['nick'],
	friendshipRequestSuccess: ['payload'],
	friendshipRequestFailure: ['errorMessage'],
	clearFriendshipRequest: [],
	friendshipAcceptRequest: ['petitionid'],
	friendshipAcceptSuccess: ['payload'],
	friendshipAcceptFailure: ['errorMessage'],
	friendshipRejectRequest: ['petitionid'],
	friendshipRejectSuccess: ['payload'],
	friendshipRejectFailure: ['errorMessage'],
	friendshipDeleteRequest: ['friendid'],
	friendshipDeleteSuccess: ['payload'],
	friendshipDeleteFailure: ['errorMessage'],
	markMessageAsReadRequest: ['categoryId'],
	markMessageAsReadSuccess: ['payload'],
	markMessageAsReadFailure: ['errorMessage'],
	markNotificationAsReadRequest: ['notificationId'],
	markNotificationAsReadSuccess: ['payload'],
	markNotificationAsReadFailure: ['errorMessage'],
	updateCourseRequest: ['childId','courseId','skipRedirect'],
	updateCourseSuccess: ['payload'],
	updateCourseFailure: ['errorMessage'],
	clearUserInAppNotification: [],
	setPrefInstructions: ['readed'],
	selectEdit: ['child'],
	setPro: ['pro'],
	clearUser: null,
	clearError: null,
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	profile: null,
	fetching: null,
	registering: false,
	error: null,
	children: [],
	edit: null,
	instructionsReaded: false
})


/* ------------- Reducers ------------- */

export const profileRequest = (state, {role}) =>
	state.merge({fetching: true})

export const profileSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetching: false, error: null, profile: payload, errorMessage: ""})
}

export const profileFailure = state =>
	state.merge({fetching: false, error: true})


export const fatherRegistrationRequest = (state, {name,username,password}) =>
	state.merge({registering: true})

export const fatherRegistrationSuccess = (state, action) => {
	return state.merge({registering: false, error: null})
}

export const fatherRegistrationFailure = (state, action) =>
	state.merge({registering: false, error: true, errorMessage:action.errorMessage})


export const fatherUpdateRequest = (state, {frecuencia}) =>
	state.merge({
		fetching: true,
		profile:{
			info:{
				frecuenciaemail:frecuencia ? frecuencia : state.profile.info.frecuenciaemail
			}
		}
	},{deep: true})

export const fatherUpdateSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}

export const fatherUpdateFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const fatherDeleteRequest = (state) =>
	state.merge({fetching: true})

export const fatherDeleteSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}

export const fatherDeleteFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const childUpdateRequest = (state) =>
	state.merge({fetching: true})

export const childUpdateSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}

export const childUpdateFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})


export const childRegistrationRequest = (state, {name,curse,categories}) =>
	state.merge({fetching: true})

export const childRegistrationSuccess = (state, action) => {
	let { info } = action.payload
	return state.merge({ fetching: false, error: null, children: state.children.concat([info]), profile: state.profile.merge({ hijos: state.profile.hijos.concat([info]) }) })
}

export const childRegistrationFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})


export const updateAvatarRequest = (state) =>
	state.merge({fetching: true})

export const updateAvatarSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}

export const updateAvatarFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})


export const childDeleteRequest = (state, {name,curse,categories}) =>
	state.merge({fetching: true})

export const childDeleteSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}

export const childDeleteFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const friendshipRequestRequest = (state, action) =>
	state.merge({fetching: true, friendshipRequestedName: action.nick})

export const friendshipRequestSuccess = (state, action) => {
	return state.merge({fetching: false, error: null, friendshipRequested:true})
}

export const friendshipRequestFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const friendshipAcceptRequest = (state) =>
	state.merge({fetching: true})

export const friendshipAcceptSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}
export const clearFriendshipRequest = (state) => {
	return state.merge({friendshipRequested:false})
}
export const friendshipAcceptFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const friendshipRejectRequest = (state) =>
	state.merge({fetching: true})

export const friendshipRejectSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}

export const friendshipRejectFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const friendshipDeleteRequest = (state) =>
	state.merge({fetching: true})

export const friendshipDeleteSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}

export const friendshipDeleteFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const clearUser = (state, action) => INITIAL_STATE

export const clearError = (state, action) =>
	state.merge({fetching: false, error: false, errorMessage:action.errorMessage})

export const setPrefInstructions = (state, {readed}) =>
	state.merge({instructionsReaded: readed})

export const selectEdit = (state, {child}) => {
	return state.merge({edit: child})
}
export const setPro = (state, action) => {
	const { pro } = action
	return state.merge({fetching: false, error: null, profile: { info: {es_pro: pro}}},{deep: true})
}
export const markMessageAsReadRequest = (state) =>
	state.merge({fetching: true})

export const markMessageAsReadSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}
export const markMessageAsReadFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const markNotificationAsReadRequest = (state) =>
	state.merge({fetching: true})

export const markNotificationAsReadSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}
export const markNotificationAsReadFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage: null})

export const updateCourseRequest = (state) =>
	state.merge({fetching: true})

export const updateCourseSuccess = (state, action) => {
	return state.merge({fetching: false, error: null})
}

export const updateCourseFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const clearUserInAppNotification = (state, action) => {
	const { pro } = action
	return state.merge({fetching: false, error: null, profile: { info: {notificacion: {pendiente:false}}}},{deep: true})
}
export const reducer = createReducer(INITIAL_STATE, {
	[Types.PROFILE_REQUEST]: profileRequest,
	[Types.PROFILE_SUCCESS]: profileSuccess,
	[Types.PROFILE_FAILURE]: profileFailure,
	[Types.FATHER_REGISTRATION_REQUEST]: fatherRegistrationRequest,
	[Types.FATHER_REGISTRATION_SUCCESS]: fatherRegistrationSuccess,
	[Types.FATHER_REGISTRATION_FAILURE]: fatherRegistrationFailure,
	[Types.FATHER_UPDATE_REQUEST]: fatherUpdateRequest,
	[Types.FATHER_UPDATE_SUCCESS]: fatherUpdateSuccess,
	[Types.FATHER_UPDATE_FAILURE]: fatherUpdateFailure,
	[Types.FATHER_DELETE_REQUEST]: fatherDeleteRequest,
	[Types.FATHER_DELETE_SUCCESS]: fatherDeleteSuccess,
	[Types.FATHER_DELETE_FAILURE]: fatherDeleteFailure,
	[Types.CHILD_UPDATE_REQUEST]: childUpdateRequest,
	[Types.CHILD_UPDATE_SUCCESS]: childUpdateSuccess,
	[Types.CHILD_UPDATE_FAILURE]: childUpdateFailure,
	[Types.CHILD_REGISTRATION_REQUEST]: childRegistrationRequest,
	[Types.CHILD_REGISTRATION_SUCCESS]: childRegistrationSuccess,
	[Types.CHILD_REGISTRATION_FAILURE]: childRegistrationFailure,
	[Types.CHILD_DELETE_REQUEST]: childDeleteRequest,
	[Types.CHILD_DELETE_SUCCESS]: childDeleteSuccess,
	[Types.CHILD_DELETE_FAILURE]: childDeleteFailure,
	[Types.UPDATE_AVATAR_REQUEST]: updateAvatarRequest,
	[Types.UPDATE_AVATAR_SUCCESS]: updateAvatarSuccess,
	[Types.UPDATE_AVATAR_FAILURE]: updateAvatarFailure,
	[Types.FRIENDSHIP_REQUEST_REQUEST]: friendshipRequestRequest,
	[Types.FRIENDSHIP_REQUEST_SUCCESS]: friendshipRequestSuccess,
	[Types.FRIENDSHIP_REQUEST_FAILURE]: friendshipRequestFailure,
	[Types.CLEAR_FRIENDSHIP_REQUEST]: clearFriendshipRequest,
	[Types.FRIENDSHIP_ACCEPT_REQUEST]: friendshipAcceptRequest,
	[Types.FRIENDSHIP_ACCEPT_SUCCESS]: friendshipAcceptSuccess,
	[Types.FRIENDSHIP_ACCEPT_FAILURE]: friendshipAcceptFailure,
	[Types.FRIENDSHIP_REJECT_REQUEST]: friendshipRejectRequest,
	[Types.FRIENDSHIP_REJECT_SUCCESS]: friendshipRejectSuccess,
	[Types.FRIENDSHIP_REJECT_FAILURE]: friendshipRejectFailure,
	[Types.FRIENDSHIP_DELETE_REQUEST]: friendshipDeleteRequest,
	[Types.FRIENDSHIP_DELETE_SUCCESS]: friendshipDeleteSuccess,
	[Types.FRIENDSHIP_DELETE_FAILURE]: friendshipDeleteFailure,
	[Types.MARK_MESSAGE_AS_READ_REQUEST]: markMessageAsReadRequest,
	[Types.MARK_MESSAGE_AS_READ_SUCCESS]: markMessageAsReadSuccess,
	[Types.MARK_MESSAGE_AS_READ_FAILURE]: markMessageAsReadFailure,
	[Types.MARK_NOTIFICATION_AS_READ_REQUEST]: markNotificationAsReadRequest,
	[Types.MARK_NOTIFICATION_AS_READ_SUCCESS]: markNotificationAsReadSuccess,
	[Types.MARK_NOTIFICATION_AS_READ_FAILURE]: markNotificationAsReadFailure,
	[Types.UPDATE_COURSE_REQUEST]: updateCourseRequest,
	[Types.UPDATE_COURSE_SUCCESS]: updateCourseSuccess,
	[Types.UPDATE_COURSE_FAILURE]: updateCourseFailure,
	[Types.SET_PREF_INSTRUCTIONS]: setPrefInstructions,
	[Types.SELECT_EDIT]: selectEdit,
	[Types.SET_PRO]: setPro,
	[Types.CLEAR_USER]: clearUser,
	[Types.CLEAR_ERROR]: clearError,
	[Types.CLEAR_USER_IN_APP_NOTIFICATION]: clearUserInAppNotification,
})
