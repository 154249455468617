/* eslint-disable prettier/prettier */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import {call, put, putResolve} from 'redux-saga/effects'
import ShopActions from '../Redux/ShopRedux'
import Strings from '../Config/Strings'
import UserActions  from '../Redux/UserRedux';

export function * getList(api,action){
	const {asignaturaId} = action;
	const response = yield call(api.getList, asignaturaId)
	if(response.data.errno != 0){
		yield put(ShopActions.getListFailure(Strings.GENERIC_ERROR))
	}
	else{
		yield put(ShopActions.getListSuccess({list:response.data.secciones, neuros: response.data.neuros}));
	}
}

export function * getInfoNeuros(api,action){
	const {asignaturaId} = action;
	const response = yield call(api.getInfoNeuros, asignaturaId)
	if(response.data.errno != 0){
		yield put(ShopActions.getInfoNeurosFailure(Strings.GENERIC_ERROR))
	}
	else{
		yield put(ShopActions.getInfoNeurosSuccess({neuros: response.data.neuros}));
	}
}

export function * buyObject(api,action){
	const {objId, asignaturaId, useObjects, next} = action;
	const apiCall = typeof objId === 'object' ? api.buyObjects : api.buyObject
	const response = yield call(apiCall, objId, asignaturaId)
	if(response.data.errno != 0 && response.data.errno != 56){
		console.log("BUY OBJECT", response)
		yield put(ShopActions.buyObjectFailure(Strings.GENERIC_ERROR))
		yield put(UserActions.profileRequest())
	}
	else{
		console.log("SHOPSAGAS",response.data)
		yield put(ShopActions.buyObjectSuccess({ neuros: response.data.neuros}));
		yield put(ShopActions.getListRequest(asignaturaId));
		if(useObjects) {
			yield put(ShopActions.useObjectRequest(useObjects,asignaturaId, next));
		}
		else if(next) {
			yield call(next);
		}
	}
}

export function * useObject(api,action){
	const {objId, asignaturaId, next} = action;
	const apiCall = typeof objId === 'object' ? api.useObjects : api.useObject
	const response = yield call(apiCall, objId, asignaturaId)
	if(response.data.errno != 0){
		console.log("USE OBJECT", response)
		yield put(ShopActions.useObjectFailure(Strings.GENERIC_ERROR))
	}
	else{
		console.log("USE OBJECT",response.data)
		yield putResolve(UserActions.profileRequest());
		yield putResolve(ShopActions.useObjectSuccess({}));
		if(next) {
			yield call(next)
		}

	}
}

export function * delObject(api,action){
	const {objId, asignaturaId} = action;
	const apiCall = typeof objId === 'object' ? api.delObjects : api.delObject
	const response = yield call(apiCall, objId, asignaturaId)
	if(response.data.errno != 0){
		console.log("DEL OBJECT", response)
		yield put(ShopActions.delObjectFailure(Strings.GENERIC_ERROR))
	}
	else{
		console.log("DEL OBJECT",response.data)
		yield put(UserActions.profileRequest());
		yield put(ShopActions.delObjectSuccess({}));
	}
}
