import styled from 'styled-components';

import buttonStyles, { smallButtonStyles, xSmallButtonStyles } from './buttonStyles';

const StyledButton = styled.button`
  ${buttonStyles};
`;

export default StyledButton;

export const SmallStyledButton = styled.button`
  ${smallButtonStyles};
`;

export const XSmallStyledButton = styled.button`
  ${xSmallButtonStyles};
`;
