import standardApi from "./standardApi";


const create = () => {
	const api = standardApi();

	const getPokes = (categoryId) =>
		api.get(`/usuario/alumno/pokes/${categoryId}`)
	const getAllPokes = () =>
		api.get(`/usuario/alumno/todos-pokes`)
	const poke = (categoryId,to,type) =>
		api.post(`/usuario/alumno/poke/${categoryId}/${to}/${type}`)
	const markAsRead = (categoryId,type) =>
		api.post(`/usuario/alumno/pokes-leidos/${categoryId}/${type}`)

	return {
		apisauce: api,
		getPokes,
		poke,
		markAsRead,
		getAllPokes
	}
}

export default {
	create
}
