import Reactotron from 'reactotron-react-js';
import { push, goBack, replace } from 'connected-react-router';
import { webStartup, postLogin } from '../containers/App/actions';
import AppConfig from "./AppConfig";

const AppEnvironment = {
  getPlatform() {
    return 'web';
  },
  getCountry() {
    return AppConfig.country;
  },
  getLanguage() {
    return AppConfig.supportedLocales.indexOf(navigator.language) > -1 ?  navigator.language : 'es';
  },
  getApiMonitor() {
    return Reactotron.apisauce;
  },
  getDeviceId() {
    return null;//MediaDeviceInfo.deviceId;
  },
  createNavigationAction(route) {
    if (route.indexOf('/') !== 0) {
      route = `/${route}`;
    }
    return push(route);
  },
  createReplaceNavigationAction(route) {
    if (route.indexOf('/') !== 0) {
      route = `/${route}`;
    }
    return replace(route);
  },
  createBackNavigationAction() {
    return goBack();
  },
  getStartupAction() {
    return webStartup();
  },
  getPostLoginAction() {
    return postLogin();
  }
};
export default AppEnvironment;
