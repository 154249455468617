const NetworkConfig = {
	API_BASE:
		process.env.NODE_ENV !== 'production'
			? 'https://dev-api.academons.com'
			: 'https://sapi-edu.cerebriti.com',
	// API_BASE: 'https://sapi-edu.cerebriti.com',
	// API_BASE: 'https://dev-api.academons.com',
	// API_BASE: 'https://devapi-us.academons.com',
	DEFAULT_NETWORK_TIMEOUT: 30000,
	DEFAULT_HEADERS: {},
	AXIOS_CONFIG: {
		proxy: {
			host: '127.0.0.1',
			port: 8888,
		},
	},
};
console.log('Environment', process.env.NODE_ENV);
export default NetworkConfig;
