/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Environment from 'academons-shared-library/src/Config/Environment';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import A, { ASmall } from './A';
import StyledButton, { SmallStyledButton, XSmallStyledButton } from './StyledButton';
import { unstyledStyles } from './buttonStyles';
import LoadingIndicator from '../LoadingIndicator';

const LoadingWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Button(props) {
  const {
    href,
    onClick,
    children,
    isButton,
    isReplace,
    unstyled,
    navigation,
    txt,
    txtValues,
    small,
    xsmall,
    loading,
  } = props;
  // Render an anchor tag
  const navigate = event => {
    event.preventDefault();
    if (href === 'back' || href === '#back') {
      props.dispatch(Environment.createBackNavigationAction());
    } else if(isReplace) {
      props.dispatch(Environment.createReplaceNavigationAction(href));
    } else {
      props.dispatch(Environment.createNavigationAction(href));
    }
  };
  // eslint-disable-next-line no-nested-ternary
  let content = txt ? <FormattedMessage id={txt} values={txtValues} /> : Children.toArray(children);
  content = loading ? <span style={{ visibility: 'hidden' }}>{content}</span> : content;
  const clickHandler = navigation && href ? navigate : onClick;
  if (unstyled) {
    const sty = css`
      ${unstyledStyles}
      ${props.css}
    `;
    if (isButton) {
      return (
        <button css={sty} onClick={clickHandler}>
          {content}
        </button>
      );
    }
    return (
      <a href={href} {...props} css={sty} onClick={clickHandler}>
        {content}
      </a>
    );
  }
  if (isButton) {
    const B = small ? SmallStyledButton : xsmall ? XSmallStyledButton : StyledButton;
    return (
      <B onClick={clickHandler} {...props}>
        {loading ? (
          <LoadingWrapper>
            <LoadingIndicator />
          </LoadingWrapper>
        ) : null}
        {content}
      </B>
    );
  }
  const B = !small ? A : ASmall;
  return (
    <B href={href} onClick={clickHandler} {...props}>
      {content}
    </B>
  );
}

Button.propTypes = {
  handleRoute: PropTypes.func,
  isButton: PropTypes.bool,
  unstyled: PropTypes.bool,
  loading: PropTypes.bool,
  navigation: PropTypes.bool,
  isReplace: PropTypes.bool,
  href: PropTypes.string,
  txt: PropTypes.string,
  txtValues: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node,
  small: PropTypes.bool,
  xsmall: PropTypes.bool,
  transparent: PropTypes.bool,
};
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
export default connect(
  null,
  mapDispatchToProps,
)(Button);
