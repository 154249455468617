import AppConfig from 'academons-shared-library/src/Config/AppConfig';
import AppEnvironment from './AppEnvironment';

// eslint-disable-next-line no-underscore-dangle
const _AppConfig = {
  ...AppConfig,
  bugsnagKey: '9b18e14b3c7e0fa457e820522348068b',
  stripeKey: 'pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG',
  stripeYearlyPlanId: 'yearly',
  stripeMonthlyPlanId: 'monthly',
  useReactotron: process.env.ENV !== 'production' && typeof window === 'object',
  pageTransitionTime: 3000,
  gameArenaUrl: process.env.ENV !== 'production' ? 'https://dev-usa.academons.com/gameapi/index.php/gameapi' : 'https://academons.com/gameapi/index.php/gameapi',
  gameEndUrl: process.env.ENV !== 'production' ? 'https://dev-usa.academons.com/ws_endgame.php' : 'https://academons.com/ws_endgame.php',
  storageVersion: '3',
  country: "ES",
  supportedLocales: ['en', 'es'],
};
console.log("ENV", process.env.ENV)
console.log("NODE_ENV", process.env.NODE_ENV)
export default Object.assign(AppConfig, _AppConfig);
