/* eslint-disable prettier/prettier */
import {call, put, putResolve, select} from 'redux-saga/effects'
import ChallengesActions from '../Redux/ChallengesRedux'
import Strings from '../Config/Strings'
import AppConfig from '../Config/AppConfig'
import MessagesActions from '../Redux/MessageRedux'
import Environment from "../Config/Environment";

export function * getPendingChallenges (api, action) {
	const categoriaid = yield select(state => state.game.category);
	const response = yield call(api.getPending, categoriaid)

	// success?
	if (response.ok) {
		if (response.data.errno == 0) {
			yield put(ChallengesActions.challengesPendingSuccess(response.data.retos))
		}
		else{
			yield put(ChallengesActions.challengesPendingFailure())
		}
	} else {
		yield put(ChallengesActions.challengesPendingFailure())
	}
}
export function * challengeFriend (api, action) {
	const categoriaid = yield select(state => state.game.category);
	const {friendid} = action;
	const response = yield call(api.challengeFriend,categoriaid,friendid);

	const validErrors = [0,39,42]
	if(!response.ok || validErrors.indexOf(response.data.errno) == -1){
		yield put(ChallengesActions.challengeFriendFailure(response.data.errno))
	}
	else {
		yield put(ChallengesActions.challengeFriendSuccess(response.data))
	}
	if(response.ok && response.data.errno == 0){
		yield put(Environment.createNavigationAction('ChallengePresentationScreen'));
	}
}
export function * challengeRandom (api, action) {
	const categoriaid = yield select(state => state.game.category);
	const response = yield call(api.challengeRandom,categoriaid);

	const validErrors = [0,39,42]
	if(!response.ok || validErrors.indexOf(response.data.errno) == -1){
		yield put(ChallengesActions.challengeRandomFailure(response.data.errno))
	}
	else {
		response.data.info.tiporeto = "aleatorio";
		yield put(ChallengesActions.challengeRandomSuccess(response.data))
	}
	if(response.ok && response.data.errno == 0){
		yield put(Environment.createNavigationAction('ChallengePresentationScreen'));
	}
}
export function * getChallengeResult (api, action) {
	const {id, doReplace, key} = action;
	const response = yield call(api.getChallengeResult,id);

	if(!response.ok || response.data.errno != 0){
		yield put(ChallengesActions.challengeResultFailure(Strings.GENERIC_ERROR))
		AppConfig.bugsnag.notify(new Error("Error al obtener resultado del reto: " + key),function(report) {
			report.metadata = response;
		});
		yield put(MessagesActions.messageShow("Ocurrió un error al obtener el resultado del reto. Por favor, inténtalo mas tarde.","Error"));
	}
	else {
		yield putResolve(ChallengesActions.challengesPendingRequest());
		yield put(ChallengesActions.challengeResultSuccess(response.data))
		if(doReplace){
			yield put(Environment.createReplaceNavigationAction(key, 'ChallengeResultScreen'));
		}
		else{
			yield put(Environment.createNavigationAction('ChallengeResultScreen' ));
		}
	}
}
export function * rejectChallenge (api, action) {
	const id = yield select(state => state.challenges.current.id);
	const response = yield call(api.rejectChallenge, id);
	if(!response.ok || response.data.errno != 0){
		yield put(ChallengesActions.rejectChallengeFailure(Strings.GENERIC_ERROR))
	}
	else {
		yield putResolve(ChallengesActions.challengesPendingRequest());
		yield putResolve(Environment.createBackNavigationAction());
		setTimeout(() => put(ChallengesActions.rejectChallengeFailure()), 1000)
	}
}
