import { createGlobalStyle, css } from 'styled-components';
import media from 'styled-media-query';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  :root {
	  --teal-blue: #009bb5;
    --red: #ff3333;
    --rosy-pink: #e6788b;
	  --brown-grey: #999999;
	  --black: #333333;
	  --white: #ffffff;
	  --very-light-pink: #cccccc;
    --very-light-pink-two: #ebe8e8;
    --very-light-pink-three: #f5f4f4;
    --mango: #f9a825;
    --mango-light: #ffcc00;
    --mango-dark: #996402;
    --deep-sea-blue: #006486;
    --alert: #ff3c3c;

    --title-fonts: 'Fredoka One', cursive;
    --text-fonts: 'Lato', sans-serif;

    --header-height: 70px;
  }
  ${reset}
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    color: var(--black);
    font-family: var(--text-fonts);
    font-size: 18px;
  }
  a{
    color: var(--deep-sea-blue);
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
    color: var(--deep-sea-blue);
  }
  strong{
    font-weight: bold;
  }
  #app {
    background-color: var(--white);
    min-height: 100%;
    min-width: 100%;
  }
  // Removes Pure Carousel Blue outline
  // https://github.com/express-labs/pure-react-carousel/issues/203
  .focusRing___1airF.carousel__slide-focus-ring { outline: none !important; }

  ${[5, 10, 15, 20, 30, 40, 50].map(
    size => css`
      ${['top', 'right', 'bottom', 'left'].map(
        dir => css`
      .m${dir[0]}-${size}{
        margin-${dir}: ${size}px !important;
      }
    `,
      )}
    `,
  )}

  ${media.lessThan('medium')`
    ${[5, 10, 15, 20, 30, 40, 50].map(
      size => css`
        ${['top', 'right', 'bottom', 'left'].map(
          dir => css`
        .m${dir[0]}-${size}-m{
          margin-${dir}: ${size}px !important;
        }
      `,
        )}
      `,
    )}
  `}
  * {
    box-sizing: border-box;
  }

  .carousel .slide{
    background-color: transparent !important;
  }
  .carousel.carousel.carousel img{
    width: auto;
  }
  .no-scroll {
    overflow: hidden;
  }
  body.game-menu .main-header {
    display: none;
  }
  body:not(.game-menu) .menu-header {
    display: none;
  }
  body.hide-header{
    --header-height: 0px;
  }
  body.hide-header .menu-header, body.hide-header .main-header {
    display: none;
  }

  // Transitions
  .page {
    position: absolute;
    top: var(--header-height);
    left: 0;
    right: 0;
  }
  /* simple - enter transition 300ms, exit 150ms */
  .fade-appear,
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }
  .fade-appear-active,
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
  }
`;

export default GlobalStyle;
