import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	masterTablesRequest: ['lang'],
	masterTablesSuccess: ['payload'],
	masterTablesFailure: null,
	countriesRequest: [],
	countriesSuccess: ['payload'],
	countriesFailure: null
})

export const MasterTablesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	tables: null,
	countries: [],
	fetching: null,
	error: null,
	geoip: null
})

/* ------------- Reducers ------------- */

// request the data from an api
export const masterTablesRequest = (state, { data }) =>
	state.merge({ fetching: true })

// successful api lookup
export const masterTablesSuccess = (state, action) => {
	const { payload } = action;
	let categoriasLookup = new Object();
	payload.cursos.forEach(curso => curso.categorias.forEach(categoria => categoriasLookup[categoria.id] = categoria))
	return state.merge({ fetching: false, tables: payload, categoriasLookup })
}

// Something went wrong somewhere.
export const masterTablesFailure = state =>
	state.merge({ fetching: false, error: true })

// request the data from an api
export const countriesRequest = (state, { data }) =>
	state.merge({ fetching: true })

// successful api lookup
export const countriesSuccess = (state, action) => {
	const { payload } = action;
	return state.merge({ fetching: false, countries: payload.paises, geoip: payload.geoip })
}

// Something went wrong somewhere.
export const countriesFailure = state =>
	state.merge({ fetching: false, error: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.MASTER_TABLES_REQUEST]: masterTablesRequest,
	[Types.MASTER_TABLES_SUCCESS]: masterTablesSuccess,
	[Types.MASTER_TABLES_FAILURE]: masterTablesFailure,
	[Types.COUNTRIES_REQUEST]: countriesRequest,
	[Types.COUNTRIES_SUCCESS]: countriesSuccess,
	[Types.COUNTRIES_FAILURE]: countriesFailure
})
