/* eslint-disable */

import aventurero_es from './asignaturas/es/aventurero.png';
import calculator_es from './asignaturas/es/calculator.png';
import lenguaraz_es from './asignaturas/es/lenguaraz.png';
import naturalisto_es from './asignaturas/es/naturalisto.png';
import rockstar_es from './asignaturas/es/rockstar.png';
import superbrit_es from './asignaturas/es/superbrit.png';
import junglestar_es from './asignaturas/es/junglestar.png';
import backpacker_es from './asignaturas/es/backpacker.png';

import calculator_en from './asignaturas/en/calculator.png';
import geek_en from './asignaturas/en/geek.png';
import lenguaraz_en from './asignaturas/en/lenguaraz.png';
import bookworm_en from './asignaturas/en/bookworm.png';
import naturalisto_en from './asignaturas/en/naturalisto.png';
import superbrit_en from './asignaturas/en/superbrit.png';
import junglestar_en from './asignaturas/en/junglestar.png';
import backpacker_en from './asignaturas/en/backpacker.png';

import aventureroBN from './asignaturas/aventurero-bn.png';
import calculatorBN from './asignaturas/calculator-bn.png';
import lenguarazBN from './asignaturas/lenguaraz-bn.png';
import naturalistoBN from './asignaturas/naturalisto-bn.png';
import rockstarBN from './asignaturas/rockstar-bn.png';
import superbritBN from './asignaturas/superbrit-bn.png';
import junglestarBN from './asignaturas/junglestar-bn.png';
import backpackerBN from './asignaturas/backpacker-bn.png';

import aventureroBG from './asignaturas/aventurero-bg.png';
import calculatorBG from './asignaturas/calculator-bg.png';
import lenguarazBG from './asignaturas/lenguaraz-bg.png';
import naturalistoBG from './asignaturas/naturalisto-bg.png';
import rockstarBG from './asignaturas/rockstar-bg.png';
import superbritBG from './asignaturas/superbrit-bg.png';
import junglestarBG from './asignaturas/naturalisto-bg.png';
import backpackerBG from './asignaturas/aventurero-bg.png';

import aventureroEgg from './asignaturas/aventurero-egg.png';
import calculatorEgg from './asignaturas/calculator-egg.png';
import lenguarazEgg from './asignaturas/lenguaraz-egg.png';
import naturalistoEgg from './asignaturas/naturalisto-egg.png';
import rockstarEgg from './asignaturas/rockstar-egg.png';
import superbritEgg from './asignaturas/superbrit-egg.png';
import junglestarEgg from './asignaturas/naturalisto-egg.png';
import backpackerEgg from './asignaturas/aventurero-egg.png';

import aventureroEgg2 from './asignaturas/huevo-ciencias-sociales.png';
import calculatorEgg2 from './asignaturas/huevo-matematicas.png';
import lenguarazEgg2 from './asignaturas/huevo-lengua.png';
import naturalistoEgg2 from './asignaturas/huevo-ciencias-naturales.png';
import rockstarEgg2 from './asignaturas/huevo-musica.png';
import superbritEgg2 from './asignaturas/huevo-ingles.png';
import junglestarEgg2 from './asignaturas/huevo-ciencias-naturales.png';
import backpackerEgg2 from './asignaturas/huevo-ciencias-sociales.png';

import aventureroBall from './asignaturas/aventurero-ball.png';
import calculatorBall from './asignaturas/calculator-ball.png';
import lenguarazBall from './asignaturas/lenguaraz-ball.png';
import naturalistoBall from './asignaturas/naturalisto-ball.png';
import rockstarBall from './asignaturas/rockstar-ball.png';
import superbritBall from './asignaturas/superbrit-ball.png';
import junglestarBall from './asignaturas/naturalisto-ball.png';
import backpackerBall from './asignaturas/aventurero-ball.png';

import aventureroProg from './asignaturas/aventurero-prog.png';
import calculatorProg from './asignaturas/calculator-prog.png';
import lenguarazProg from './asignaturas/lenguaraz-prog.png';
import naturalistoProg from './asignaturas/naturalisto-prog.png';
import rockstarProg from './asignaturas/rockstar-prog.png';
import superbritProg from './asignaturas/superbrit-prog.png';
import junglestarProg from './asignaturas/naturalisto-prog.png';
import backpackerProg from './asignaturas/aventurero-prog.png';
import noProg from './asignaturas/icono-total-bloqueado.png';

import aventureroStarBall from './asignaturas/bola-ciencias-sociales.png';
import calculatorStarBall from './asignaturas/bola-matematicas.png';
import lenguarazStarBall from './asignaturas/bola-lengua.png';
import naturalistoStarBall from './asignaturas/bola-ciencias.png';
import rockstarStarBall from './asignaturas/bola-musica.png';
import superbritStarBall from './asignaturas/bola-ingles.png';
import junglestarStarBall from './asignaturas/bola-ciencias.png';
import backpackerStarBall from './asignaturas/bola-ciencias-sociales.png';
import neutreStarBall from './asignaturas/bola-neutra.png';

import aventureroPattern from './asignaturas/aventurero-pattern.png';
import calculatorPattern from './asignaturas/calculator-pattern.png';
import lenguarazPattern from './asignaturas/lenguaraz-pattern.png';
import naturalistoPattern from './asignaturas/naturalisto-pattern.png';
import rockstarPattern from './asignaturas/rockstar-pattern.png';
import superbritPattern from './asignaturas/superbrit-pattern.png';
import junglestarPattern from './asignaturas/naturalisto-pattern.png';
import backpackerPattern from './asignaturas/aventurero-pattern.png';

import aventureroButton from './asignaturas/boton-aventurero.png';
import calculatorButton from './asignaturas/boton-calculator.png';
import lenguarazButton from './asignaturas/boton-lenguaraz.png';
import naturalistoButton from './asignaturas/boton-naturalisto.png';
import rockstarButton from './asignaturas/boton-rockstar.png';
import superbritButton from './asignaturas/boton-superbrit.png';
import junglestarButton from './asignaturas/boton-naturalisto.png';
import backpackerButton from './asignaturas/boton-aventurero.png';

import aventureroAvatarSmall1 from './asignaturas/bicho_ciencias_sociales_small_1.png';
import aventureroAvatarSmall2 from './asignaturas/bicho_ciencias_sociales_small_2.png';
import aventureroAvatarSmall3 from './asignaturas/bicho_ciencias_sociales_small_3.png';
import aventureroAvatarSmall4 from './asignaturas/bicho_ciencias_sociales_small_4.png';
import aventureroAvatarSmall5 from './asignaturas/bicho_ciencias_sociales_small_5.png';
import aventureroAvatarSmall6 from './asignaturas/bicho_ciencias_sociales_small_6.png';
import aventureroAvatarSmall7 from './asignaturas/bicho_ciencias_sociales_small_7.png';
import aventureroAvatarSmall8 from './asignaturas/bicho_ciencias_sociales_small_8.png';
import aventureroAvatarSmall9 from './asignaturas/bicho_ciencias_sociales_small_9.png';
import aventureroAvatarSmall10 from './asignaturas/bicho_ciencias_sociales_small_10.png';
import aventureroAvatarSmall11 from './asignaturas/bicho_ciencias_sociales_small_11.png';

import calculatorAvatarSmall1 from './asignaturas/bicho_matematicas_small_1.png';
import calculatorAvatarSmall2 from './asignaturas/bicho_matematicas_small_2.png';
import calculatorAvatarSmall3 from './asignaturas/bicho_matematicas_small_3.png';
import calculatorAvatarSmall4 from './asignaturas/bicho_matematicas_small_4.png';
import calculatorAvatarSmall5 from './asignaturas/bicho_matematicas_small_5.png';
import calculatorAvatarSmall6 from './asignaturas/bicho_matematicas_small_6.png';
import calculatorAvatarSmall7 from './asignaturas/bicho_matematicas_small_7.png';
import calculatorAvatarSmall8 from './asignaturas/bicho_matematicas_small_8.png';
import calculatorAvatarSmall9 from './asignaturas/bicho_matematicas_small_9.png';
import calculatorAvatarSmall10 from './asignaturas/bicho_matematicas_small_10.png';
import calculatorAvatarSmall11 from './asignaturas/bicho_matematicas_small_11.png';

import lenguarazAvatarSmall1 from './asignaturas/bicho_lengua_small_1.png';
import lenguarazAvatarSmall2 from './asignaturas/bicho_lengua_small_2.png';
import lenguarazAvatarSmall3 from './asignaturas/bicho_lengua_small_3.png';
import lenguarazAvatarSmall4 from './asignaturas/bicho_lengua_small_4.png';
import lenguarazAvatarSmall5 from './asignaturas/bicho_lengua_small_5.png';
import lenguarazAvatarSmall6 from './asignaturas/bicho_lengua_small_6.png';
import lenguarazAvatarSmall7 from './asignaturas/bicho_lengua_small_7.png';
import lenguarazAvatarSmall8 from './asignaturas/bicho_lengua_small_8.png';
import lenguarazAvatarSmall9 from './asignaturas/bicho_lengua_small_9.png';
import lenguarazAvatarSmall10 from './asignaturas/bicho_lengua_small_10.png';
import lenguarazAvatarSmall11 from './asignaturas/bicho_lengua_small_11.png';

import naturalistoAvatarSmall1 from './asignaturas/bicho_ciencias_naturales_small_1.png';
import naturalistoAvatarSmall2 from './asignaturas/bicho_ciencias_naturales_small_2.png';
import naturalistoAvatarSmall3 from './asignaturas/bicho_ciencias_naturales_small_3.png';
import naturalistoAvatarSmall4 from './asignaturas/bicho_ciencias_naturales_small_4.png';
import naturalistoAvatarSmall5 from './asignaturas/bicho_ciencias_naturales_small_5.png';
import naturalistoAvatarSmall6 from './asignaturas/bicho_ciencias_naturales_small_6.png';
import naturalistoAvatarSmall7 from './asignaturas/bicho_ciencias_naturales_small_7.png';
import naturalistoAvatarSmall8 from './asignaturas/bicho_ciencias_naturales_small_8.png';
import naturalistoAvatarSmall9 from './asignaturas/bicho_ciencias_naturales_small_9.png';
import naturalistoAvatarSmall10 from './asignaturas/bicho_ciencias_naturales_small_10.png';
import naturalistoAvatarSmall11 from './asignaturas/bicho_ciencias_naturales_small_11.png';

import superbritAvatarSmall1 from './asignaturas/bicho_ingles_small_1.png';
import superbritAvatarSmall2 from './asignaturas/bicho_ingles_small_2.png';
import superbritAvatarSmall3 from './asignaturas/bicho_ingles_small_3.png';
import superbritAvatarSmall4 from './asignaturas/bicho_ingles_small_4.png';
import superbritAvatarSmall5 from './asignaturas/bicho_ingles_small_5.png';
import superbritAvatarSmall6 from './asignaturas/bicho_ingles_small_6.png';
import superbritAvatarSmall7 from './asignaturas/bicho_ingles_small_7.png';
import superbritAvatarSmall8 from './asignaturas/bicho_ingles_small_8.png';
import superbritAvatarSmall9 from './asignaturas/bicho_ingles_small_9.png';
import superbritAvatarSmall10 from './asignaturas/bicho_ingles_small_10.png';
import superbritAvatarSmall11 from './asignaturas/bicho_ingles_small_11.png';

import backpackerAvatarSmall1 from './asignaturas/bicho_ciencias_sociales_small_1.png';
import backpackerAvatarSmall2 from './asignaturas/bicho_ciencias_sociales_small_2.png';
import backpackerAvatarSmall3 from './asignaturas/bicho_ciencias_sociales_small_3.png';
import backpackerAvatarSmall4 from './asignaturas/bicho_ciencias_sociales_small_4.png';
import backpackerAvatarSmall5 from './asignaturas/bicho_ciencias_sociales_small_5.png';
import backpackerAvatarSmall6 from './asignaturas/bicho_ciencias_sociales_small_6.png';
import backpackerAvatarSmall7 from './asignaturas/bicho_ciencias_sociales_small_7.png';
import backpackerAvatarSmall8 from './asignaturas/bicho_ciencias_sociales_small_8.png';
import backpackerAvatarSmall9 from './asignaturas/bicho_ciencias_sociales_small_9.png';
import backpackerAvatarSmall10 from './asignaturas/bicho_ciencias_sociales_small_10.png';
import backpackerAvatarSmall11 from './asignaturas/bicho_ciencias_sociales_small_11.png';

import junglestarAvatarSmall1 from './asignaturas/bicho_ciencias_naturales_small_1.png';
import junglestarAvatarSmall2 from './asignaturas/bicho_ciencias_naturales_small_2.png';
import junglestarAvatarSmall3 from './asignaturas/bicho_ciencias_naturales_small_3.png';
import junglestarAvatarSmall4 from './asignaturas/bicho_ciencias_naturales_small_4.png';
import junglestarAvatarSmall5 from './asignaturas/bicho_ciencias_naturales_small_5.png';
import junglestarAvatarSmall6 from './asignaturas/bicho_ciencias_naturales_small_6.png';
import junglestarAvatarSmall7 from './asignaturas/bicho_ciencias_naturales_small_7.png';
import junglestarAvatarSmall8 from './asignaturas/bicho_ciencias_naturales_small_8.png';
import junglestarAvatarSmall9 from './asignaturas/bicho_ciencias_naturales_small_9.png';
import junglestarAvatarSmall10 from './asignaturas/bicho_ciencias_naturales_small_10.png';
import junglestarAvatarSmall11 from './asignaturas/bicho_ciencias_naturales_small_11.png';

import aventureroAvatar1 from './asignaturas/bicho_ciencias_sociales_1.png';
import aventureroAvatar2 from './asignaturas/bicho_ciencias_sociales_2.png';
import aventureroAvatar3 from './asignaturas/bicho_ciencias_sociales_3.png';
import aventureroAvatar4 from './asignaturas/bicho_ciencias_sociales_4.png';
import aventureroAvatar5 from './asignaturas/bicho_ciencias_sociales_5.png';
import aventureroAvatar6 from './asignaturas/bicho_ciencias_sociales_6.png';
import aventureroAvatar7 from './asignaturas/bicho_ciencias_sociales_7.png';
import aventureroAvatar8 from './asignaturas/bicho_ciencias_sociales_8.png';
import aventureroAvatar9 from './asignaturas/bicho_ciencias_sociales_9.png';
import aventureroAvatar10 from './asignaturas/bicho_ciencias_sociales_10.png';
import aventureroAvatar11 from './asignaturas/bicho_ciencias_sociales_11.png';

import calculatorAvatar1 from './asignaturas/bicho_matematicas_1.png';
import calculatorAvatar2 from './asignaturas/bicho_matematicas_2.png';
import calculatorAvatar3 from './asignaturas/bicho_matematicas_3.png';
import calculatorAvatar4 from './asignaturas/bicho_matematicas_4.png';
import calculatorAvatar5 from './asignaturas/bicho_matematicas_5.png';
import calculatorAvatar6 from './asignaturas/bicho_matematicas_6.png';
import calculatorAvatar7 from './asignaturas/bicho_matematicas_7.png';
import calculatorAvatar8 from './asignaturas/bicho_matematicas_8.png';
import calculatorAvatar9 from './asignaturas/bicho_matematicas_9.png';
import calculatorAvatar10 from './asignaturas/bicho_matematicas_10.png';
import calculatorAvatar11 from './asignaturas/bicho_matematicas_11.png';

import lenguarazAvatar1 from './asignaturas/bicho_lengua_1.png';
import lenguarazAvatar2 from './asignaturas/bicho_lengua_2.png';
import lenguarazAvatar3 from './asignaturas/bicho_lengua_3.png';
import lenguarazAvatar4 from './asignaturas/bicho_lengua_4.png';
import lenguarazAvatar5 from './asignaturas/bicho_lengua_5.png';
import lenguarazAvatar6 from './asignaturas/bicho_lengua_6.png';
import lenguarazAvatar7 from './asignaturas/bicho_lengua_7.png';
import lenguarazAvatar8 from './asignaturas/bicho_lengua_8.png';
import lenguarazAvatar9 from './asignaturas/bicho_lengua_9.png';
import lenguarazAvatar10 from './asignaturas/bicho_lengua_10.png';
import lenguarazAvatar11 from './asignaturas/bicho_lengua_11.png';

import naturalistoAvatar1 from './asignaturas/bicho_ciencias_naturales_1.png';
import naturalistoAvatar2 from './asignaturas/bicho_ciencias_naturales_2.png';
import naturalistoAvatar3 from './asignaturas/bicho_ciencias_naturales_3.png';
import naturalistoAvatar4 from './asignaturas/bicho_ciencias_naturales_4.png';
import naturalistoAvatar5 from './asignaturas/bicho_ciencias_naturales_5.png';
import naturalistoAvatar6 from './asignaturas/bicho_ciencias_naturales_6.png';
import naturalistoAvatar7 from './asignaturas/bicho_ciencias_naturales_7.png';
import naturalistoAvatar8 from './asignaturas/bicho_ciencias_naturales_8.png';
import naturalistoAvatar9 from './asignaturas/bicho_ciencias_naturales_9.png';
import naturalistoAvatar10 from './asignaturas/bicho_ciencias_naturales_10.png';
import naturalistoAvatar11 from './asignaturas/bicho_ciencias_naturales_11.png';

import superbritAvatar1 from './asignaturas/bicho_ingles_1.png';
import superbritAvatar2 from './asignaturas/bicho_ingles_2.png';
import superbritAvatar3 from './asignaturas/bicho_ingles_3.png';
import superbritAvatar4 from './asignaturas/bicho_ingles_4.png';
import superbritAvatar5 from './asignaturas/bicho_ingles_5.png';
import superbritAvatar6 from './asignaturas/bicho_ingles_6.png';
import superbritAvatar7 from './asignaturas/bicho_ingles_7.png';
import superbritAvatar8 from './asignaturas/bicho_ingles_8.png';
import superbritAvatar9 from './asignaturas/bicho_ingles_9.png';
import superbritAvatar10 from './asignaturas/bicho_ingles_10.png';
import superbritAvatar11 from './asignaturas/bicho_ingles_11.png';

import backpackerAvatar1 from './asignaturas/bicho_ciencias_sociales_1.png';
import backpackerAvatar2 from './asignaturas/bicho_ciencias_sociales_2.png';
import backpackerAvatar3 from './asignaturas/bicho_ciencias_sociales_3.png';
import backpackerAvatar4 from './asignaturas/bicho_ciencias_sociales_4.png';
import backpackerAvatar5 from './asignaturas/bicho_ciencias_sociales_5.png';
import backpackerAvatar6 from './asignaturas/bicho_ciencias_sociales_6.png';
import backpackerAvatar7 from './asignaturas/bicho_ciencias_sociales_7.png';
import backpackerAvatar8 from './asignaturas/bicho_ciencias_sociales_8.png';
import backpackerAvatar9 from './asignaturas/bicho_ciencias_sociales_9.png';
import backpackerAvatar10 from './asignaturas/bicho_ciencias_sociales_10.png';
import backpackerAvatar11 from './asignaturas/bicho_ciencias_sociales_11.png';

import junglestarAvatar1 from './asignaturas/bicho_ciencias_naturales_1.png';
import junglestarAvatar2 from './asignaturas/bicho_ciencias_naturales_2.png';
import junglestarAvatar3 from './asignaturas/bicho_ciencias_naturales_3.png';
import junglestarAvatar4 from './asignaturas/bicho_ciencias_naturales_4.png';
import junglestarAvatar5 from './asignaturas/bicho_ciencias_naturales_5.png';
import junglestarAvatar6 from './asignaturas/bicho_ciencias_naturales_6.png';
import junglestarAvatar7 from './asignaturas/bicho_ciencias_naturales_7.png';
import junglestarAvatar8 from './asignaturas/bicho_ciencias_naturales_8.png';
import junglestarAvatar9 from './asignaturas/bicho_ciencias_naturales_9.png';
import junglestarAvatar10 from './asignaturas/bicho_ciencias_naturales_10.png';
import junglestarAvatar11 from './asignaturas/bicho_ciencias_naturales_11.png';

import egg2 from './asignaturas/huevo-neutro.png';
import bloqued from './asignaturas/huevo-bloqueado.png';

const IMAGES = {
  categories: {
    es: {
      aventurero: aventurero_es,
      calculator: calculator_es,
      geek: calculator_es,
      lenguaraz: lenguaraz_es,
      bookworm: lenguaraz_es,
      naturalisto: naturalisto_es,
      rockstar: rockstar_es,
      superbrit: superbrit_es,
      junglestar: junglestar_es,
      backpacker: backpacker_es,
    },
    en: {
      calculator: calculator_en,
      geek: geek_en,
      lenguaraz: lenguaraz_en,
      bookworm: bookworm_en,
      naturalisto: naturalisto_en,
      superbrit: superbrit_en,
      junglestar: junglestar_en,
      backpacker: backpacker_en,
    },

    aventureroBN,
    calculatorBN,
    geekBN: calculatorBN,
    lenguarazBN,
    bookwormBN: lenguarazBN,
    naturalistoBN,
    rockstarBN,
    superbritBN,
    junglestarBN,
    backpackerBN,

    aventureroBG,
    calculatorBG,
    geekBG: calculatorBG,
    bookwormBG: lenguarazBG,
    lenguarazBG,
    naturalistoBG,
    rockstarBG,
    superbritBG,
    junglestarBG,
    backpackerBG,

    aventureroEgg,
    calculatorEgg,
    lenguarazEgg,
    naturalistoEgg,
    rockstarEgg,
    superbritEgg,
    junglestarEgg,
    backpackerEgg,

    aventureroEgg2,
    calculatorEgg2,
    lenguarazEgg2,
    naturalistoEgg2,
    rockstarEgg2,
    superbritEgg2,
    junglestarEgg2,
    backpackerEgg2,

    aventureroBall,
    calculatorBall,
    geekBall: calculatorBall,
    lenguarazBall,
    bookwormBall: lenguarazBall,
    naturalistoBall,
    rockstarBall,
    superbritBall,
    junglestarBall,
    backpackerBall,

    aventureroProg,
    calculatorProg,
    lenguarazProg,
    naturalistoProg,
    rockstarProg,
    superbritProg,
    junglestarProg,
    backpackerProg,
    noProg,

    aventureroStarBall,
    calculatorStarBall,
    geekStarBall: calculatorStarBall,
    lenguarazStarBall,
    bookworkStarBall: lenguarazStarBall,
    naturalistoStarBall,
    rockstarStarBall,
    superbritStarBall,
    junglestarStarBall,
    backpackerStarBall,
    neutreStarBall,

    aventureroPattern,
    calculatorPattern,
    lenguarazPattern,
    naturalistoPattern,
    rockstarPattern,
    superbritPattern,
    junglestarPattern,
    backpackerPattern,

    aventureroButton,
    calculatorButton,
    lenguarazButton,
    naturalistoButton,
    rockstarButton,
    superbritButton,
    junglestarButton,
    backpackerButton,

    aventureroAvatar1,
    aventureroAvatar2,
    aventureroAvatar3,
    aventureroAvatar4,
    aventureroAvatar5,
    aventureroAvatar6,
    aventureroAvatar7,
    aventureroAvatar8,
    aventureroAvatar9,
    aventureroAvatar10,
    aventureroAvatar11,

    calculatorAvatar1,
    calculatorAvatar2,
    calculatorAvatar3,
    calculatorAvatar4,
    calculatorAvatar5,
    calculatorAvatar6,
    calculatorAvatar7,
    calculatorAvatar8,
    calculatorAvatar9,
    calculatorAvatar10,
    calculatorAvatar11,

    geekAvatar1: calculatorAvatar1,
    geekAvatar2: calculatorAvatar2,
    geekAvatar3: calculatorAvatar3,
    geekAvatar4: calculatorAvatar4,
    geekAvatar5: calculatorAvatar5,
    geekAvatar6: calculatorAvatar6,
    geekAvatar7: calculatorAvatar7,
    geekAvatar8: calculatorAvatar8,
    geekAvatar9: calculatorAvatar9,
    geekAvatar10: calculatorAvatar10,
    geekAvatar11: calculatorAvatar11,

    bookwormAvatar1: lenguarazAvatar1,
    bookwormAvatar2: lenguarazAvatar2,
    bookwormAvatar3: lenguarazAvatar3,
    bookwormAvatar4: lenguarazAvatar4,
    bookwormAvatar5: lenguarazAvatar5,
    bookwormAvatar6: lenguarazAvatar6,
    bookwormAvatar7: lenguarazAvatar7,
    bookwormAvatar8: lenguarazAvatar8,
    bookwormAvatar9: lenguarazAvatar9,
    bookwormAvatar10: lenguarazAvatar10,
    bookwormAvatar11: lenguarazAvatar11,

    lenguarazAvatar1,
    lenguarazAvatar2,
    lenguarazAvatar3,
    lenguarazAvatar4,
    lenguarazAvatar5,
    lenguarazAvatar6,
    lenguarazAvatar7,
    lenguarazAvatar8,
    lenguarazAvatar9,
    lenguarazAvatar10,
    lenguarazAvatar11,

    naturalistoAvatar1,
    naturalistoAvatar2,
    naturalistoAvatar3,
    naturalistoAvatar4,
    naturalistoAvatar5,
    naturalistoAvatar6,
    naturalistoAvatar7,
    naturalistoAvatar8,
    naturalistoAvatar9,
    naturalistoAvatar10,
    naturalistoAvatar11,

    superbritAvatar1,
    superbritAvatar2,
    superbritAvatar3,
    superbritAvatar4,
    superbritAvatar5,
    superbritAvatar6,
    superbritAvatar7,
    superbritAvatar8,
    superbritAvatar9,
    superbritAvatar10,
    superbritAvatar11,

    backpackerAvatar1,
    backpackerAvatar2,
    backpackerAvatar3,
    backpackerAvatar4,
    backpackerAvatar5,
    backpackerAvatar6,
    backpackerAvatar7,
    backpackerAvatar8,
    backpackerAvatar9,
    backpackerAvatar10,
    backpackerAvatar11,

    junglestarAvatar1,
    junglestarAvatar2,
    junglestarAvatar3,
    junglestarAvatar4,
    junglestarAvatar5,
    junglestarAvatar6,
    junglestarAvatar7,
    junglestarAvatar8,
    junglestarAvatar9,
    junglestarAvatar10,
    junglestarAvatar11,

    aventureroAvatarSmall1,
    aventureroAvatarSmall2,
    aventureroAvatarSmall3,
    aventureroAvatarSmall4,
    aventureroAvatarSmall5,
    aventureroAvatarSmall6,
    aventureroAvatarSmall7,
    aventureroAvatarSmall8,
    aventureroAvatarSmall9,
    aventureroAvatarSmall10,
    aventureroAvatarSmall11,

    calculatorAvatarSmall1,
    calculatorAvatarSmall2,
    calculatorAvatarSmall3,
    calculatorAvatarSmall4,
    calculatorAvatarSmall5,
    calculatorAvatarSmall6,
    calculatorAvatarSmall7,
    calculatorAvatarSmall8,
    calculatorAvatarSmall9,
    calculatorAvatarSmall10,
    calculatorAvatarSmall11,

    geekAvatarSmall1: calculatorAvatarSmall1,
    geekAvatarSmall2: calculatorAvatarSmall2,
    geekAvatarSmall3: calculatorAvatarSmall3,
    geekAvatarSmall4: calculatorAvatarSmall4,
    geekAvatarSmall5: calculatorAvatarSmall5,
    geekAvatarSmall6: calculatorAvatarSmall6,
    geekAvatarSmall7: calculatorAvatarSmall7,
    geekAvatarSmall8: calculatorAvatarSmall8,
    geekAvatarSmall9: calculatorAvatarSmall9,
    geekAvatarSmall10: calculatorAvatarSmall10,
    geekAvatarSmall11: calculatorAvatarSmall11,

    lenguarazAvatarSmall1,
    lenguarazAvatarSmall2,
    lenguarazAvatarSmall3,
    lenguarazAvatarSmall4,
    lenguarazAvatarSmall5,
    lenguarazAvatarSmall6,
    lenguarazAvatarSmall7,
    lenguarazAvatarSmall8,
    lenguarazAvatarSmall9,
    lenguarazAvatarSmall10,
    lenguarazAvatarSmall11,

    bookwormAvatarSmall1: lenguarazAvatarSmall1,
    bookwormAvatarSmall2: lenguarazAvatarSmall2,
    bookwormAvatarSmall3: lenguarazAvatarSmall3,
    bookwormAvatarSmall4: lenguarazAvatarSmall4,
    bookwormAvatarSmall5: lenguarazAvatarSmall5,
    bookwormAvatarSmall6: lenguarazAvatarSmall6,
    bookwormAvatarSmall7: lenguarazAvatarSmall7,
    bookwormAvatarSmall8: lenguarazAvatarSmall8,
    bookwormAvatarSmall9: lenguarazAvatarSmall9,
    bookwormAvatarSmall10: lenguarazAvatarSmall10,
    bookwormAvatarSmall11: lenguarazAvatarSmall11,

    naturalistoAvatarSmall1,
    naturalistoAvatarSmall2,
    naturalistoAvatarSmall3,
    naturalistoAvatarSmall4,
    naturalistoAvatarSmall5,
    naturalistoAvatarSmall6,
    naturalistoAvatarSmall7,
    naturalistoAvatarSmall8,
    naturalistoAvatarSmall9,
    naturalistoAvatarSmall10,
    naturalistoAvatarSmall11,

    superbritAvatarSmall1,
    superbritAvatarSmall2,
    superbritAvatarSmall3,
    superbritAvatarSmall4,
    superbritAvatarSmall5,
    superbritAvatarSmall6,
    superbritAvatarSmall7,
    superbritAvatarSmall8,
    superbritAvatarSmall9,
    superbritAvatarSmall10,
    superbritAvatarSmall11,

    backpackerAvatarSmall1,
    backpackerAvatarSmall2,
    backpackerAvatarSmall3,
    backpackerAvatarSmall4,
    backpackerAvatarSmall5,
    backpackerAvatarSmall6,
    backpackerAvatarSmall7,
    backpackerAvatarSmall8,
    backpackerAvatarSmall9,
    backpackerAvatarSmall10,
    backpackerAvatarSmall11,

    junglestarAvatarSmall1,
    junglestarAvatarSmall2,
    junglestarAvatarSmall3,
    junglestarAvatarSmall4,
    junglestarAvatarSmall5,
    junglestarAvatarSmall6,
    junglestarAvatarSmall7,
    junglestarAvatarSmall8,
    junglestarAvatarSmall9,
    junglestarAvatarSmall10,
    junglestarAvatarSmall11,



    egg2,
    bloqued,
  },
  avatar: {
    mini: {
      0: require('./avatar/mini/icono-cuerpo-0.png'),
      1: require('./avatar/mini/icono-cuerpo-1.png'),
      2: require('./avatar/mini/icono-cuerpo-2.png'),
      3: require('./avatar/mini/icono-cuerpo-3.png'),
      4: require('./avatar/mini/icono-cuerpo-4.png'),
      5: require('./avatar/mini/icono-cuerpo-5.png'),
      6: require('./avatar/mini/icono-cuerpo-6.png'),
      7: require('./avatar/mini/icono-cuerpo-7.png'),
      8: require('./avatar/mini/icono-cuerpo-8.png'),
    },
    eyes:{
      default: require('./avatar/eyes/AvEyeDefault.png'),
      0: require('./avatar/eyes/AvEye0.png'),
      1: require('./avatar/eyes/AvEye1.png'),
      2: require('./avatar/eyes/AvEye2.png'),
      3: require('./avatar/eyes/AvEye3.png'),
      4: require('./avatar/eyes/AvEye4.png'),
      5: require('./avatar/eyes/AvEye5.png'),
      6: require('./avatar/eyes/AvEye6.png'),
      7: require('./avatar/eyes/AvEye7.png'),
      8: require('./avatar/eyes/AvEye8.png'),
      9: require('./avatar/eyes/AvEye9.png'),
    },
    legs:{
      default: require('./avatar/legs/AvLegsDefault.png'),
      0: require('./avatar/legs/AvLegs0.png'),
      1: require('./avatar/legs/AvLegs1.png'),
      2: require('./avatar/legs/AvLegs2.png'),
      3: require('./avatar/legs/AvLegs3.png'),
      4: require('./avatar/legs/AvLegs4.png'),
      5: require('./avatar/legs/AvLegs5.png'),
      6: require('./avatar/legs/AvLegs6.png'),
      7: require('./avatar/legs/AvLegs7.png'),
      8: require('./avatar/legs/AvLegs8.png'),
      9: require('./avatar/legs/AvLegs9.png'),
      10: require('./avatar/legs/AvLegs10.png'),
    },
    mouth:{
      default: require('./avatar/mouth/AvMouthDefault.png'),
      0: require('./avatar/mouth/AvMouth0.png'),
      1: require('./avatar/mouth/AvMouth1.png'),
      2: require('./avatar/mouth/AvMouth2.png'),
      3: require('./avatar/mouth/AvMouth3.png'),
      4: require('./avatar/mouth/AvMouth4.png'),
      5: require('./avatar/mouth/AvMouth5.png'),
      6: require('./avatar/mouth/AvMouth6.png'),
      7: require('./avatar/mouth/AvMouth7.png'),
      8: require('./avatar/mouth/AvMouth8.png'),
      9: require('./avatar/mouth/AvMouth9.png'),
    },
    body:{
      default: require('./avatar/body/AvBodyDefault_ColorDefault.png'),
      "00": require('./avatar/body/AvBody0_Color0.png'),
      "11": require('./avatar/body/AvBody1_Color1.png'),
      "12": require('./avatar/body/AvBody1_Color2.png'),
      "13": require('./avatar/body/AvBody1_Color3.png'),
      "14": require('./avatar/body/AvBody1_Color4.png'),
      "15": require('./avatar/body/AvBody1_Color5.png'),
      "16": require('./avatar/body/AvBody1_Color6.png'),
      "17": require('./avatar/body/AvBody1_Color7.png'),
      "18": require('./avatar/body/AvBody1_Color8.png'),
      "19": require('./avatar/body/AvBody1_Color9.png'),
      "21": require('./avatar/body/AvBody2_Color1.png'),
      "22": require('./avatar/body/AvBody2_Color2.png'),
      "23": require('./avatar/body/AvBody2_Color3.png'),
      "24": require('./avatar/body/AvBody2_Color4.png'),
      "25": require('./avatar/body/AvBody2_Color5.png'),
      "26": require('./avatar/body/AvBody2_Color6.png'),
      "27": require('./avatar/body/AvBody2_Color7.png'),
      "28": require('./avatar/body/AvBody2_Color8.png'),
      "29": require('./avatar/body/AvBody2_Color9.png'),
      "31": require('./avatar/body/AvBody3_Color1.png'),
      "32": require('./avatar/body/AvBody3_Color2.png'),
      "33": require('./avatar/body/AvBody3_Color3.png'),
      "34": require('./avatar/body/AvBody3_Color4.png'),
      "35": require('./avatar/body/AvBody3_Color5.png'),
      "36": require('./avatar/body/AvBody3_Color6.png'),
      "37": require('./avatar/body/AvBody3_Color7.png'),
      "38": require('./avatar/body/AvBody3_Color8.png'),
      "39": require('./avatar/body/AvBody3_Color9.png'),
      "41": require('./avatar/body/AvBody4_Color1.png'),
      "42": require('./avatar/body/AvBody4_Color2.png'),
      "43": require('./avatar/body/AvBody4_Color3.png'),
      "44": require('./avatar/body/AvBody4_Color4.png'),
      "45": require('./avatar/body/AvBody4_Color5.png'),
      "46": require('./avatar/body/AvBody4_Color6.png'),
      "47": require('./avatar/body/AvBody4_Color7.png'),
      "48": require('./avatar/body/AvBody4_Color8.png'),
      "49": require('./avatar/body/AvBody4_Color9.png'),
      "51": require('./avatar/body/AvBody5_Color1.png'),
      "52": require('./avatar/body/AvBody5_Color2.png'),
      "53": require('./avatar/body/AvBody5_Color3.png'),
      "54": require('./avatar/body/AvBody5_Color4.png'),
      "55": require('./avatar/body/AvBody5_Color5.png'),
      "56": require('./avatar/body/AvBody5_Color6.png'),
      "57": require('./avatar/body/AvBody5_Color7.png'),
      "58": require('./avatar/body/AvBody5_Color8.png'),
      "59": require('./avatar/body/AvBody5_Color9.png'),
      "61": require('./avatar/body/AvBody6_Color1.png'),
      "62": require('./avatar/body/AvBody6_Color2.png'),
      "63": require('./avatar/body/AvBody6_Color3.png'),
      "64": require('./avatar/body/AvBody6_Color4.png'),
      "65": require('./avatar/body/AvBody6_Color5.png'),
      "66": require('./avatar/body/AvBody6_Color6.png'),
      "67": require('./avatar/body/AvBody6_Color7.png'),
      "68": require('./avatar/body/AvBody6_Color8.png'),
      "69": require('./avatar/body/AvBody6_Color9.png'),
      "71": require('./avatar/body/AvBody7_Color1.png'),
      "72": require('./avatar/body/AvBody7_Color2.png'),
      "73": require('./avatar/body/AvBody7_Color3.png'),
      "74": require('./avatar/body/AvBody7_Color4.png'),
      "75": require('./avatar/body/AvBody7_Color5.png'),
      "76": require('./avatar/body/AvBody7_Color6.png'),
      "77": require('./avatar/body/AvBody7_Color7.png'),
      "78": require('./avatar/body/AvBody7_Color8.png'),
      "79": require('./avatar/body/AvBody7_Color9.png'),
      "81": require('./avatar/body/AvBody8_Color1.png'),
      "82": require('./avatar/body/AvBody8_Color2.png'),
      "83": require('./avatar/body/AvBody8_Color3.png'),
      "84": require('./avatar/body/AvBody8_Color4.png'),
      "85": require('./avatar/body/AvBody8_Color5.png'),
      "86": require('./avatar/body/AvBody8_Color6.png'),
      "87": require('./avatar/body/AvBody8_Color7.png'),
      "88": require('./avatar/body/AvBody8_Color8.png'),
      "89": require('./avatar/body/AvBody8_Color9.png'),
    },
  },
};
export default IMAGES;
