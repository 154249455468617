/* eslint-disable */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, takeLatest } from 'redux-saga/effects';
import MasterTablesActions, { MasterTablesTypes } from '../Redux/MasterTablesRedux';
import PlatformApi from "../Services/PlatformApi";

// import { MasterTablesSelectors } from '../Redux/MasterTablesRedux'


export function* getMasterTables(api, action) {
	const { lang } = action;
	const response = yield call(api.getMasterData, lang);
	if (response.ok) {
		yield put(MasterTablesActions.masterTablesSuccess(response.data.tablas));
	} else {
		yield put(MasterTablesActions.masterTablesFailure());
	}
}
export function* getCountries(api, action) {
	const response = yield call(api.getCountries);

	if (response.ok) {
		yield put(MasterTablesActions.countriesSuccess(response.data));
	} else {
		yield put(MasterTablesActions.countriesFailure());
	}
}
