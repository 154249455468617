/* eslint-disable no-underscore-dangle */
/**
 *
 * Layouts
 *
 */

// eslint-disable-next-line no-unused-vars
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const _StackLayout = styled.section`
  > :not(:last-child) {
    margin-bottom: ${props => (props.separation ? `${props.separation}px` : '16px')};
  }
`;

_StackLayout.propTypes = {
  separation: PropTypes.number,
};
export const StackLayout = memo(_StackLayout);

export const FULL_HEIGHT_MIN_HEIGHT = 550;

const _FullHeightStackLayout = styled(StackLayout)`
  min-height: ${FULL_HEIGHT_MIN_HEIGHT}px;
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: ${p => p.direction ?? 'row'};
  justify-content: center;
  align-items: center;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  margin: 0 auto;
  box-sizing: border-box;
`;
_FullHeightStackLayout.propTypes = {
  width: PropTypes.number,
  direction: PropTypes.string,
};
export const FullHeightStackLayout = memo(_FullHeightStackLayout);

export const Pusher = styled.div`
  flex: 1 1 auto;
`;

export const FullHeightLayout = styled.div`
  width: 100%;
  min-height: ${FULL_HEIGHT_MIN_HEIGHT}px;
  height: calc(100vh - var(--header-height));
`;
