/* eslint-disable */
import standardApi from "./standardApi";


const create = () => {
	const api = standardApi();
	const requestGeneralStats = (childId) => api.get(`/usuario/alumno/${childId}/estadisticas/generales`);
	const requestSpecificStats = (childId, subjectId) => api.get(`/usuario/alumno/${childId}/estadisticas/asignatura/${subjectId}`);
	return {
		apisauce: api,
		requestGeneralStats,
		requestSpecificStats
	}
}

export default {
	create
}
