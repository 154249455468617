/* eslint-disable prettier/prettier */
import {putResolve, select} from 'redux-saga/effects'
import MasterTablesActions from '../Redux/MasterTablesRedux'
import UserActions, {UserTypes} from '../Redux/UserRedux'
import AuthActions from '../Redux/AuthRedux'
import AppConfig from '../Config/AppConfig'
import Environment from "../Config/Environment"

// process STARTUP actions
export function * startup (action) {
	if (AppConfig.useReactotron && console.tron) {
		// straight-up string logging
		console.tron.log('Hello World.')
	}
	//console.log(Environment.getStartupAction(),MasterTablesActions.masterTablesRequest());
	yield putResolve(Environment.getStartupAction());
	//yield putResolve(MasterTablesActions.masterTablesRequest());
	yield putResolve(AuthActions.resetLoadingState());
	yield putResolve(UserActions.clearError());

	let {accessToken,login,password, network, googleToken, msTokenType, msAccessToken} = yield select(state => state.auth);
	AppConfig.parentToken = accessToken;

	if(accessToken){
		console.log("Parent has access token, loggin in")
		yield putResolve(AuthActions.loginRequest(login,password,true, action.postAction, network, googleToken, msTokenType, msAccessToken));
		//bugsnag.setUser(accessToken, '', login);
	}
	else{
		console.log("User has not access token")
		if(action.postAction){
			yield putResolve(action.postAction);
		}
	}
}

export function * startupTeacher (action) {
	if (AppConfig.useReactotron && console.tron) {
		// straight-up string logging
		console.tron.log('Hello World.')
	}
	//console.log(Environment.getStartupAction(),MasterTablesActions.masterTablesRequest());
	putResolve(Environment.getStartupAction());

	yield putResolve(AuthActions.resetLoadingState());

	let {accessToken,email,password} = yield select(state => state.auth);
	AppConfig.userToken = accessToken;

	if(accessToken){
		console.log("User has access token, loggin in")
		yield putResolve(AuthActions.loginTeacherRequest(email,password,true));
	}
	else{
		console.log("User has not access token")
	}
}
