/**
 *
 * Modal
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import * as ReactModal from 'react-modal';
// import Colors from '../../utils/Colors';

function Modal(props) {
  // eslint-disable-next-line no-unused-vars
  const { modalIsOpen, onRequestClose, label, style, children } = props;
  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={label}
    >
      {children}
    </ReactModal>
  );
}

Modal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
  style: PropTypes.object,
};

export default memo(Modal);

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    border: '0 none',
    inset: 0,
    padding: 5,
  },
};
