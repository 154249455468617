/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import {call, put, select} from 'redux-saga/effects'
import RankingActions from '../Redux/RankingRedux'
import Strings from '../Config/Strings'


export function * getFriendsRanking(api,action) {
	let categoriaid = yield select(state => state.game.category);
	const response = yield call(api.getFriendsRanking,categoriaid)
	if(response.data.errno != 0){
		yield put(RankingActions.rankingFriendsFailure(Strings.GENERIC_ERROR))
	}
	else{
		yield put(RankingActions.rankingFriendsSuccess(response.data));
	}
}

export function * getGlobalRanking(api,action) {
	console.log('getGlobalRanking')
	let categoriaid = yield select(state => state.game.category);
	const response = yield call(api.getGlobalRanking,categoriaid)
	if(response.data.errno != 0){
		yield put(RankingActions.rankingGlobalFailure(Strings.GENERIC_ERROR))
	}
	else{
		yield put(RankingActions.rankingGlobalSuccess(response.data));
	}
}
