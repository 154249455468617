import standardApi from "./standardApi";


const create = () => {
	const api = standardApi();

	const getPending = (asignaturaid) =>
		api.get(`/usuario/alumno/retos/${asignaturaid}`)
	const getChallengeResult = (retoid) =>
		api.get(`/usuario/alumno/retos/resultado/${retoid}`)
	const rejectChallenge = (retoid) =>
		api.post(`/usuario/alumno/retos/rechazar/${retoid}`)
	const challengeRandom = (asignaturaid) =>
		api.post(`/usuario/alumno/retos/nuevo/aleatorio/${asignaturaid}`)
	const challengeFriend = (asignaturaid,amigoid) =>
		api.post(`/usuario/alumno/retos/nuevo/amigo/${asignaturaid}/${amigoid}`)
	return {
		apisauce: api,
		getPending,
		getChallengeResult,
		rejectChallenge,
		challengeRandom,
		challengeFriend,
	}
}

export default {
	create
}
