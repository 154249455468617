import styled from 'styled-components';

const Wrapper = styled.div`
  ${props => ({ ...props.css })};
  width: ${p => p.size || '40px'};
  height: ${p => p.size || '40px'};
  position: relative;
`;

export default Wrapper;
