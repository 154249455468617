/**
 *
 * InputText
 *
 */

import React, { memo, useState } from 'react';
// import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import icon from './i-icon.png';
import eye from './ojo.png';
import Img from '../Img';
import Info from './icono-info/icono-info.png';

const InputElement = styled.input`
  color: var(--black);
  border: 2px solid var(--very-light-pink-two);
  padding: 23px 26px;
  border-radius: 20px;
  font-size: 18px;
  background-color: var(--very-light-pink-three);
  flex: 1;

  &::placeholder {
    color: var(--brown-grey);
  }
  &:active,
  &:focus {
    border-color: var(--black);
    outline: 0 none;
  }
  &.error {
    border-color: var(--red);
    &::before {
      content: '';
    }
  }
  ${media.lessThan('medium')`
    padding: 16px 23px;
    font-size: 15px;
    border-radius: 15px;
  `}
`;
const containerStyle = css`
  position: relative;
  display: flex;
  & > img {
    opacity: 0;
    position: absolute;
    left: -40px;
    top: 20px;
  }
  &.error > img {
    opacity: 1;
  }
`;
const EyeButton = styled.button`
  position: absolute;
  right: 10px;
  top: 25px;
  appearance: none;
  border: 0 none;
  background-color: transparent;

  ${media.lessThan('medium')`
    top: 15px;
  `}
`;
const ToolTip = styled.div`
  position: absolute;
  right: -50px;
  display: flex;
  top: 50%;
  transform: translateY(-50%);

  ${media.lessThan('medium')`
    > img {
      width: 25px;
    }

    right: -30px !important;
  `}

  > div {
    display: flex;
    position: relative;

    > span {
      position: absolute;
      background: white;
      padding: 15px;
      border: 1px solid black;
      width: 200px;
      left: 55px;
      border-radius: 15px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;

      ${media.lessThan('medium')`
        transform: translate(-100%, -85%);
        left: -10px;
      `}

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -30px;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-right-color: black;
        top: 50%;
        transform: translateY(-50%);

        ${media.lessThan('medium')`
          top: 86%;
          left: 100%;
          border-right-color: transparent;
          border-left-color: black;
        `}
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -27px;
        width: 0;
        height: 0;
        border: 14px solid transparent;
        border-right-color: white;
        top: 50%;
        transform: translateY(-50%);

        ${media.lessThan('medium')`
          top: 86%;
          left: 100%;
          border-right-color: transparent;
          border-left-color: white;
        `}
      }
    }
  }

  //&:hover > div {
  //  display: flex;
  //}
`;

function InputText(props) {
  const { inputStyle, style, error, className, type, tooltip, ...rest } = props;
  const c = css`
    ${containerStyle}
    ${props.containerStyle}
  `;
  const [innerType, setInnerType] = useState(type);
  const [toolOpen, setToolOpen] = useState(false);
  const handleEyeClick = event => {
    event.preventDefault();
    if (innerType === 'password') {
      setInnerType('text');
    } else {
      setInnerType('password');
    }
  };
  return (
    <div style={style} className={error ? `error ${className}` : className} css={c}>
      <img src={icon} alt="" />
      <InputElement
        style={inputStyle}
        type={innerType}
        {...rest}
        className={error ? 'error' : ''}
      />
      {type === 'password' && (
        <EyeButton onClick={handleEyeClick}>
          <Img src={eye} alt="Mostrar / Ocultar contraseña" />
        </EyeButton>
      )}
      {tooltip && (
        <ToolTip
          onClick={() => setToolOpen(o => !o)}
          onMouseEnter={() => setToolOpen(true)}
          onMouseLeave={() => setToolOpen(false)}
        >
          {toolOpen && (
            <div>
              <span>{tooltip}</span>
            </div>
          )}
          <img src={Info} alt="Info" />
        </ToolTip>
      )}
    </div>
  );
}

InputText.propTypes = {};

export default memo(InputText);
