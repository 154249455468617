/**
 *
 * GoToTop
 *
 */

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

function GoToTop({children, active = true}) {

  useEffect(() => {
    active && window.scrollTo({ top: 0, behavior: 'smooth'});
  }, [active]);

  return <>
    {children}
  </>;
}

GoToTop.propTypes = {
  active: PropTypes.bool
};

export default GoToTop;
