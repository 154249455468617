/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, putResolve} from 'redux-saga/effects'
import PokesActions from '../Redux/PokesRedux'
import Strings from '../Config/Strings'

export function * sendPoke(api,action){
	const {categoryId,toId,pokeCategory} = action;
	const response = yield call(api.poke,categoryId,toId,pokeCategory);
	if(response.data.errno != 0){
		yield put(PokesActions.sendPokeFailure(Strings.GENERIC_ERROR))
	}
	else{
		yield put(PokesActions.sendPokeSuccess());
	}
}
export function * getPokes(api,action){
	const {categoryId} = action;
	const response = yield call(api.getPokes,categoryId);
	if(response.data.errno != 0){
		yield put(PokesActions.getPokesFailure(Strings.GENERIC_ERROR))
	}
	else{
		const response2 = yield call(api.getAllPokes);
		if(response2.data.errno != 0){
			yield put(PokesActions.getPokesFailure(Strings.GENERIC_ERROR))
		}
		else {
			yield put(PokesActions.getPokesSuccess({categoryList:response.data.toques,all:response.data.toques}));
		}
	}
}

export function * getAllPokes(api,action){
	const response = yield call(api.getAllPokes);
	if(response.data.errno != 0){
		yield put(PokesActions.getAllPokesFailure(Strings.GENERIC_ERROR))
	}
	else{
		yield put(PokesActions.getAllPokesSuccess(response.data.toques));
	}
}
export function * clearPokes(api,action){
	const {categoryId, pokeCategory} = action;
	const response = yield call(api.markAsRead,categoryId,pokeCategory);
	if(!response || !response.data || response.data.errno != 0){
		yield put(PokesActions.clearPokesFailure(Strings.GENERIC_ERROR))
	}
	else{
		putResolve(PokesActions.getAllPokesRequest());
		yield put(PokesActions.clearPokesSuccess(response.data.toques));
	}
}
