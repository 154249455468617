/* eslint-disable */
import standardApi from "./standardApi";


const create = () => {
	const api = standardApi();
	const teacherLoginGoogle = (token) => api.post('/sso/auth/profesor/google', {token})
	const fatherLoginGoogle = (token) => api.post('/sso/auth/padre/google', {token})
	const studentLoginGoogle = (token) => api.post('/sso/auth/google', {token})
	const teacherLoginMicrosoft = (tokenType, accessToken) => api.post('/sso/auth/profesor/microsoft', {tokenType, accessToken})
	const fatherLoginMicrosoft = (tokenType, accessToken) => api.post('/sso/auth/padre/microsoft', {tokenType, accessToken})
	const studentLoginMicrosoft = (tokenType, accessToken) => api.post('/sso/auth/microsoft', {tokenType, accessToken})
	return {
		apisauce: api,
		teacherLoginGoogle,
		fatherLoginGoogle,
		studentLoginGoogle,
		teacherLoginMicrosoft,
		fatherLoginMicrosoft,
		studentLoginMicrosoft,
	}
}

export default {
	create
}
