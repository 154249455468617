/**
 *
 * BuyModalPopup
 *
 */

import React from 'react';
import BuyModal from '../../containers/BuyModal';
import MainModal from '../Modal';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function BuyModalPopup({ onOpen, onClose, header }) {
  return (
    <MainModal modalIsOpen={onOpen} onRequestClose={() => onClose(false)}>
      <BuyModal header={header} onRequestClose={() => onClose(false)} />
    </MainModal>
  );
}

BuyModalPopup.propTypes = {};

export default BuyModalPopup;
