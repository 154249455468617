/* eslint-disable */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import {all, call, put, putResolve} from 'redux-saga/effects'
import AuthActions from '../Redux/AuthRedux'
import UserActions  from '../Redux/UserRedux';
import SchoolActions from '../Redux/SchoolRedux';
import GameActions from '../Redux/GameRedux'
//import BuyActions from '../../../CerebritiApp/App/Redux/BuyRedux'
import AppConfig from '../Config/AppConfig'
import Environment from "../Config/Environment";
import SubjectAction from '../Redux/SubjectRedux';
import TopicAction from '../Redux/TopicRedux';
import StudentAction, {putStudents} from '../Redux/StudentRedux';
import SSOApi from "../Services/SSOApi";
import PokesActions from "../Redux/PokesRedux";
import MasterTablesActions from "../Redux/MasterTablesRedux";

export function * login (api, configurePushNotifications, platform, device, action) {
	const {login, password, skipRedirect, postAction, network, token, tokenType, accessToken} = action;
	let pushtoken = null;
	if(configurePushNotifications){
		try{
			pushtoken = yield call(configurePushNotifications);
		}
		catch(err){
			console.error(err.message, err);
		}
	}
	let sso = SSOApi.create();
	let response;
	if(!network) {
		response = yield call(api.login, login, password, pushtoken, platform, device)
	} else if (network === 'google') {
		response = yield call(sso.studentLoginGoogle, token)
	} else if (network === 'microsoft') {
		response = yield call(sso.studentLoginMicrosoft, tokenType, accessToken)
	} else {
		console.error("Login impossible, unknown network ", network);
		return;
	}

	// success?
	if (response.ok) {
		if(response.data.errno == 2){
			//yield put(MessagesActions.messageShow("Los datos no son válidos","Error"));
			yield put(AuthActions.loginFailure())
		}
		else if(response.data.errno == 8){
			//yield put(MessagesActions.messageShow("Debes rellenar todos los campos","Error"));
			yield put(AuthActions.loginFailure())
		} else if (response.data.errno === 80 || response.data.errno === 78) {
			yield put(AuthActions.loginFailure(response.data.errno))
		}
		else{
			AppConfig.parentToken = response.data.token;
			console.log("Got Token: " + AppConfig.parentToken)
			yield putResolve(MasterTablesActions.masterTablesRequest(response.data.info.country_code || Environment.getCountry()));
			yield put(AuthActions.loginSuccess(response.data));
			yield put(UserActions.profileSuccess(response.data));
			yield put(Environment.getPostLoginAction());

			if (response.data.info && response.data.info.version_coles) {
				const child = response.data.hijos[0];
				yield putResolve(GameActions.selectPlayer(child));
			}

			if(!skipRedirect){
				// console.log("Profille data",response.data);
				if(response.data.info.version_coles) {
					const child = response.data.hijos[0];

					yield putResolve(GameActions.clearSearch());
					yield putResolve(UserActions.clearFriendshipRequest());
					yield putResolve(PokesActions.getPokesRequest(child.asignaturas_visibles[0].id));
					yield putResolve(GameActions.selectCategory(child.asignaturas_visibles[0].id));
					yield putResolve(GameActions.getFriendsRequest());

					yield put(Environment.createNavigationAction('PlayerStatusPanel'));
				}else if(!response.data.soypadre && !response.data.info["confirmed-by-parent"] && false){
					console.log("hijo sin validar")
					yield put(Environment.createNavigationAction('ValidateFatherInfoScreen'));
				}
				else if(response.data.soypadre){
					console.log("padre")
					yield put(Environment.createNavigationAction('ProfileSelectionScreen'));
				}

			}

			if(postAction){
				yield putResolve(postAction);
			}
		}
	} else {
		yield put(AuthActions.loginFailure())
	}
}

export function * loginTeacher (api, action) {
	const {email, password, skipRedirect} = action;

	const response = yield call(api.teachersAreaLogin, email, password)

	// success?
	if (response.ok) {
		if(response.data.errno == 2){
			//yield put(MessagesActions.messageShow("Los datos no son válidos","Error"));
			yield put(AuthActions.loginFailure())
		}
		else if(response.data.errno == 8){
			//yield put(MessagesActions.messageShow("Debes rellenar todos los campos","Error"));
			yield put(AuthActions.loginFailure())
		}
		else{
			AppConfig.userToken = response.data.token;
			AppConfig.teacherToken = response.data.token;
			console.log("Got Token: " + AppConfig.teacherToken)
			yield put(AuthActions.loginTeacherSuccess(response.data));
			// yield put(UserActions.profileTeacherSuccess(response.data));
			yield put(SchoolActions.profileSchoolSuccess(response.data));
			yield put(StudentAction.putStudents(response.data.info.classes));

			if (!skipRedirect) {
				yield put(Environment.createNavigationAction('dashboard'));
			}
		}
	} else {
		yield put(AuthActions.loginFailure())
	}
}

export function * requestPasswordCode (api, action) {
	const {email} = action
	const response = yield call(api.requestRecover, email)

	// success?
	if (response.ok) {
		if(response.data.errno == 20){
			//yield put(MessagesActions.messageShow("Los datos no son válidos","Error"));
			yield put(AuthActions.requestPasswordCodeFailure())
		}
		else if(response.data.errno == 8){
			//yield put(MessagesActions.messageShow("Debes rellenar todos los campos","Error"));
			yield put(AuthActions.requestPasswordCodeFailure())
		}
		else{
			yield put(AuthActions.requestPasswordCodeSuccess(response.data))
		}
	} else {
		yield put(AuthActions.requestPasswordCodeFailure())
	}
}
export function * changePassword (api, action) {
	const {password, code} = action
	const response = yield call(api.changePassword, code, password);

	// success?
	if (response.ok) {
		if(response.data.errno != 0){
			//yield put(MessagesActions.messageShow("Los datos no son válidos","Error"));
			yield put(AuthActions.changePasswordFailure())
		}
		else{
			yield put(AuthActions.changePasswordSuccess(response.data))
		}
	} else {
		yield put(AuthActions.changePasswordFailure())
	}
}

export function * deleteUserAccount (api, action) {
	const {id} = action
	const response = yield call(api.deleteUserAccount, id);

	if (response.ok) {
		if(response.data.errno != 0){
			console.log('ERROR H')
		}
		else{
			yield putResolve(AuthActions.logout())
		}
	} else {
		console.log('ERROR')
	}
}

export function * logout () {
	yield all([
		putResolve(UserActions.clearUser()),
		putResolve(GameActions.clearGame()),
		putResolve(SubjectAction.subjectReset()),
		putResolve(TopicAction.topicReset())
	])
	AppConfig.parentToken = null;
	AppConfig.playerToken = null;
	AppConfig.teacherToken = null;
}
