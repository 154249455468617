/* eslint-disable */
import standardApi from "./standardApi";


const create = () => {
	const api = standardApi();
	const login = (user, pass, pushtoken = null, platform = null, device = null) =>
		api.post('/auth/login', {user, pass, pushtoken, platform, device })
	const studentLogin = (user, pass) => api.post('/auth/login-alumno', {user, pass})
	const teacherLogin = (user, pass) => api.post('/auth/login-profesor', {user, pass})
	const teachersAreaLogin = (email, pass) => api.post('/colegios/login-teacher', {email, pass})
	const fatherLogin = (email, pass) => api.post('/auth/login-padre', {email, pass})
	const adminLogin = (email, pass) => api.post('/auth/login-superadmin', {email, pass})
	const requestRecover = (email) => api.post('/auth/pedir-codigo-recuperacion', {email})
	const changePassword = (codigo, nuevopassword) => api.post('/auth/usar-codigo-recuperacion', {codigo, nuevopassword})
	const deleteUserAccount = (id) => api.delete(`/usuario/padre/${id}`)
	return {
		apisauce: api,
		studentLogin,
		teacherLogin,
		fatherLogin,
		adminLogin,
		login,
		teachersAreaLogin,
		requestRecover,
		changePassword,
		deleteUserAccount
	}
}

export default {
	create
}
