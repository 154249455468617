/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createRootSaga from 'academons-shared-library/src/Sagas/';
import immutablePersistenceTransform from 'academons-shared-library/src/Services/ImmutablePersistenceTransform';
import createReducer from './reducers';
import AppConfig from './config/AppConfig';
import getAppSagas from './redux/appSagas';

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;
  const sagaMonitor = AppConfig.useReactotron ? console.tron.createSagaMonitor() : null;

  const reduxSagaMonitorOptions = { sagaMonitor };

  const storeConfig = {
    key: 'root_v' + AppConfig.storageVersion,
    storage,
    // Reducer keys that you do NOT want stored to persistence here.
    blacklist: ['router', 'global', 'pokes', 'buy', 'shop', 'transient'],
    // Optionally, just specify the keys you DO want stored to persistence.
    // An empty array means 'don't store any reducers' -> infinitered/ignite#409
    // whitelist: [],
    transforms: [immutablePersistenceTransform],
  };

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  if (AppConfig.useReactotron) {
    enhancers.push(console.tron.createEnhancer());
  }

  const persistedReducer = persistReducer(storeConfig, createReducer());
  const store = createStore(persistedReducer, initialState, composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  const customSagas = getAppSagas();
  sagaMiddleware.run(createRootSaga(customSagas));

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return {
    store,
    persistor: persistStore(store),
  };
}
