import styled from 'styled-components';

import buttonStyles, { smallButtonStyles } from './buttonStyles';

const A = styled.a`
  ${buttonStyles};
`;
export const ASmall = styled.a`
  ${smallButtonStyles};
`;
export default A;
