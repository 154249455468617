import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  studentDeleteRequest: ['studentId', 'classId'],
  studentDeleteSuccess: ['payload'],
  studentDeleteFailure: ['errorMessage'],
  putStudents: ['students'],
});

export const StudentTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  students: [],
});

export const studentDeleteRequest = (state) =>
  state.merge({ fetching: true, error: null });

export const studentDeleteSuccess = (state, action) => {
  const { classId, studentId } = action.payload;
  let newStudents = JSON.parse(JSON.stringify(state.students));



  newStudents[classId].students = newStudents[classId].students.filter(
    ({ id }) => studentId !== id
  );

  return state.merge({
    fetching: false,
    error: null,
    students: newStudents,
  });
};

export const studentDeleteFailure = (state, action) =>
  state.merge({
    fetching: false,
    error: true,
    errorMessage: action.errorMessage,
  });

export const putStudents = (state, action) => {
  let list = {};

  action.students.forEach(({ id, students, name }) => {
    list[id] = {
      students: students.map((item, i) => ({
        key: i,
        id: item.id,
        name: item.name,
        username: item.name,
        login: 'google',
      })),
      className: name,
    };
  });

  return state.merge({
    students: list,
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.STUDENT_DELETE_REQUEST]: studentDeleteRequest,
  [Types.STUDENT_DELETE_SUCCESS]: studentDeleteSuccess,
  [Types.STUDENT_DELETE_FAILURE]: studentDeleteFailure,
  [Types.PUT_STUDENTS]: putStudents,
});
