import React, { createContext, useEffect, useState } from 'react';
import { throttle } from 'lodash';

const getDi = (width, height, imgH, imgW) => {
  let h = (width * imgH) / imgW;
  let w = width;

  if (h < height) {
    h = height;
    w = (height * imgW) / imgH;
  }

  return { h, w };
};

const imgH = 1050;
const imgW = 1680;

const initValues = {
  imgH,
  imgW,
};

export const UseSizeContext = createContext({
  height: getDi(window.innerWidth, window.innerHeight, imgH, imgW).h,
  width: getDi(window.innerWidth, window.innerHeight, imgH, imgW).w,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  ...initValues,
});

const UseSize = ({ children }) => {
  const [height, setHeight] = useState(getDi(window.innerWidth, window.innerHeight, imgH, imgW).h);
  const [width, setWidth] = useState(getDi(window.innerWidth, window.innerHeight, imgH, imgW).w);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateSize = throttle(() => {
      setHeight(getDi(window.innerWidth, window.innerHeight, imgH, imgW).h);
      setWidth(getDi(window.innerWidth, window.innerHeight, imgH, imgW).w);
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }, 1000 / 60);
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [imgH, imgW]);

  return (
    <UseSizeContext.Provider
      value={{
        height,
        width,
        windowWidth,
        windowHeight,
        ...initValues,
      }}
    >
      {children}
    </UseSizeContext.Provider>
  );
};

export default UseSize;
