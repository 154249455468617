/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const PRODUCTS_REQUEST = 'products/PRODUCTS_REQUEST';
export const PRODUCTS_SUCCESS = 'products/PRODUCTS_SUCCESS';
export const PRODUCTS_FAILURE = 'products/PRODUCTS_FAILURE';
export const SUBSCRIBE_REQUEST = 'products/SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'products/SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'products/SUBSCRIBE_FAILURE';
