// Simple React Native specific changes

export default {
	_playerToken: null,
	_teacherToken: null,
	get playerToken() {
		return this._playerToken;
	},
	set playerToken(val) {
		//console.log("Player token changed to: " + val);
		return (this._playerToken = val);
	},
	_parentToken: null,
	get parentToken() {
		return this._parentToken;
	},
	set parentToken(val) {
		//console.log("Player token changed to: " + val);
		return (this._parentToken = val);
	},
	get teacherToken() {
		return this._teacherToken;
	},
	set teacherToken(val) {
		//console.log("Teacher token changed to: " + val);
		return (this._teacherToken = val);
	},
	emailFrequencies: ['7DIAS', '15DIAS', 'NUNCA'],
	gameDifficult: 70,
	GOOGLE_ANALYTICS_ID: 'UA-131179120-1',
	instaBugKey: 'a9ecf7e9320e6634b7e75b5901fbb878',
	urlPrivacidad: 'http://www.academons.com/ppriv',
	urlTerminos: 'http://www.academons.com/bbll',
	urlPrivacidadUS: 'http://www.academons.com/ppriv',
	urlTerminosUS: 'http://www.academons.com/bbll',
};
