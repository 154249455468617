/* eslint-disable prettier/prettier */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import {call, put} from 'redux-saga/effects'
import GameActions from '../Redux/GameRedux'
import Strings from '../Config/Strings'

export function * updateAfterGame(api,action) {
	const response = yield call(api.getChild)
	if(response.data.errno != 0){
		yield put(GameActions.updateAfterGameFailure(Strings.GENERIC_ERROR))
	}
	else{
		console.log(response.data);
		yield put(GameActions.updateAfterGameSuccess(response.data));
	}
}

export function * getTrophies(api,action){
	const response = yield call(api.getTrophies)
	if(response.data.errno != 0){
		yield put(GameActions.getTrophiesFailure(Strings.GENERIC_ERROR))
	}
	else{
		let noNotificados = response.data.trofeos.filter(x => !x.bcomunicado && x.bconseguido);
		if(noNotificados[0] != null){
			noNotificados[0].bcomunicado = true;
			yield call(api.markTrophyAsSeen,noNotificados[0].codigo);
		}
		yield put(GameActions.getTrophiesSuccess({list:response.data.trofeos,current:noNotificados[0]}));
	}
}
export function * getFriendsRequestSend(api,action){
	const response = yield call (api.getPendingSends)
	if(response.data.errno !=0){
		yield put(GameActions.getFriendsRequestSendFailure(Strings.GENERIC_ERROR))
	}else{
		yield put(GameActions.getFriendsRequestSendSuccess({enviadas:response.data.pendientes}));
	}
}

export function * getFriends(api,action){
	const response = yield call(api.getFriends)
	if(response.data.errno != 0){
		yield put(GameActions.getFriendsFailure(Strings.GENERIC_ERROR))
	}
	else{
		const response2 = yield call(api.getPendingFriends)
		if(response2.data.errno != 0){
			yield put(GameActions.getFriendsFailure(Strings.GENERIC_ERROR))
		}
		else{
			yield put(GameActions.getFriendsSuccess({amigos:response.data.amigos,pendientes:response2.data.amigospendientes}));
		}
	}
}

export function * searchFriends(api,action){
	let {term} = action;
	const response = yield call(api.searchFriends,term)
	if(response.data.errno != 0){
		yield put(GameActions.searchFriendsFailure(Strings.GENERIC_ERROR))
	}
	else{
		const response2 = yield call(api.getPendingFriends)
		if(response2.data.errno != 0){
			yield put(GameActions.searchFriendsFailure(Strings.GENERIC_ERROR))
		}
		else{
			yield put(GameActions.searchFriendsSuccess(response.data.info));
		}
	}
}
