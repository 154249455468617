import { select, call, put } from 'redux-saga/effects';
import { loadStripe } from '@stripe/stripe-js';
import AppConfig from '../../config/AppConfig';
import { productsFailure, productsSuccess, subscribeFailure, subscribeSuccess } from './actions';

export function* productsRequest(api, action) {
  const result = yield call(api.getProducts);
  if (!result.ok || result.status !== 200) {
    yield put(productsFailure('Error de red'));
  } else {
    yield put(productsSuccess(result.data));
  }
}

export function* subscribe(api, action) {
  const { productId } = action;
  const { priceID, currency } = yield select(
    state => state.products.products.filter(x => x.id === productId)[0],
  );
  const result = yield call(api.createCheckoutSession, priceID, currency);
  const { publishableKey } = yield select(state => state.products);
  const stripe = yield loadStripe(publishableKey);
  if (!result.ok || result.status !== 200) {
    yield put(subscribeFailure('Error de red'));
  } else {
    // stripe.redirectToCheckout({ sessionId: result.data.checkoutSessionId });
    stripe.redirectToCheckout({
      sessionId: result.data.checkoutSessionId,
    });
    yield put(subscribeSuccess(result.data));
  }
}
