/* eslint-disable */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import {call, put} from 'redux-saga/effects';
import SubjectAction from '../Redux/SubjectRedux';
import Strings from '../Config/Strings';

export function * getSubject (api, action) {
	const {classID, subjectID} = action;

	const response = yield call(api.requestSubject, classID, subjectID)

	if(!response.ok || response.data.errno != 0){
		yield put(SubjectAction.subjectFailure(Strings.GENERIC_ERROR))
	} else {
		yield put(SubjectAction.subjectSuccess(response.data))
	}
}

export function * updateTopicStatus (api, action) {
	const {classID, topicID, status} = action;
	const response = yield call(api.topicToggle, classID, topicID, status)

	if(!response.ok || response.data.errno != 0){
		yield put(SubjectAction.topicStatusUpdateFailure(Strings.GENERIC_ERROR))
	} else {
		yield put(SubjectAction.topicStatusUpdateSuccess({topicID, status}))
	}
}

