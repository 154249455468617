/**
 *
 * Pet
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import IMAGES from '../../images';
import Img from '../Img';

const Container = styled.div`
  position: relative;
`;
const overlay = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
`;
const SETTINGS = {
  '1': {
    name: 'Mascota',
    position: 'right bottom',
    size: '25%',
  },
  '2': {
    name: 'Máscara',
    position: 'center center',
    size: '40%',
  },
  '3': {
    name: 'Objeto',
    position: 'left 7% bottom',
    size: '40%',
  },
  '4': {
    name: 'Bandera',
    position: 'right 3% bottom 0',
    size: '30%',
  },
  '5': {
    name: 'Estado',
    position: 'left top',
    size: '24%',
  },
};
const getImage = item => (item ? `url(${item.imagen})` : 'url()');
function Pet(props) {
  const { level, category, isSmall, personalization = [], ...rest } = props;
  const img = IMAGES.categories[`${category}Avatar${isSmall ? 'Small' : ''}${level}`];
  const divStyles = css`
    ${overlay};
    background-image: ${getImage(personalization['1'])}, ${getImage(personalization['2'])},
      ${getImage(personalization['3'])}, ${getImage(personalization['4'])},
      ${getImage(personalization['5'])};
    background-size: ${SETTINGS['1'].size}, ${SETTINGS['2'].size}, ${SETTINGS['3'].size},
      ${SETTINGS['4'].size}, ${SETTINGS['5'].size};
    background-position: ${SETTINGS['1'].position}, ${SETTINGS['2'].position},
      ${SETTINGS['3'].position}, ${SETTINGS['4'].position}, ${SETTINGS['5'].position};
  `;
  return (
    <Container {...rest}>
      <Img src={img} alt="Mascota" style={{ width: '100%' }} />
      <div css={divStyles} />
    </Container>
  );
}

Pet.propTypes = {
  level: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
  personalization: PropTypes.object,
};

export default memo(Pet);
