/**
 *
 * Text
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import media from 'styled-media-query';
import { FormattedMessage } from 'react-intl';

const title = css`
  font-family: var(--title-fonts);
  line-height: 1;
`;
const text = css`
  font-family: var(--text-fonts);
  line-height: 1.2;
`;
export const presets = {
  bigTitle: css`
    ${title};
    font-size: 36px;
  `,
  title1: css`
    ${title};
    font-size: 26px;
    ${media.lessThan('medium')`
      font-size: 17.5px;
    `}
  `,
  title2: css`
    ${title};
    font-size: 24px;
  `,
  title3: css`
    ${title};
    font-size: 22px;
  `,
  title4: css`
    ${title};
    font-size: 20px;
  `,
  title5: css`
    ${title};
    font-size: 18px;
  `,
  title6: css`
    ${title};
    font-size: 16px;
  `,
  button: css`
    ${text};
    font-weight: bold;
    font-size: 20px;
    ${media.lessThan('medium')`
      font-size: 16px;
    `}
  `,
  textXXL: css`
    ${text};
    font-size: 28px;
    line-height: normal;
  `,
  textXXLBold: css`
    ${text};
    font-size: 28px;
    font-weight: bold;
  `,
  textXL: css`
    ${text};
    font-size: 24px;
    line-height: normal;
  `,
  textXLBold: css`
    ${text};
    font-size: 24px;
    font-weight: bold;
  `,
  textL: css`
    ${text};
    font-size: 20px;
    line-height: 1.4;
  `,
  textLBold: css`
    ${text};
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  `,
  text: css`
    ${text};
    font-size: 18px;
    line-height: 1.3;
    ${media.lessThan('medium')`
      font-size: 15px;
    `}
  `,
  textBold: css`
    ${text};
    font-weight: bold;
    font-size: 18px;
  `,
  textS: css`
    ${text};
    font-size: 16px;
  `,
  textSBold: css`
    ${text};
    font-size: 16px;
    font-weight: bold;
  `,
  textXS: css`
    ${text};
    font-size: 14px;
    ${media.lessThan('medium')`
      font-size: 12px;
    `}
  `,
  textXSBold: css`
    ${text};
    font-size: 14px;
    font-weight: bold;
    ${media.lessThan('medium')`
      font-size: 12px;
    `}
  `,
  textXXS: css`
    ${text};
    font-size: 12px;
  `,
};

function Text(props) {
  const c = css`
    ${presets[props.preset]};
    ${props.css};
    color: ${props.textColor};
  `;
  const { tagName, style, children, txt, txtValues = {} } = props;
  return (
    <p as={tagName} css={c} style={style} {...props}>
      {txt ? (
        <FormattedMessage
          id={txt}
          values={{
            ...txtValues,
            strong: msg => <strong>{msg}</strong>,
            br: () => <br />,
          }}
        />
      ) : (
        children
      )}
    </p>
  );
}

Text.propTypes = {
  preset: PropTypes.string,
  tagName: PropTypes.string,
  css: PropTypes.object,
  style: PropTypes.object,
  children: PropTypes.node,
  textColor: PropTypes.string,
  txt: PropTypes.string,
  txtValues: PropTypes.object,
};
Text.defaultProps = {
  preset: 'text',
  tagName: 'p',
};
export default memo(Text);
