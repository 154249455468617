import { putResolve, select } from 'redux-saga/effects';
import AppConfig from '../../config/AppConfig';
import { productsRequest } from '../../redux/products/actions';
import GameActions from 'academons-shared-library/src/Redux/GameRedux';

/**
 * Web Boot code
 */
// eslint-disable-next-line no-empty-function
export function* webStartup() {
  console.log('Web boot');
  const player = yield select(state => state.game.player);
  const auth = yield select(state => state.auth.accessToken);
  if (player) {
    AppConfig.playerToken = player.token;
  }
  AppConfig.parentToken = auth;
  yield putResolve(productsRequest());
  yield putResolve(GameActions.clearCurrentTrophy());
}
export function* postLogin() {
  console.log('post login');
}
