/* eslint-disable prettier/prettier */
import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	getListRequest: ['asignaturaId'],
	getListSuccess: ['payload'],
	getListFailure: null,
	getInfoNeurosRequest: ['asignaturaId'],
	getInfoNeurosSuccess: ['payload'],
	getInfoNeurosFailure: null,
	clearShop: [],
	setNeuros: ['neuros'],
	clearListShop: [],
	buyObjectRequest: ['objId', 'asignaturaId','useObjects', 'next'],
	buyObjectSuccess: ['payload'],
	buyObjectFailure: null,
	useObjectRequest: ['objId', 'asignaturaId', 'next'],
	useObjectSuccess: ['payload'],
	useObjectFailure: null,
	delObjectRequest: ['objId', 'asignaturaId'],
	delObjectSuccess: ['payload'],
	delObjectFailure: null
})

export const ShopTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	data: null,
	fetching: null,
	payload: null,
	error: null
})

/* ------------- Reducers ------------- */



export const getListRequest = (state) =>
	state.merge({fetching: true})

export const getListSuccess = (state, {payload}) => {
	//console.log("PAYLOAD SHOP", payload)
	return state.merge({fetching: false, error: null, data: payload})
}

export const getListFailure = (state, action) =>{
	return state.merge({fetching: false, error: true})
}

export const getInfoNeurosRequest = (state) =>
	state.merge({fetching: true})

export const getInfoNeurosSuccess = (state, {payload}) => {
	console.log("PAYLOAD SHOP", payload)
	return state.merge({fetching: false, error: null, data: payload})
}

export const getInfoNeurosFailure = (state, action) =>{
	return state.merge({fetching: false, error: true})
}

export const clearShop = (state, action) => {
	return state.merge({data:  null})
}

export const setNeuros = (state, {neuros}) => {
	return state.merge({data: neuros})
}

export const clearListShop = (state, action) => {
	let d = { neuros: state.data.neuros}
	return state.merge({data:  d})
}

export const buyObjectRequest = (state) =>
	state.merge({fetching: true})

export const buyObjectSuccess = (state, {payload}) => {
	let d = state.data.merge({neuros: payload.neuros})
	return state.merge({fetching: false, error: null, data: d})
}

export const buyObjectFailure = (state, action) => {
	return state.merge({fetching: false, error: true})
}

export const useObjectRequest = (state) =>
	state.merge({fetching: true})

export const useObjectSuccess = (state, {payload}) => {
	return state.merge({fetching: false, error: null})
}

export const useObjectFailure = (state, action) => {
	return state.merge({fetching: false, error: true})
}

export const delObjectRequest = (state) =>
	state.merge({fetching: true})

export const delObjectSuccess = (state, {payload}) => {
	return state.merge({fetching: false, error: null})
}

export const delObjectFailure = (state, action) => {
	return state.merge({fetching: false, error: true})
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

	[Types.GET_LIST_REQUEST]: getListRequest,
	[Types.GET_LIST_SUCCESS]: getListSuccess,
	[Types.GET_LIST_FAILURE]: getListFailure,
	[Types.GET_INFO_NEUROS_REQUEST]: getInfoNeurosRequest,
	[Types.GET_INFO_NEUROS_SUCCESS]: getInfoNeurosSuccess,
	[Types.GET_INFO_NEUROS_FAILURE]: getInfoNeurosFailure,
	[Types.CLEAR_SHOP]: clearShop,
	[Types.SET_NEUROS]: setNeuros,
	[Types.CLEAR_LIST_SHOP]: clearListShop,
	[Types.BUY_OBJECT_REQUEST]: buyObjectRequest,
	[Types.BUY_OBJECT_SUCCESS]: buyObjectSuccess,
	[Types.BUY_OBJECT_FAILURE]: buyObjectFailure,
	[Types.USE_OBJECT_REQUEST]: useObjectRequest,
	[Types.USE_OBJECT_SUCCESS]: useObjectSuccess,
	[Types.USE_OBJECT_FAILURE]: useObjectFailure,
	[Types.DEL_OBJECT_REQUEST]: delObjectRequest,
	[Types.DEL_OBJECT_SUCCESS]: delObjectSuccess,
	[Types.DEL_OBJECT_FAILURE]: delObjectFailure

})
