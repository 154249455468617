/* eslint-disable */
import standardApi from "./standardApi";
import AppConfig from '../Config/AppConfig'



const create = (Platform = {}) => {
	const api = standardApi();

	const createFather = (nombre,apellidos,email,password,frecuencia="7DIAS", country) => {
		const headers = {};
		if(country) {
			headers['X-Country'] = country;
		}
		return api.post('/usuario/padre', {nombre,apellidos,email,password,frecuencia}, {headers})
	}
	const getFather = (fatherToken) => {
		if(fatherToken){
			var userToken = AppConfig.userToken;
			// console.log("User token stored",userToken,"new",fatherToken)
			AppConfig.userToken = fatherToken;
		}
		let result = api.get('/usuario/padre')
		AppConfig.userToken = userToken;
		return result;
	}
	const deleteFather = (idusuario,bborrarhijos) =>
		api.delete('/usuario/padre/'+idusuario, {bborrarhijos})
	const updateFather = (idusuario,nombre,email,frecuencia,password) =>
		api.put('/usuario/padre/'+idusuario, {nombre,email,frecuencia,password})

	const createChild = (nombre,paisid,genero,cursoid,listadoasignaturas,cuerpo,color,ojos,boca,piernas) =>
		api.post('/usuario/alumno-hijo', {nombre,paisid,cursoid,listadoasignaturas,cuerpo,color,ojos,boca,piernas})
	const getChild = () =>
		api.get('/usuario/alumno')
	const sendPaymentReceipt = (receipt) =>
		api.post('/usuario/padre/payment-receipt',{
			platform: Platform.OS === "ios" ? 'iTunes' : 'PlayStore',
			data: receipt
		})
	const sendPaymentReceipt2 = (receipt) =>
		api.post('/usuario/padre/payment-receipt',{
			platform: Platform.OS === "android" ? 'PlayStore' : 'iTunes',
			version: 2,
			os: Platform.OS,
			data: receipt
		})
	const updateChild = (id,nombre,listadoasignaturas) =>
		api.put('/usuario/alumno-hijo/' + id,{nombre,listadoasignaturas})
	const deleteChild = (idusuario) =>
		api.delete('/usuario/alumno/'+idusuario)
	const linkChild = (idusuario,hijoid) =>
		api.post('/usuario/padre/' + idusuario + '/anadir-hijo',{hijoid})
	const updateChildAvatar = (idusuario,cuerpo,color,ojos,boca,piernas) =>
		api.put(`/usuario/alumno-avatar`, {cuerpo,color,ojos,boca,piernas})
	const getTrophies = () =>
		api.get(`/usuario/alumno/trofeos`)
	const getFriends = () =>
		api.get(`/usuario/alumno/amigos`)
	const getFakeFriends = () =>
		api.get(`/usuario/alumno/fakeamigos`)
	const getPendingSends = () =>
		api.get(`/usuario/alumno/enviadas-pendientes`)
	const getPendingFriends = () =>
		api.get(`/usuario/alumno/amigos-pendientes`)
	const searchFriends = (term) =>
		api.get(`/usuario/alumno/busca-otro-alumno/${term}`)
	const friendshipRequest = (user) =>
		api.post(`/usuario/alumno/amigo/nueva-peticion/${user}`)
	const friendshipConfirm = (requestId) =>
		api.post(`/usuario/alumno/amigo/aceptar-peticion/${requestId}`)
	const friendshipReject = (requestId) =>
		api.post(`/usuario/alumno/amigo/rechazar-peticion/${requestId}`)
	const markMessageAsRead = (categoryId) =>
		api.post(`/usuario/alumno/mensaje-bicho-leido/${categoryId}`)
	const friendshipDelete = (friendid) =>
		api.delete(`/usuario/alumno/amigo/${friendid}`)
	const markNotificationAsRead = (idnotificacion) =>
		api.post(`/usuario/notificacion-leida/${idnotificacion}`)
	const changeCourse = (childId,courseId) =>
		api.post(`/usuario/alumno/${childId}/cambiar-curso/${courseId}`)

	return {
		apisauce: api,
		createFather,
		getFather,
		updateFather,
		deleteFather,
		createChild,
		updateChild,
		getChild,
		deleteChild,
		updateChildAvatar,
		linkChild,
		getTrophies,
		getFriends,
		getFakeFriends,
		getPendingFriends,
		getPendingSends,
		searchFriends,
		friendshipRequest,
		friendshipConfirm,
		friendshipReject,
		friendshipDelete,
		markMessageAsRead,
		sendPaymentReceipt,
		sendPaymentReceipt2,
		markNotificationAsRead,
		changeCourse
	}
}

export default {
	create
}
