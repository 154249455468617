/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import {
  PRODUCTS_FAILURE,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS, SUBSCRIBE_FAILURE,
  SUBSCRIBE_REQUEST, SUBSCRIBE_SUCCESS,
} from './constants';

// The initial state of the App
export const initialState = {
  publishableKey: null,
  products: [],
  loading: false,
  error: null,
};

/* eslint-disable default-case, no-param-reassign */
const productsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case PRODUCTS_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;
      case PRODUCTS_SUCCESS:
        draft.loading = false;
        draft.products = action.products;
        draft.publishableKey = action.publishableKey;
        break;
      case PRODUCTS_FAILURE:
        draft.loading = false;
        draft.error = action.error;
        break;
      case SUBSCRIBE_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;
      case SUBSCRIBE_SUCCESS:
        draft.loading = false;
        break;
      case SUBSCRIBE_FAILURE:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default productsReducer;
