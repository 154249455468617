/* eslint-disable prettier/prettier */
import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	challengesPendingRequest: [],
	challengesPendingSuccess: ['payload'],
	challengesPendingFailure: null,
	challengeFriendRequest: ['friendid'],
	challengeFriendSuccess: ['payload'],
	challengeFriendFailure: ['errcode'],
	challengeRandomRequest: [],
	challengeRandomSuccess: ['payload'],
	challengeRandomFailure: null,
	challengeResultRequest: ['id','doReplace','key'],
	challengeResultSuccess: ['payload'],
	challengeResultFailure: null,
	acceptChallenge: ['challenge'],
	rejectChallengeRequest: [],
	rejectChallengeSuccess: ['payload'],
	rejectChallengeFailure: null,
	clearChallenge: [],
	clearChallengeErrorCode: []
})

export const ChallengesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	data: null,
	fetching: null,
	error: null,
	challengeErrorCode: null,
	challengeResultCode: null,
	challengeResultSubject: null
})

/* ------------- Reducers ------------- */

export const challengesPendingRequest = (state, {data}) =>{
	return state.merge({fetching: true})
}

export const challengesPendingSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetching: false, lists: payload})
}
export const challengesPendingFailure = state =>
	state.merge({fetching: false, error: true})

export const challengeFriendRequest = (state,{friendid}) =>
	state.merge({fetching: true, challengeResultCode: null, challengeResultSubject:friendid, challengeErrorCode: null})
export const challengeFriendSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetching: false, challengeResultCode: payload.errno, current: payload.info})
}
export const challengeFriendFailure = (state, {errcode}) =>
	state.merge({fetching: false, error: true, challengeErrorCode: errcode})

export const challengeRandomRequest = (state) =>
	state.merge({fetching: true, challengeResultCode: null, challengeResultSubject:null})
export const challengeRandomSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetching: false, challengeResultCode: payload.errno, current: payload.info})
}
export const challengeRandomFailure = (state) =>
	state.merge({fetching: false, error: true})

export const challengeResultRequest = (state) =>
	state.merge({fetching: true})

export const challengeResultSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetching: false, result: payload.resultado})
}
export const challengeResultFailure = (state) =>
	state.merge({fetching: false, error: true})

export const acceptChallenge = (state,{challenge}) => {
	const c = {
		"id": challenge.idreto,
		"juegoid": challenge.idjuego,
		"nombrejuego": challenge.nombrejuego,
		"temareto": challenge.biblioteca,
		"urlparajugar": challenge.linkpartidareto,
		"recibido": true,
		"tiporeto": challenge.tiporeto,
		"oponente": {
		  "id": challenge.idretador,
		  "nombre": challenge.nombreretador,
		  "nick": challenge.nickretador,
		  "nivel": challenge.nivelretador,
		  "avatar": challenge.avatarretador
		},
		"gameprops": challenge.gameprops,
		"objetospuestos": challenge.objetospuestosretador
	}
	return state.merge({current:c})
}

export const rejectChallengeRequest = (state) =>
	state.merge({fetching: true})

export const rejectChallengeSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetching: false, current:null})
}
export const rejectChallengeFailure = (state) =>
	state.merge({fetching: false, error: true})

export const clearChallenge = (state) =>
	state.merge({fetching: true, challengeResultCode: null, challengeResultSubject:null, current:null, challengeErrorCode: null, result: null})
export const clearChallengeErrorCode = (state) =>
	state.merge({fetching: true, challengeErrorCode: null})
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.CHALLENGES_PENDING_REQUEST]: challengesPendingRequest,
	[Types.CHALLENGES_PENDING_SUCCESS]: challengesPendingSuccess,
	[Types.CHALLENGES_PENDING_FAILURE]: challengesPendingFailure,
	[Types.CHALLENGE_FRIEND_REQUEST]: challengeFriendRequest,
	[Types.CHALLENGE_FRIEND_SUCCESS]: challengeFriendSuccess,
	[Types.CHALLENGE_FRIEND_FAILURE]: challengeFriendFailure,
	[Types.CHALLENGE_RANDOM_REQUEST]: challengeRandomRequest,
	[Types.CHALLENGE_RANDOM_SUCCESS]: challengeRandomSuccess,
	[Types.CHALLENGE_RANDOM_FAILURE]: challengeRandomFailure,
	[Types.CHALLENGE_RESULT_REQUEST]: challengeResultRequest,
	[Types.CHALLENGE_RESULT_SUCCESS]: challengeResultSuccess,
	[Types.CHALLENGE_RESULT_FAILURE]: challengeResultFailure,
	[Types.ACCEPT_CHALLENGE]: acceptChallenge,
	[Types.REJECT_CHALLENGE_REQUEST]: rejectChallengeRequest,
	[Types.REJECT_CHALLENGE_SUCCESS]: rejectChallengeSuccess,
	[Types.REJECT_CHALLENGE_FAILURE]: rejectChallengeFailure,
	[Types.CLEAR_CHALLENGE]: clearChallenge,
	[Types.CLEAR_CHALLENGE_ERROR_CODE]: clearChallengeErrorCode,
})
