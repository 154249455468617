import { css } from 'styled-components';
import media from 'styled-media-query';

const buttonStyles = css`
  display: inline-block;
  position: relative;
  padding: 16px 50px;
  text-decoration: none;
  border-radius: 20px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-family: var(--title-fonts);
  font-size: 24px;
  color: var(--white);
  background-color: ${p => (p.transparent ? 'transparent' : 'var(--mango)')};
  border: ${p => (p.transparent ? '2px solid var(--white)' : '2px solid var(--mango)')};
  transition: border-color 150ms linear, color 150ms linear, background-color 150ms linear;
  &:disabled {
    background-color: var(--very-light-pink);
    border-color: var(--very-light-pink);
    cursor: auto;
    pointer-events: none;
  }
  &:hover {
    color: var(--white);
    background: var(--mango-light);
    border-color: var(--mango-light);
    text-decoration: none;
  }
  &:active,
  &:focus {
    background: var(--mango-light);
    border-color: var(--mango-light);
  }

  ${media.lessThan('medium')`
      font-size: 20px;
  `}
`;
export const smallButtonStyles = css`
  ${buttonStyles};
  padding: 13px 25px;
  font-size: 16px;
`;
export const xSmallButtonStyles = css`
  ${buttonStyles};
  border-radius: 15px;
  padding: 10px 30px;
  font-size: 13px;
`;
export const unstyledStyles = css`
  text-decoration: none;
  outline: 0 none;
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
`;
export default buttonStyles;
