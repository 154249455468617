/**
 *
 * ParentVerificationBox
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import Text from '../Text';
import Colors from '../../utils/Colors';
import icon from './padres-icon.png';
import Img from '../Img';
import InputText from '../InputText';
import Button from '../Button';
import TitledBox, { Body } from '../TitledBox';

const alignLeft = css`
  align-self: flex-end;
`;
const InputWrapper = styled.div`
  min-width: 440px;
  display: flex;
  flex-direction: column;
`;
function ParentVerificationBox(props) {
  const [pass, setPass] = useState('');
  const intl = useIntl();
  const {title = "fatherVerification.title", ...rest} = props;
  return (
    <TitledBox txt={title} {...rest}>
      <form
        onSubmit={e => {
          props.onSend(pass);
          e.preventDefault();
        }}
      >
        <Body>
          <Img src={icon} alt="" />
          <Text txt="fatherVerification.subtitle" />
          <InputWrapper>
            <InputText
              type="password"
              name="password"
              style={{ minWidth: 440 }}
              value={pass}
              onChange={e => setPass(e.target.value)}
              placeholder={intl.formatMessage({ id: 'fatherVerification.placeholder' })}
            />
            <Button css={alignLeft} unstyled href="/RecoverPasswordScreen" navigation>
              <Text
                tagName="span"
                preset="textXS"
                textColor={Colors.deepSeaBlue}
                txt="fatherVerification.forgot"
              />
            </Button>
          </InputWrapper>
          <Button txt="fatherVerification.button" isButton />
          <Text textColor="var(--red)">{props.error}</Text>
        </Body>
      </form>
    </TitledBox>
  );
}

ParentVerificationBox.propTypes = {
  onSend: PropTypes.func,
  title: PropTypes.string,
};

export default ParentVerificationBox;
