/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import StartupActions from 'academons-shared-library/src/Redux/StartupRedux';
import TransientDataActions, {putTransientData} from 'academons-shared-library/src/Redux/TransientDataRedux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Header from '../../components/Header';
import NotFoundPage from '../NotFoundPage/Loadable';
import RegistrationPage from '../RegistrationPage/Loadable';
import LoginPage from '../LoginPage/Loadable';
import ProfileSelectionPage from '../ProfileSelectionPage/Loadable';
import ParentVerificationPage from '../ParentVerificationPage/Loadable';
import RecoverPasswordPage from '../RecoverPasswordPage/Loadable';
import PlayerStatusPage from '../PlayerStatusPage/Loadable';
import HomePage from '../HomePage/Loadable';
import MissionsPage from '../MissionsPage/Loadable';
import ItineraryPage from '../ItineraryPage/Loadable';
import ChildProfilePage from '../ChildProfilePage/Loadable';
import GamePage from '../GamePage/Loadable';
import GameEndRegularPage from '../GameEndRegularPage/Loadable';
import GameEndMissionPage from '../GameEndMissionPage/Loadable';
import ParentProfilePage from '../ParentProfilePage/Loadable';
import StatisticsPage from '../StatisticsPage/Loadable';
import PrivacyPolicyPage from '../PrivacyPolicyPage/Loadable';
import TermsPage from '../TermsPage/Loadable';
import UpgradesPage from '../UpgradesPage/Loadable';
import DownloadAppPage from '../DownloadAppPage/Loadable';
import GameEndMasterBallPage from '../GameEndMasterBallPage/Loadable';
import TrophiesPage from '../TrophiesPage/Loadable';
import BuySubscriptionFinishedPage from '../BuySubscriptionFinishedPage/Loadable';
import BuySubscriptionFailedPage from '../BuySubscriptionFailedPage/Loadable';
import LeaguesPage from '../LeaguesPage/Loadable';
import FriendsManagerPage from '../FriendsManagerPage/Loadable';
import ChallengesPage from '../ChallengesPage/Loadable';
import ChallengeFriendPage from '../ChallengeFriendPage/Loadable';
import ChallengePresentationPage from '../ChallengePresentationPage/Loadable';
import ChallengeResultPage from '../ChallengeResultPage/Loadable';
import GameEndChallengePage from '../GameEndChallengePage/Loadable';
import AvatarConfiguratorPage from '../AvatarConfiguratorPage/Loadable';
import SubscriptionsPage from '../SubscriptionsPage/Loadable';
import ShopPage from '../ShopPage/Loadable';
import PrivateRoute from '../PrivateRoute';
import GlobalStyle from '../../global-styles';
import 'react-responsive-carousel/lib/styles/carousel.css';
import GameMenu from '../GameMenu';
import { webStartup } from './actions';
import UseSize from '../../components/Backgrounds/useSize';
import VacationPage from '../VacationPage/Loadable';
import useQuery from "../../hooks/useQuery";

const AppWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

// const key = 'app';

function App(props) {
  const query = useQuery();
  const promotion = query.get('code') ?? 'none';
  useEffect(() => {
    props.init();
    if(promotion !== 'none') {
      props.setPromotion(promotion);
    }
  }, []);
  return (
    <AppWrapper>
      <UseSize>
        <Helmet
          titleTemplate="Academons | Aplicación Educativa para Niños de Primaria"
          defaultTitle="Academons | Aplicación Educativa para Niños de Primaria"
        >
          <meta name="description" content="A React.js Boilerplate application" />
        </Helmet>
        <Header />
        <GameMenu />
        <Switch>
          <Redirect exact path="/" to={props.auth ? '/ProfileSelectionScreen' : '/LoginScreen'} />
          <Redirect from="/PlayerStatusPanel" to="/ProfileSelectionScreen" />
          <Route exact path="/vacaciones/:id" component={VacationPage} />
          <Route path="/vacaciones" component={VacationPage} />
          {/*<Route path="/BlackFriday/anual" component={BlackFridayPage} />*/}
          {/*<Route path="/BlackFriday/mensual" component={BlackFridayPageMonthly} />*/}
          {/*<Route path="/BlackFriday-US-en" component={BlackFridayPageUSEN} />*/}
          {/*<Route path="/BlackFriday-US-es" component={BlackFridayPageUSES} />*/}
          <Route path="/LoginScreen" component={LoginPage} />
          <Route path="/RegistrationScreen" component={RegistrationPage} />
          <Route path="/RegistrationOkScreen" component={UpgradesPage} />
          <Route path="/UpgradesScreen" component={UpgradesPage} />
          <Route path="/ParentVerificationScreen" component={ParentVerificationPage} />
          <Route path="/RecoverPasswordScreen" component={RecoverPasswordPage} />
          <Route path="/DownloadAppScreen" component={DownloadAppPage} />
          <PrivateRoute path="/StatisticsScreen" component={StatisticsPage} />
          <PrivateRoute path="/SubscriptionsScreen" component={SubscriptionsPage} />
          <PrivateRoute path="/GameHomeScreen" component={HomePage} />
          <PrivateRoute path="/ProfileSelectionScreen" component={ProfileSelectionPage} />
          <PrivateRoute path="/PlayerStatusScreen" component={PlayerStatusPage} />
          <PrivateRoute path="/MissionsScreen" component={MissionsPage} />
          <PrivateRoute path="/ItineraryScreen" component={ItineraryPage} />
          <PrivateRoute path="/ChildProfilePage" component={ChildProfilePage} />
          <PrivateRoute path="/GameScreen" component={GamePage} />
          <PrivateRoute path="/GameEndRegularScreen" component={GameEndRegularPage} />
          <PrivateRoute path="/GameEndMissionScreen" component={GameEndMissionPage} />
          <PrivateRoute path="/GameEndMasterBallScreen" component={GameEndMasterBallPage} />
          <PrivateRoute path="/ChildProfileScreen" component={ChildProfilePage} />
          <PrivateRoute path="/ParentProfileScreen" component={ParentProfilePage} />
          <PrivateRoute path="/TrophiesScreen" component={TrophiesPage} />
          <PrivateRoute
            path="/BuySubscriptionFinishedScreen"
            component={BuySubscriptionFinishedPage}
          />
          <PrivateRoute path="/BuySubscriptionFailedScreen" component={BuySubscriptionFailedPage} />
          <PrivateRoute path="/LeaguesScreen" component={LeaguesPage} />
          <PrivateRoute path="/FriendsManagerScreen" component={FriendsManagerPage} />
          <PrivateRoute path="/ChallengesScreen" component={ChallengesPage} />
          <PrivateRoute path="/ChallengeFriendScreen" component={ChallengeFriendPage} />
          <PrivateRoute path="/ChallengePresentationScreen" component={ChallengePresentationPage} />
          <PrivateRoute path="/ChallengeResultScreen" component={ChallengeResultPage} />
          <PrivateRoute path="/GameEndChallengeScreen" component={GameEndChallengePage} />
          <PrivateRoute path="/AvatarConfiguratorScreen" component={AvatarConfiguratorPage} />
          <PrivateRoute path="/ShopScreen" component={ShopPage} />
          <Route path="/PrivacyPolicyScreen" component={PrivacyPolicyPage} />
          <Route path="/TermsScreen" component={TermsPage} />
          <Route path="" component={NotFoundPage} />
        </Switch>
        <GlobalStyle />
      </UseSize>
    </AppWrapper>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    init() {
      dispatch(StartupActions.startup(webStartup()));
    },
    setPromotion(promotion) {
      dispatch(TransientDataActions.putTransientData('promotion', promotion));
    }
  };
}
const mapStateToProps = state => ({
  error: state.auth.error,
  loading: state.auth.fetching,
  auth: state.auth.accessToken,
  player: state.game.player,
});
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(App);
