/* eslint-disable prettier/prettier */
import standardApi from "./standardApi";


const create = () => {
	const api = standardApi();

	const getList = (asignaturaid) =>
		api.get(`/tienda/listado/${asignaturaid}`)

	const getInfoNeuros = (asignaturaid) =>
		api.get(`/tienda/info-neuros/${asignaturaid}`)

	const buyObject = (objId, asignaturaid) => {
		return api.post(`/tienda/comprar-objeto/${objId}/asignatura/${asignaturaid}`)
	}

	const buyObjects = (objId, asignaturaid) => {
		return api.post(`/tienda/comprar-objetos/asignatura/${asignaturaid}`, objId)
	}

	const useObject = (objId, asignaturaid) => {
		return api.post(`/tienda/usar-objeto/${objId}/asignatura/${asignaturaid}`)
	}

	const useObjects = (objId, asignaturaid) => {
		return api.post(`/tienda/usar-objetos/asignatura/${asignaturaid}`, objId)
	}

	const delObject = (objId, asignaturaid) => {
		return api.post(`/tienda/quitar-objeto/${objId}/asignatura/${asignaturaid}`)
	}

	const delObjects = (objId, asignaturaid) => {
		return api.post(`/tienda/quitar-objetos/asignatura/${asignaturaid}`, objId)
	}

	return {
		apisauce: api,
		getList,
		getInfoNeuros,
		buyObject,
		buyObjects,
		useObject,
		useObjects,
		delObject,
		delObjects,
	}

}

export default {
	create
}
