import standardApi from "./standardApi";


const create = () => {
	const api = standardApi();

	const list = () =>
		api.get('/juegos/listado')

	const getGlobalRanking = (asignaturaid) =>
		api.get(`/usuario/alumno/ranking-curso-asignatura/${asignaturaid}`)

	const getFriendsRanking = (asignaturaid) =>
		api.get(`/usuario/alumno/ranking-amigos-asignatura/${asignaturaid}`)
	const getTrophies = () =>
		api.get(`/usuario/alumno/trofeos`)
	const markTrophyAsSeen = (id) =>
		api.post(`/usuario/alumno/trofeo-notificado/${id}`)
	return {
		apisauce: api,
		list,
		getGlobalRanking,
		getFriendsRanking,
		getTrophies,
		markTrophyAsSeen,
	}
}

export default {
	create
}
