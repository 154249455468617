/* eslint-disable */
import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	loginTeacherRequest: ['email', 'password','skipRedirect'],
	loginRequest: ['login', 'password','skipRedirect','postAction','network','token', 'tokenType', 'accessToken'],
	loginSuccess: ['payload'],
	loginTeacherSuccess: ['payload'],
	loginFailure: ['errorNo'],
	logout: null,
	resetLoadingState: null,
	requestPasswordCodeRequest: ['email'],
	requestPasswordCodeSuccess: ['payload'],
	requestPasswordCodeFailure: null,
	changePassword: ['password'],
	changePasswordRequest: ['code', 'password'],
	changePasswordSuccess: ['payload'],
	changePasswordFailure: null,
	deleteUser: ['id']
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetching: null,
	error: false,
	recoverError: false,
	login: "",
	password: "",
	accessToken: "",
	isFather: null,
	isTeacher: null,
	isStudent: null,
	isSubscriptor: false,
	recoverCodeSent: false,
	errorNo: null
})

/* ------------- Selectors ------------- */

export const AuthSelectors = {
	getData: state => state.data
}

/* ------------- Reducers ------------- */

// request the data from an api
export const loginRequest = (state, {login, password, network, token, tokenType, accessToken}) =>
	state.merge({fetching: true, login, error: false, password, network, googleToken: token, msTokenType: tokenType, msAccessToken: accessToken, errorNo: null})

// request the data from an api for teacher
export const loginTeacherRequest = (state, {email, password}) =>
	state.merge({fetching: true, email, error: false, password})

// successful api lookup
export const loginSuccess = (state, action) => {
	const {payload} = action
	return state.merge({
		fetching: false,
		accessToken: payload.token,
		isFather: payload.soypadre,
		isTeacher: payload.soyprofe,
		isStudent: !payload.soypadre && !payload.soyprofe
	})
}

// successful api lookup for teacher
export const loginTeacherSuccess = (state, action) => {
	const {payload} = action
	return state.merge({
		fetching: false,
		accessToken: payload.token,
		isTeacher: true
	})
}

export const loginFailure = (state, action) => {
	const {errorNo} = action
	return state.merge({fetching: false, error: true, login: "", password: "", accessToken: "", errorNo})
}

export const requestPasswordCodeRequest = (state, {email}) =>
	state.merge({fetching: true,recoverCodeSent:false, recoverError:false})

export const requestPasswordCodeSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetching: false, recoverError: false,recoverCodeSent:true})
}

export const requestPasswordCodeFailure = state =>
	state.merge({fetching: false, recoverError: true})

export const changePassword = (state, {password}) =>
	state.merge({password})

export const changePasswordRequest = (state, {password}) =>
	state.merge({fetching: true,password})

export const changePasswordSuccess = (state, action) => {
	const {payload} = action
	return state.merge({fetching: false, changedPassword:true})
}

export const changePasswordFailure = state =>
	state.merge({fetching: false, changePasswordError: true})

export const logout = state => INITIAL_STATE
export const resetLoadingState = state => state.merge({fetching: false, recoverCodeSent:false , error:false})

export const deleteUser = (state, action) => state.merge({fetching: true})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.LOGIN_REQUEST]: loginRequest,
	[Types.LOGIN_SUCCESS]: loginSuccess,
	[Types.LOGIN_FAILURE]: loginFailure,
	[Types.REQUEST_PASSWORD_CODE_REQUEST]: requestPasswordCodeRequest,
	[Types.REQUEST_PASSWORD_CODE_SUCCESS]: requestPasswordCodeSuccess,
	[Types.REQUEST_PASSWORD_CODE_FAILURE]: requestPasswordCodeFailure,
	[Types.CHANGE_PASSWORD]: changePassword,
	[Types.CHANGE_PASSWORD_REQUEST]: changePasswordRequest,
	[Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
	[Types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,
	[Types.LOGOUT]: logout,
	[Types.RESET_LOADING_STATE]: resetLoadingState,
	[Types.LOGIN_TEACHER_REQUEST]: loginTeacherRequest,
	[Types.LOGIN_TEACHER_SUCCESS]: loginTeacherSuccess,
	[Types.DELETE_USER]: deleteUser,
})
