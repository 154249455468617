import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	getPokesRequest: ['categoryId'],
	getPokesSuccess: ['payload'],
	getPokesFailure: null,
	getAllPokesRequest: [],
	getAllPokesSuccess: ['payload'],
	getAllPokesFailure: null,
	sendPokeRequest: ['categoryId','toId','pokeCategory'],
	sendPokeSuccess: ['payload'],
	sendPokeFailure: null,
	clearPokesRequest: ['categoryId','pokeCategory'],
	clearPokesSuccess: ['payload'],
	clearPokesFailure: null,
})

export const PokeCategories = {
	LEVEL_UP: "SUBE_NIVEL",
	OPEN_CATEGORY: "ABRE_ASIGNATURA",
	PLAY_CHALLENGES: "JUEGA_RETOS"
}

export const PokesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: null,
  payload: null,
  error: null
})

/* ------------- Reducers ------------- */


export const getPokesRequest = (state) =>
	state.merge({ fetching: true })
export const getPokesSuccess = (state, action) => {
	const { payload } = action
	return state.merge({ fetching: false, error: null, list:payload.categoryList,all:payload.all })
}
export const getPokesFailure = state =>
	state.merge({ fetching: false, error: true })

export const getAllPokesRequest = (state) =>
	state.merge({ fetching: true })
export const getAllPokesSuccess = (state, action) => {
	const { payload } = action
	return state.merge({ fetching: false, error: null, all:payload })
}
export const getAllPokesFailure = state =>
	state.merge({ fetching: false, error: true })

export const sendPokeRequest = (state, { data }) =>
	state.merge({ fetching: true })
export const sendPokeSuccess = (state, action) => {
	const { payload } = action
	return state.merge({ fetching: false, error: null, result:payload })
}
export const sendPokeFailure = state =>
	state.merge({ fetching: false, error: true })

export const clearPokesRequest = (state, { data }) =>
	state.merge({ fetching: true })
export const clearPokesSuccess = (state, action) => {
	return state.merge({ fetching: false, error: null })
}
export const clearPokesFailure = state =>
	state.merge({ fetching: false, error: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.SEND_POKE_REQUEST]: sendPokeRequest,
	[Types.SEND_POKE_SUCCESS]: sendPokeSuccess,
	[Types.SEND_POKE_FAILURE]: sendPokeFailure,
	[Types.GET_POKES_REQUEST]: getPokesRequest,
	[Types.GET_POKES_SUCCESS]: getPokesSuccess,
	[Types.GET_POKES_FAILURE]: getPokesFailure,
	[Types.GET_ALL_POKES_REQUEST]: getAllPokesRequest,
	[Types.GET_ALL_POKES_SUCCESS]: getAllPokesSuccess,
	[Types.GET_ALL_POKES_FAILURE]: getAllPokesFailure,
	[Types.CLEAR_POKES_REQUEST]: clearPokesRequest,
	[Types.CLEAR_POKES_SUCCESS]: clearPokesSuccess,
	[Types.CLEAR_POKES_FAILURE]: clearPokesFailure,
})
