import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedNumber, useIntl } from 'react-intl';

import neuro from './images/neuro.png';
import Img from '../../components/Img';
import Text from '../../components/Text';
import Colors from '../../utils/Colors';
import { formatNumber } from '@formatjs/intl';

const Container = styled.div`
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 47px;
  padding: 7px;
  color: ${props => props.color};
  border: solid 1px var(--very-light-pink);

  > p {
    color: ${props => props.color};
  }
`;

const TextContainer = styled(Text)`
  display: flex;
  align-items: center;
  margin: 0 14px;
  font-family: var(--title-fonts);
  line-height: 1;
  font-size: 14px;
  img {
    vertical-align: middle;
    margin-right: 7px;
  }
`;
const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: var(--very-light-pink);
`;
function Score(props) {
  const { level, points, neuros, color } = props;
  const intl = useIntl();
  const translate = (term, d) => intl.formatMessage({ id: term }, d);
  const line1 = translate('gameMenu.level', { level });
  const line2 = translate('gameMenu.points', { points: intl.formatNumber(points) });
  return (
    <Container color={color}>
      <TextContainer>{line1}</TextContainer>
      <Line />
      <TextContainer>{line2}</TextContainer>
      <Line />
      <TextContainer>
        <Img src={neuro} alt="" />
        {intl.formatNumber(neuros)}
      </TextContainer>
    </Container>
  );
}
Score.propTypes = {
  color: PropTypes.string,
  level: PropTypes.number,
  points: PropTypes.number,
  neuros: PropTypes.number,
};

export default Score;
