import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import Img from './Img';
import Logo from './logo-academons-blanco.png';
import LogoXXS from './mascota_ACADEMONS.png';
import Button from '../Button';

const Container = styled.header`
  padding: 16px 30px;
  height: var(--header-height);
  background-color: var(--teal-blue);
  z-index: 20;
  position: fixed;
  width: 100vw;

  @media only screen and (max-width: 1009px) {
    display: flex;
    justify-content: center;
    align-items: center;

    > a {
      height: fit-content;
      padding: 0;
    }
  }

  ${media.lessThan('medium')`
    padding: 10px;
    height: 50px;
  `}
`;

function Header() {
  return (
    <Container className="main-header">
      <Button unstyled href="/" navigation>
        <picture>
          <source media="(min-width:465px)" srcSet={Logo} />
          <Img src={LogoXXS} alt="Academons Web" style={{ maxHeight: 34 }} />
        </picture>
      </Button>
    </Container>
  );
}

export default Header;
