/* eslint-disable prettier/prettier */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, putResolve, race, select, take, takeLatest } from 'redux-saga/effects';
import UserActions, {UserTypes} from '../Redux/UserRedux'
import GameActions from '../Redux/GameRedux'
import AppConfig from '../Config/AppConfig'
import AuthActions, {AuthTypes} from '../Redux/AuthRedux'
import Strings from '../Config/Strings'
import Environment from '../Config/Environment';

export function * getUser (api, action) {
	const {isFather,isTeacher,isStudent,accessToken} = yield select(state => state.auth);
	const fatherToken = yield select(state => state.auth.accessToken);

	const meth = isFather ? api.getFather : isStudent ? api.getChild : api.getFather;
	const response = yield call(meth,fatherToken);

	if(!response.ok || response.data.errno != 0){
		yield put(UserActions.profileFailure(Strings.GENERIC_ERROR))
	}
	else {
		const {player} = yield select(state => state.game);
		if(player){
			yield putResolve(GameActions.selectPlayer(response.data.hijos.filter(x => x.id == player.id)[0]))
		}
		yield put(UserActions.profileSuccess(response.data))
	}
}
export function * registerFather (api, action) {
	let {name, email, password, childid, country} = action;
	let {username, course, categories} = action;
	const response = yield call(api.createFather, name, "", email, password, undefined, country);
	// success?
	if (response.ok) {

		if(response.data.errno == 6){
			yield put(UserActions.fatherRegistrationFailure("El email ya está siendo usado por otro usuario"))
		}
		else if(response.data.errno == 8){
			yield put(UserActions.fatherRegistrationFailure("Debes rellenar todos los campos"))
		}
		else if(response.data.errno != 0){
			yield put(UserActions.fatherRegistrationFailure(Strings.GENERIC_ERROR))
		}
		else{
			yield put(AuthActions.loginRequest(email,password,true))
			let {loginSuccess} = yield race({
				loginSuccess: take(AuthTypes.LOGIN_SUCCESS),
				loginError: take(AuthTypes.LOGIN_FAILURE),
			})
			if (loginSuccess) {
				if(childid){
					// Child already exists, only linking needed
					let linkResponse = yield call(api.linkChild, response.data.newuserid, childid);

					if(linkResponse.data.errno == 8){
						yield put(UserActions.fatherRegistrationFailure("Debes rellenar todos los campos"))
					}
					else if(linkResponse.data.errno != 0){
						yield put(UserActions.fatherRegistrationFailure(Strings.GENERIC_ERROR))
					}
					else{
						let updateResponse = yield call(api.updateChild, childid, username, categories);
						if(updateResponse.data.errno != 0){
							yield put(UserActions.fatherRegistrationFailure(Strings.GENERIC_ERROR))
						}
						else{
							yield put(UserActions.fatherRegistrationSuccess(response.data))
							yield put(Environment.createNavigationAction('RegistrationOkScreen'));
						}
					}
				}
				else{
					// Father is registering a new child
					yield put(UserActions.childRegistrationRequest(username,course,categories))
					let {registerChildSuccess} = yield race({
						registerChildSuccess: take(UserTypes.CHILD_REGISTRATION_SUCCESS),
						registerChildError: take(UserTypes.CHILD_REGISTRATION_FAILURE),
					})
					if(registerChildSuccess){
						yield putResolve(UserActions.profileRequest());
						yield put(UserActions.fatherRegistrationSuccess(response.data))
						yield put(Environment.createNavigationAction('RegistrationOkScreen'));
					}
					else{
						yield put(UserActions.fatherRegistrationFailure(Strings.GENERIC_ERROR))
					}
				}
			}
			else{
				yield put(UserActions.fatherRegistrationFailure(Strings.GENERIC_ERROR))
			}
		}
	} else {
		yield put(UserActions.fatherRegistrationFailure(Strings.GENERIC_ERROR))
	}
	//const response2 = yield call(api.createChild, username,67, null)
}
export function * registerChild (api, action) {
	let {username,paisid = 67,course,redirect} = action;
	const response = yield call(api.createChild, username,paisid,null,course,null,0,0,0,0,0)
	// success?
	console.log(action)
	if (response.ok) {
		if(response.data.errno == 8){
			//yield put(MessagesActions.messageShow("Debes rellenar todos los campos","Error"));
			yield put(UserActions.childRegistrationFailure("Debes rellenar todos los campos"))
		}
		else if(response.data.errno != 0){
			yield put(UserActions.childRegistrationFailure("Ocurrión un error y no se pudo completar el proceso"))
		}
		else{
			yield putResolve(UserActions.profileRequest());
			yield put(UserActions.childRegistrationSuccess(response.data))
			if(redirect){
				if(redirect === "back"){
					yield put(Environment.createBackNavigationAction());
				}
				else{
					yield put(Environment.createNavigationAction(redirect));
				}
			}
		}
	} else {
		yield put(UserActions.childRegistrationFailure())
	}
}
export function * deleteFather (api, action) {
	let {id} = action;
	const response = yield call(api.deleteFather, id, true)
	// success?
	if (response.ok) {
		yield put(UserActions.fatherDeleteSuccess(response.data))
	} else {
		yield put(UserActions.fatherDeleteFailure())
	}
}
export function * deleteChild (api, action) {
	let {idusuario} = action;
	const response = yield call(api.deleteChild, idusuario)
	// success?
	if (response.ok) {
		if(response.data.errno == 8){
			//yield put(MessagesActions.messageShow("Debes rellenar todos los campos","Error"));
			yield put(UserActions.childDeleteFailure("Debes rellenar todos los campos"))
		}
		else if(response.data.errno != 0){
			yield put(UserActions.childDeleteFailure("Ocurrión un error y no se pudo completar el proceso"))
		}
		else{
			console.log("deleted")
			yield putResolve(UserActions.profileRequest())
			yield put(UserActions.childDeleteSuccess(response.data))
		}
	} else {
		yield put(UserActions.childDeleteFailure())
	}
}
export function * updateAvatar (api, action) {
	let {idusuario,body,color,eyes,mouth,legs} = action;
	const response = yield call(api.updateChildAvatar, idusuario,body,color,eyes,mouth,legs)
	// success?
	if (response.ok) {
		if(response.data.errno == 8){
			//yield put(MessagesActions.messageShow("Debes rellenar todos los campos","Error"));
			yield put(UserActions.updateAvatarFailure("Debes rellenar todos los campos"))
		}
		else if(response.data.errno != 0){
			yield put(UserActions.updateAvatarFailure("Ocurrión un error y no se pudo completar el proceso"))
		}
		else{
			const response = yield call(api.getFather);
			if(!response.ok || response.data.errno != 0){
				yield put(UserActions.updateAvatarFailure(Strings.GENERIC_ERROR))
			}
			else {
				yield putResolve(UserActions.profileSuccess(response.data))
				const player = yield select(state => state.user.profile.hijos.filter(x => x.token == AppConfig.playerToken)[0]);
				yield putResolve(GameActions.selectPlayer(player));
				yield put(UserActions.updateAvatarSuccess(response.data))
			}
		}
	} else {
		yield put(UserActions.updateAvatarFailure())
	}
}
export function * updateFather (api, action) {
	let {name,email,frecuencia,password} = action;
	let idusuario = yield select(state => state.user.profile.loggeduserid);
	const response = yield call(api.updateFather,idusuario, name, email,frecuencia,password)
	// success?
	if (response.ok) {
		if(response.data.errno == 6){
			yield put(UserActions.fatherUpdateFailure("El email ya está siendo usado por otro usuario"))
		}
		else if(response.data.errno == 8){
			yield put(UserActions.fatherUpdateFailure("Debes rellenar todos los campos"))
		}
		else if(response.data.errno != 0){
			yield put(UserActions.fatherUpdateFailure(Strings.GENERIC_ERROR))
		}
		else{
			yield putResolve(UserActions.profileRequest())
			yield put(AuthActions.loginRequest(email, password, true))
			// if(password){
			// 	yield  put(AuthActions.changePassword(password));
			// }
			yield put(UserActions.fatherUpdateSuccess());
			//yield put(NavigationActions.back());
		}
	} else {
		yield put(UserActions.fatherUpdateFailure(Strings.GENERIC_ERROR))
	}
	//const response2 = yield call(api.createChild, username,67, null)
}
export function * updateChild (api, action) {
	let {id,name,categories,skipRedirect} = action;
	const response = yield call(api.updateChild,id, name, categories)
	// success?
	if (response.ok) {
		if(response.data.errno != 0){
			yield put(UserActions.childUpdateFailure(Strings.GENERIC_ERROR))
		}
		else{
			yield putResolve(UserActions.profileRequest())
			yield putResolve(UserActions.childUpdateSuccess());
			if (!skipRedirect) {
				yield putResolve(Environment.createBackNavigationAction());
			}
		}
	} else {
		yield put(UserActions.childUpdateFailure(Strings.GENERIC_ERROR))
	}
	//const response2 = yield call(api.createChild, username,67, null)
}
export function * updateCourse (api, action) {
	let {childId,courseId,skipRedirect} = action;
	const response = yield call(api.changeCourse, childId, courseId);
	// success?
	if (response.ok) {
		if(response.data.errno != 0){
			yield put(UserActions.updateCourseFailure(Strings.GENERIC_ERROR))
		}
		else{
			yield putResolve(UserActions.profileRequest())
			yield putResolve(UserActions.updateCourseSuccess());
			!skipRedirect && (yield putResolve(Environment.createBackNavigationAction()));
		}
	} else {
		yield put(UserActions.updateCourseFailure(Strings.GENERIC_ERROR))
	}
}
export function * friendshipRequest (api, action) {
	let { nick } = action;
	console.log("SAGA",action)
	const response = yield call(api.friendshipRequest,nick);
	if (response.ok) {
		if (response.data.errno != 0) {
			yield put(UserActions.friendshipRequestFailure("Ocurrió un error"))
		}
		else{
			console.log("entro")
			yield put(GameActions.getFriendsRequest())
			yield put(UserActions.friendshipRequestSuccess())
		}
	}
}
export function * friendshipAccept (api, action) {
	let { petitionid } = action;
	const response = yield call(api.friendshipConfirm,petitionid);
	if (response.ok) {
		if (response.data.errno != 0) {
			yield put(UserActions.friendshipAcceptFailure("Ocurrió un error"))
		}
		else{
			yield put(GameActions.getFriendsRequest())
			yield put(UserActions.friendshipAcceptSuccess())
		}
	}
}
export function * friendshipReject (api, action) {
	let { petitionid } = action;
	const response = yield call(api.friendshipReject,petitionid);
	if (response.ok) {
		if (response.data.errno != 0) {
			yield put(UserActions.friendshipRejectFailure("Ocurrió un error"))
		}
		else{
			yield put(GameActions.getFriendsRequest())
			yield put(UserActions.friendshipRejectSuccess());
		}
	}
}
export function * friendshipDelete (api, action) {
	let { friendid } = action;
	const response = yield call(api.friendshipDelete,friendid);
	if (response.ok) {
		if (response.data.errno != 0) {
			yield put(UserActions.friendshipDeleteFailure("Ocurrió un error"))
		}
		else{
			yield put(GameActions.getFriendsRequest())
			yield put(UserActions.friendshipDeleteSuccess());
		}
	}
}
export function * markMessageAsRead (api, action) {
	let { categoryId } = action;
	const response = yield call(api.markMessageAsRead,categoryId);
	if (response.ok) {
		if (response.data.errno != 0) {
			yield put(UserActions.markMessageAsReadFailure("Ocurrió un error"))
		}
		else{
			yield putResolve(UserActions.profileRequest())
			yield put(UserActions.markMessageAsReadSuccess())
		}
	}
}
export function * markNotificationAsRead (api, action) {
	let { notificationId } = action;
	const response = yield call(api.markNotificationAsRead, notificationId);
	if (response.ok) {
		if (response.data.errno != 0) {
			yield put(UserActions.markNotificationAsReadFailure("Ocurrió un error"))
		}
		else{
			yield putResolve(UserActions.profileRequest())
			yield put(UserActions.markNotificationAsReadSuccess())
		}
	}
}
