/* eslint-disable prettier/prettier */
import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'
import AppConfig from '../Config/AppConfig'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	selectPlayer: ['player'],
	exitPlayer: [],
	selectCategory: ['category'],
	unselectCategory: [],
	selectStage: ['mision', 'stage', 'missionType'],
	selectItinerary: ['itinerary'],
	setLastScore: ['lastScore'],
	updateAfterGameRequest: [],
	updateAfterGameSuccess: ['payload'],
	updateAfterGameFailure: ['errorMessage'],
	getTrophiesRequest: [],
	getTrophiesSuccess: ['payload'],
	getTrophiesFailure: ['errorMessage'],
	clearCurrentTrophy: [],
	getFriendsRequest: [],
	getFriendsRequestSendRequest:[],
	getFriendsRequestSendSuccess:['payload'],
	getFriendsRequestSendFailure:['errorMessage'],
	getFriendsSuccess: ['payload'],
	getFriendsFailure: ['errorMessage'],
	searchFriendsRequest: ['term'],
	searchFriendsSuccess: ['payload'],
	searchFriendsFailure: ['errorMessage'],
	clearGame: [],
	clearSearch: [],
	setMasterball: ['val'],
	clearPlayerInAppNotification: [],
	clearCategoryInAppNotification: ['index']
})

export const GameTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetching: null,
	category: 0,
	error: null,
	searchTerm: "",
	currentTrophy: null,
	masterball: false,
	inAppCategoryShow: true
})

/* ------------- Reducers ------------- */

export const selectPlayer = (state, {player}) => {
	if(player.token){
		AppConfig.playerToken = player.token;
	}
	else{
		console.warn("El jugador no tenía token, usando el del usuario");
	}
	return state.merge({player})
}
export const exitPlayer = (state) => {
	AppConfig.playerToken = null;
	return state.merge({player:null})
}

export const selectCategory = (state, {category}) =>
	state.merge({category})
export const unselectCategory = (state) =>
	state.merge({category:null})
export const selectStage = (state, {mision, stage, missionType}) =>
	state.merge({stage,mision, missionType})
export const selectItinerary = (state, {itinerary}) =>
	state.merge({itinerary})
export const setLastScore = (state, {lastScore}) =>
	state.merge({lastScore})
export const setMasterball = (state, {val} ) => {
	return state.merge({masterball: val})
}
export const clearGame = (state) => INITIAL_STATE


export const updateAfterGameRequest = (state) =>
	state.merge({fetching: true})

export const updateAfterGameSuccess = (state, action) => {
		if(state.mision){
			let as = action.payload["asignaturas_visibles"];
			let category = as.filter(x => x.id == state.category)[0];
			let mision = category.misiones.filter(x => x.mision.id == state.mision.mision.id)[0];
			let stage = mision.bibliotecas.filter(x => x.info.id == state.stage.info.id)[0];
			if(!stage) {
				// Puede que sea una de refuerzo
				if(mision.refuerzo.basico.info.id === state.stage.info.id) {
					stage = mision.refuerzo.basico;
				}
				else if(mision.refuerzo.intermedio.info.id === state.stage.info.id) {
					stage = mision.refuerzo.intermedio;
				}
				else if(mision.refuerzo.avanzado.info.id === state.stage.info.id) {
					stage = mision.refuerzo.avanzado;
				}
			}
			if(!stage) {
				stage = mision.refuerzo.intermedio.filter(x => x.info.id == state.stage.info.id)[0];
			}
			if(!stage) {
				stage = mision.refuerzo.avanzado.filter(x => x.info.id == state.stage.info.id)[0];
			}
			let itinerary = stage.itinerario.filter(x => x.id == state.itinerary.id && x.pasoitinerario == state.itinerary.pasoitinerario)[0];
			return state.merge({fetching: false, error: null,player:{asignaturas_visibles:as},stage,mision,itinerary},{deep: true})
		}
		else{
			return state;
		}

}

export const updateAfterGameFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const getTrophiesRequest = (state) =>
	state.merge({fetching: true})

export const getTrophiesSuccess = (state, {payload}) => {
	return state.merge({fetching: false, error: null, trophies: payload.list,currentTrophy:payload.current})
}
export const clearCurrentTrophy = (state) => {
	return state.merge({currentTrophy:null})
}
export const getTrophiesFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const getFriendsRequestSendRequest = (state) =>
	state.merge({fetching: true})

export const getFriendsRequestSendFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const getFriendsRequestSendSuccess = (state, {payload}) => {
		return state.merge({fetching: false, error: null, sends:payload.enviadas})
	}

export const getFriendsRequest = (state) =>
	state.merge({fetching: true})

export const getFriendsSuccess = (state, {payload}) => {
	return state.merge({fetching: false, error: null, friends: payload.amigos, requests: payload.pendientes})
}

export const getFriendsFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const searchFriendsRequest = (state, {term}) =>
	state.merge({fetching: true,searchTerm:term})

export const searchFriendsSuccess = (state, {payload}) => {
	return state.merge({fetching: false, error: null, searchResult: payload})
}

export const searchFriendsFailure = (state, action) =>
	state.merge({fetching: false, error: true, errorMessage:action.errorMessage})

export const clearSearch = (state) => {
	return state.merge({fetching: false, error: null, searchResult: null, searchTerm: ""})
}

export const clearPlayerInAppNotification = (state, action) =>
	state.merge({fetching: false, error: null, player: { notificacion: {pendiente:false}}},{deep: true})

export const clearCategoryInAppNotification = (state, action) => {
	const { index } = action
	return state.merge({fetching: false, error: null, inAppCategoryShow: false})
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.SELECT_PLAYER]: selectPlayer,
	[Types.EXIT_PLAYER]: exitPlayer,
	[Types.SELECT_CATEGORY]: selectCategory,
	[Types.UNSELECT_CATEGORY]: unselectCategory,
	[Types.SELECT_STAGE]: selectStage,
	[Types.SELECT_ITINERARY]: selectItinerary,
	[Types.UPDATE_AFTER_GAME_REQUEST]: updateAfterGameRequest,
	[Types.UPDATE_AFTER_GAME_SUCCESS]: updateAfterGameSuccess,
	[Types.UPDATE_AFTER_GAME_FAILURE]: updateAfterGameFailure,
	[Types.GET_TROPHIES_REQUEST]: getTrophiesRequest,
	[Types.GET_TROPHIES_SUCCESS]: getTrophiesSuccess,
	[Types.GET_TROPHIES_FAILURE]: getTrophiesFailure,
	[Types.CLEAR_CURRENT_TROPHY]: clearCurrentTrophy,
	[Types.GET_FRIENDS_REQUEST_SEND_REQUEST]: getFriendsRequestSendRequest,
	[Types.GET_FRIENDS_REQUEST_SEND_SUCCESS]: getFriendsRequestSendSuccess,
	[Types.GET_FRIENDS_REQUEST_SEND_FAILURE]: getFriendsRequestSendFailure,
	[Types.GET_FRIENDS_REQUEST]: getFriendsRequest,
	[Types.GET_FRIENDS_SUCCESS]: getFriendsSuccess,
	[Types.GET_FRIENDS_FAILURE]: getFriendsFailure,
	[Types.SEARCH_FRIENDS_REQUEST]: searchFriendsRequest,
	[Types.SEARCH_FRIENDS_SUCCESS]: searchFriendsSuccess,
	[Types.SEARCH_FRIENDS_FAILURE]: searchFriendsFailure,
	[Types.SET_LAST_SCORE]: setLastScore,
	[Types.CLEAR_GAME]: clearGame,
	[Types.CLEAR_SEARCH]: clearSearch,
	[Types.SET_MASTERBALL]: setMasterball,
	[Types.CLEAR_PLAYER_IN_APP_NOTIFICATION]: clearPlayerInAppNotification,
	[Types.CLEAR_CATEGORY_IN_APP_NOTIFICATION]: clearCategoryInAppNotification
})
