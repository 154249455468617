/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  PRODUCTS_FAILURE,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  SUBSCRIBE_FAILURE, SUBSCRIBE_REQUEST, SUBSCRIBE_SUCCESS,
} from './constants';

export function productsRequest() {
  return {
    type: PRODUCTS_REQUEST,
  };
}
export function productsSuccess(result) {
  return {
    type: PRODUCTS_SUCCESS,
    ...result,
  };
}
export function productsFailure(error) {
  return {
    type: PRODUCTS_FAILURE,
    error,
  };
}
export function subscribeRequest(productId) {
  return {
    type: SUBSCRIBE_REQUEST,
    productId,
  };
}
export function subscribeSuccess(result) {
  return {
    type: SUBSCRIBE_SUCCESS,
    ...result,
  };
}
export function subscribeFailure(error) {
  return {
    type: SUBSCRIBE_FAILURE,
    error,
  };
}
