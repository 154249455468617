/* eslint-disable */
import { takeLatest, all } from 'redux-saga/effects'
import AuthAPI from '../Services/AuthApi'
import UserAPI from '../Services/UserApi'
import GameAPI from '../Services/GamesApi'
import ShopAPI from '../Services/ShopApi'
import PlatformApi from '../Services/PlatformApi'
import ChallengesAPI from '../Services/ChallengesApi'
import PokeAPI from '../Services/PokeApi'
import SubjectApi from '../Services/SubjectApi';
import TopicApi from '../Services/TopicApi';
import StatisticApi from '../Services/StatisticApi';
import StudentApi from "../Services/StudentApi";

/* ------------- Types ------------- */

//import { StartupTypes } from '../Redux/StartupRedux'
import { AuthTypes } from '../Redux/AuthRedux'
import { UserTypes } from '../Redux/UserRedux'
import { GameTypes } from '../Redux/GameRedux'
import { MasterTablesTypes } from '../Redux/MasterTablesRedux'
import { SubjectTypes } from '../Redux/SubjectRedux';
import { TopicTypes } from '../Redux/TopicRedux';
import { StatisticTypes } from '../Redux/StatisticRedux';
import {StudentTypes} from "../Redux/StudentRedux";
import {StartupTypes} from "../Redux/StartupRedux";
import Environment from '../Config/Environment';
import {ShopTypes} from "../Redux/ShopRedux";
import {RankingTypes} from "../Redux/RankingRedux";
import {ChallengesTypes} from "../Redux/ChallengesRedux";
import {PokesTypes} from "../Redux/PokesRedux";

/* ------------- Sagas ------------- */

//import { startup } from './StartupSagas'
import {login, requestPasswordCode, logout, changePassword, loginTeacher, deleteUserAccount} from '../Sagas/AuthSagas';
import {
	registerChild,
	registerFather,
	getUser,
	updateFather,
	updateChild,
	deleteChild,
	updateAvatar,
	friendshipRequest,
	friendshipAccept,
	friendshipReject,
	friendshipDelete,
	markMessageAsRead,
	markNotificationAsRead,
	updateCourse, deleteFather,
} from "../Sagas/UserSagas"
import {updateAfterGame, getTrophies, getFriends, searchFriends, getFriendsRequestSend} from '../Sagas/GameSagas'
import {getCountries, getMasterTables} from '../Sagas/MasterTablesSagas'
import {buyObject, delObject, getInfoNeuros, getList, useObject} from "./ShopSagas";
import {getFriendsRanking, getGlobalRanking} from "./RankingSagas";
import {
	challengeFriend,
	challengeRandom,
	getChallengeResult,
	getPendingChallenges,
	rejectChallenge
} from "./ChallengesSagas";
import {clearPokes, getAllPokes, getPokes, sendPoke} from "./PokeSagas";
import {startup, startupTeacher} from "./StartupSagas";
import { getSubject, updateTopicStatus } from './SubjectSagas';
import { getTopic, updateMissionStatus } from './TopicSagas';
import {getStatistics} from './StatisticSagas';
import {deleteStudent} from './StudentSagas'

/* ------------- Connect Types To Sagas ------------- */

let authApi;
let userApi;
let gameApi;
let shopApi;
let platformApi;
let challengesApi;
let pokeApi;
let subjectApi;
let topicApi;
let statisticApi;
let studentApi;

export function changeBaseUrl(baseUrl) {
	authApi.apisauce.setBaseURL(baseUrl);
	userApi.apisauce.setBaseURL(baseUrl);
	gameApi.apisauce.setBaseURL(baseUrl);
	shopApi.apisauce.setBaseURL(baseUrl);
	platformApi.apisauce.setBaseURL(baseUrl);
	challengesApi.apisauce.setBaseURL(baseUrl);
	pokeApi.apisauce.setBaseURL(baseUrl);
	subjectApi.apisauce.setBaseURL(baseUrl);
	topicApi.apisauce.setBaseURL(baseUrl);
	statisticApi.apisauce.setBaseURL(baseUrl);
	studentApi.apisauce.setBaseURL(baseUrl);
}

export default function createRootSaga(extraSagas = []) {
	return function* root() {
		/* ------------- API ------------- */
		authApi = AuthAPI.create();
		userApi = UserAPI.create();
		gameApi = GameAPI.create();
		shopApi = ShopAPI.create();
		platformApi = PlatformApi.create();
		challengesApi = ChallengesAPI.create();
		pokeApi = PokeAPI.create();
		subjectApi = SubjectApi.create();
		topicApi = TopicApi.create();
		statisticApi = StatisticApi.create();
		studentApi = StudentApi.create();

		yield all([
			...extraSagas,
			takeLatest(StartupTypes.STARTUP, startup),
			takeLatest(StartupTypes.STARTUP_TEACHER, startupTeacher),
			takeLatest(AuthTypes.LOGIN_REQUEST, login, authApi, null, Environment.getPlatform(), Environment.getPlatform()),
			takeLatest(AuthTypes.LOGIN_TEACHER_REQUEST, loginTeacher, authApi),
			takeLatest(AuthTypes.REQUEST_PASSWORD_CODE_REQUEST, requestPasswordCode, authApi),
			takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword, authApi),
			takeLatest(AuthTypes.LOGOUT, logout),
			takeLatest(AuthTypes.DELETE_USER, deleteUserAccount, authApi),
			takeLatest(MasterTablesTypes.MASTER_TABLES_REQUEST, getMasterTables, platformApi),
			takeLatest(MasterTablesTypes.COUNTRIES_REQUEST, getCountries, platformApi),
			takeLatest(UserTypes.FATHER_REGISTRATION_REQUEST, registerFather, userApi),
			takeLatest(UserTypes.FATHER_UPDATE_REQUEST, updateFather, userApi),
			takeLatest(UserTypes.FATHER_DELETE_REQUEST, deleteFather, userApi),
			takeLatest(UserTypes.CHILD_UPDATE_REQUEST, updateChild, userApi),
			takeLatest(UserTypes.CHILD_REGISTRATION_REQUEST, registerChild, userApi),
			takeLatest(UserTypes.CHILD_DELETE_REQUEST, deleteChild, userApi),
			takeLatest(UserTypes.UPDATE_AVATAR_REQUEST, updateAvatar, userApi),
			takeLatest(UserTypes.PROFILE_REQUEST, getUser, userApi),
			takeLatest(GameTypes.UPDATE_AFTER_GAME_REQUEST, updateAfterGame, userApi),
			takeLatest(GameTypes.GET_TROPHIES_REQUEST, getTrophies, gameApi),
			takeLatest(GameTypes.GET_FRIENDS_REQUEST, getFriends, userApi),
			takeLatest(GameTypes.GET_FRIENDS_REQUEST_SEND_REQUEST, getFriendsRequestSend, userApi),
			takeLatest(GameTypes.SEARCH_FRIENDS_REQUEST, searchFriends, userApi),
			takeLatest(ShopTypes.GET_LIST_REQUEST, getList, shopApi),
			takeLatest(ShopTypes.GET_INFO_NEUROS_REQUEST, getInfoNeuros, shopApi),
			takeLatest(ShopTypes.BUY_OBJECT_REQUEST, buyObject, shopApi),
			takeLatest(ShopTypes.USE_OBJECT_REQUEST, useObject, shopApi),
			takeLatest(ShopTypes.DEL_OBJECT_REQUEST, delObject, shopApi),
			takeLatest(UserTypes.FRIENDSHIP_REQUEST_REQUEST, friendshipRequest, userApi),
			takeLatest(UserTypes.FRIENDSHIP_ACCEPT_REQUEST, friendshipAccept, userApi),
			takeLatest(UserTypes.FRIENDSHIP_REJECT_REQUEST, friendshipReject, userApi),
			takeLatest(UserTypes.FRIENDSHIP_DELETE_REQUEST, friendshipDelete, userApi),
			takeLatest(UserTypes.MARK_MESSAGE_AS_READ_REQUEST, markMessageAsRead, userApi),
			takeLatest(UserTypes.MARK_NOTIFICATION_AS_READ_REQUEST, markNotificationAsRead, userApi),
			takeLatest(UserTypes.UPDATE_COURSE_REQUEST, updateCourse, userApi),
			takeLatest(RankingTypes.RANKING_FRIENDS_REQUEST, getFriendsRanking, gameApi),
			takeLatest(RankingTypes.RANKING_GLOBAL_REQUEST, getGlobalRanking, gameApi),
			takeLatest(ChallengesTypes.CHALLENGES_PENDING_REQUEST, getPendingChallenges, challengesApi),
			takeLatest(ChallengesTypes.CHALLENGE_FRIEND_REQUEST, challengeFriend, challengesApi),
			takeLatest(ChallengesTypes.CHALLENGE_RANDOM_REQUEST, challengeRandom, challengesApi),
			takeLatest(ChallengesTypes.CHALLENGE_RESULT_REQUEST, getChallengeResult, challengesApi),
			takeLatest(ChallengesTypes.REJECT_CHALLENGE_REQUEST, rejectChallenge, challengesApi),
			takeLatest(PokesTypes.SEND_POKE_REQUEST, sendPoke, pokeApi),
			takeLatest(PokesTypes.GET_POKES_REQUEST, getPokes, pokeApi),
			takeLatest(PokesTypes.GET_ALL_POKES_REQUEST, getAllPokes, pokeApi),
			takeLatest(PokesTypes.CLEAR_POKES_REQUEST, clearPokes, pokeApi),
			takeLatest(SubjectTypes.SUBJECT_REQUEST, getSubject, subjectApi),
			takeLatest(SubjectTypes.TOPIC_STATUS_UPDATE_REQ, updateTopicStatus, subjectApi),
			takeLatest(TopicTypes.TOPIC_REQUEST, getTopic, topicApi),
			takeLatest(TopicTypes.MISSION_STATUS_UPDATE_REQ, updateMissionStatus, topicApi),
			takeLatest(StatisticTypes.STATISTIC_REQUEST, getStatistics, statisticApi),
			takeLatest(StudentTypes.STUDENT_DELETE_REQUEST, deleteStudent, studentApi),
		])
	}
}
