import {connect} from "react-redux";
import {compose} from "redux";

function ReadyGate(props) {
  return !props.auth || props.masterTables.tables ? props.children : null;
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
const mapStateToProps = state => ({
  masterTables: state.masterTables,
  auth: state.auth.accessToken,
});
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(ReadyGate);
