import { takeLatest } from '@redux-saga/core/effects';
import StripeAPI from 'academons-shared-library/src/Services/StripeApi';
import { WEB_STARTUP } from '../containers/App/constants';
import { webStartup } from '../containers/App/saga';
import { PRODUCTS_REQUEST, SUBSCRIBE_REQUEST } from './products/constants';
import { productsRequest, subscribe } from './products/saga';

export default function getAppSagas() {
  const stripeApi = StripeAPI.create();

  const appSagas = [
    takeLatest(WEB_STARTUP, webStartup),
    takeLatest(PRODUCTS_REQUEST, productsRequest, stripeApi),
    takeLatest(SUBSCRIBE_REQUEST, subscribe, stripeApi),
  ];
  return appSagas;
}
