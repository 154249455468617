import apisauce from 'apisauce/dist/apisauce';
import NetworkConfig from '../Config/NetworkConfig';
import AppConfig from '../Config/AppConfig';
import Environment from '../Config/Environment';

export default (country, lang) => {
	let api = apisauce.create({
		baseURL: NetworkConfig.API_BASE,
		headers: NetworkConfig.DEFAULT_HEADERS,
		timeout: NetworkConfig.DEFAULT_NETWORK_TIMEOUT,
		...NetworkConfig.AXIOS_CONFIG,
	});
	api.addAsyncRequestTransform((request) => {
		return new Promise((resolve) => {
			request.headers['Accept-Language'] = Environment.getLanguage();
			request.headers['X-Client-Version'] = "3";
			if(!request.headers['X-Country']) {
				request.headers['X-Country'] = Environment.getCountry();
			}
			if(Environment.getDeviceId()) {
				request.headers["X-Device"] = Environment.getDeviceId();
			}

			if (AppConfig.parentToken) {
				request.headers.ACA_CHILD_TOKEN = AppConfig.playerToken;
				request.headers.ACA_PARENT_TOKEN = AppConfig.parentToken;
			}

			if (AppConfig.teacherToken) {
				request.headers.ACA_TEACHER_TOKEN = AppConfig.teacherToken;
			}

			resolve();
		});
	});
	api.addMonitor(Environment.getApiMonitor());
	return api;
};
