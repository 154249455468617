import standardApi from './standardApi';

const create = () => {
  const api = standardApi();
  // only for eduhispania App
  const deleteStudent = (alumnid) =>
    api.post('/hispania/alumno/delete', { alumnid });

  return {
    apisauce: api,
    deleteStudent,
  };
};

export default {
  create,
};
