import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
import Colors from '../Config/ClassColorCodes';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	topicRequest: ['classID', 'topicID'],
	topicSuccess: ['payload'],
	topicFailure: ['errorMessage'],
	topicReset: null,
	missionStatusUpdateReq: ['classID', 'missionID', 'status'],
	missionStatusUpdateSuccess: ['payload'],
	missionStatusUpdateFailure: ['errorMessage'],
})

export const TopicTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetching: false,
	error: null,
	errorMessage: "",
	id: null,
	name: '',
	averageOfAboveAverage: 0,
	pieChart: [],
	table: [],
	minMaxChart: [],
	missions: [],
	toggleChart: [],
	updatingMission: false,
})


/* ------------- Reducers ------------- */

export const missionStatusUpdateReq = (state, {classID, missionID}) => state.merge({updatingMission: true, error: false, missionID, classID})

export const missionStatusUpdateSuccess = (state, action) => {
	const {payload} = action;
	const {missionID, status} = payload;

	return state.merge({
		updatingMission: false,
		error: null,
		toggleChart: JSON.parse(JSON.stringify(state.toggleChart)).map(item => {
			if (missionID === item.id) {
				item.status = !!status
			}
			return item
		}),
		errorMessage: ""})
}

export const missionStatusUpdateFailure = (state, action) =>
	state.merge({updatingMission: false, error: true, errorMessage: 'MISSION_STATUS_UPDATE_FAILED'})

export const topicRequest = (state, {classID, topicID}) => state.merge({fetching: true, error: false, classID, topicID})

export const topicSuccess = (state, action) => {
	const {payload} = action
	const {id, name, levelOfPrecission, individualAverageScore} = payload.info.topicDetails;

	return state.merge({
		fetching: false,
		error: null,
		id,
		name,
		pieChart: Object.keys(levelOfPrecission).map(key => ({
			angle: levelOfPrecission[key],
			label: levelOfPrecission[key] > 0 ? `${levelOfPrecission[key]}%` : '',
			color: Colors[key].color,
			legend: Colors[key].label,
		})),
		averageOfAboveAverage: levelOfPrecission.veryhigh + levelOfPrecission.high,
		table: individualAverageScore,
		missions: payload.info.missions,
		toggleChart: payload.info.missions.map(({ id, name, status, students }) => {
			const totalPlayed = students.length ? students.reduce(
				(n, val) => n + (val.bplayed > 0 ? 1 : 0),
				0,
			) : 0;
			const avg = students.length ? students.reduce((a, b) => ({
				averageScore: a.averageScore + b.averageScore,
			})).averageScore : 0;

			return {
				label: name,
				id,
				status: !!status,
				students,
				value: totalPlayed ? avg / totalPlayed : null,
				tag: `${totalPlayed}/${students.length}`,
			}
		}),
		// minMaxChart: payload.info.missions.map(({ name, id, students }) => {
		// 	const totalPlayed = students.reduce(
		// 		(n, val) => n + (val.bplayed > 0 ? 1 : 0),
		// 		0,
		// 	);
		// 	const avg = students.reduce((a, b) => ({
		// 		averageScore: a.averageScore + b.averageScore,
		// 	})).averageScore;
		//
		// 	return {
		// 		id,
		// 		students,
		// 		label: name,
		// 		value: totalPlayed ? avg / totalPlayed : null,
		// 		tag: `${totalPlayed}/${students.length}`,
		// 	};
		// }),
		errorMessage: ""})
}

export const topicFailure = (state, action) =>
	state.merge({...INITIAL_STATE, fetching: false, error: true, errorMessage:action.errorMessage})

export const topicReset = () => INITIAL_STATE

export const reducer = createReducer(INITIAL_STATE, {
	[Types.TOPIC_REQUEST]: topicRequest,
	[Types.TOPIC_SUCCESS]: topicSuccess,
	[Types.TOPIC_FAILURE]: topicFailure,
	[Types.TOPIC_RESET]: topicReset,
	[Types.MISSION_STATUS_UPDATE_REQ]: missionStatusUpdateReq,
	[Types.MISSION_STATUS_UPDATE_SUCCESS]: missionStatusUpdateSuccess,
	[Types.MISSION_STATUS_UPDATE_FAILURE]: missionStatusUpdateFailure,
})
