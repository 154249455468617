import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	messageShow: ['message', 'title'],
	messageClear: []
})

export const MessageTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	message: null,
	title: null,
})

/* ------------- Reducers ------------- */

// request the data from an api
export const show = (state, {message,title}) =>
	state.merge({message,title})

// successful api lookup
export const clear = (state, action) => {
	return state.merge({message:null,title:null})
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.MESSAGE_SHOW]: show,
	[Types.MESSAGE_CLEAR]: clear
})
