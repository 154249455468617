/* eslint-disable */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Configure Environment
import Environment from 'academons-shared-library/src/Config/Environment';
import AppEnvironment from './config/AppEnvironment';

Environment.init(AppEnvironment);


// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Modal from 'react-modal';
import {ConnectedRouter} from 'connected-react-router';
import {PersistGate} from 'redux-persist/integration/react'
import history from 'utils/history';
import ReadyGate from './lib/ReadyGate'

import './config/ReactotronConfig';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';


// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

import configureStore from './configureStore';

// Import i18n messages
import {translationMessages} from './i18n';
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import AppConfig from "./config/AppConfig";
import packageJson from "../package.json";

// Create redux store with history

const initialState = {};
const {store, persistor} = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

Modal.setAppElement(MOUNT_NODE);

Bugsnag.start({
  apiKey: AppConfig.bugsnagKey,
  appVersion: packageJson.version,
  plugins: [new BugsnagPluginReact()]
})
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

console.log(`Academons Web version: ${packageJson.version}`);

const render = messages => {
  ReactDOM.render(
    <ErrorBoundary FallbackComponent={() => null}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <ReadyGate>
                <App/>
              </ReadyGate>
            </ConnectedRouter>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/es.js'),
      ]),
    ) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
