/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectCourses = state => state.masterTables.tables.cursos;
const selectCurrentCourseId = state => state.game.player?.cursoid;

const makeSelectCurrentCourse = () =>
  createSelector(
    [selectCourses, selectCurrentCourseId],
    (courses, courseId) => courses.filter(c => c.curso.id == courseId)[0],
  );

export { selectCourses, selectCurrentCourseId, makeSelectCurrentCourse };
