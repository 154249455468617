/* eslint-disable */
import standardApi from "./standardApi";


const create = () => {
	const api = standardApi();
	const requestTopic = (classID, topicID) => api.get(`/colegios/teacher/class/${classID}/topic/${topicID}`)
	const missionToggle = (classID, missionID, status) => api.put(`/colegios/teacher/class/${classID}/mission-status/${missionID}`, {status})
	return {
		apisauce: api,
		requestTopic,
		missionToggle
	}
}

export default {
	create
}
