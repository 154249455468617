/* eslint-disable prettier/prettier */
import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	startupTeacher: null,
  	startup: ['postAction']
})

export const StartupTypes = Types
export default Creators
