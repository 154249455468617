/**
 *
 * GameMenu
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import Immutable from 'seamless-immutable';

// Images
import GameActions from 'academons-shared-library/src/Redux/GameRedux';
import UserActions from 'academons-shared-library/src/Redux/UserRedux';
import AuthActions from 'academons-shared-library/src/Redux/AuthRedux';
import Environment from 'academons-shared-library/src/Config/Environment';
import ChallengesActions from 'academons-shared-library/src/Redux/ChallengesRedux';
import PokesActions from 'academons-shared-library/src/Redux/PokesRedux';
import chev from './images/flecha-desplegable.png';
import iconAmigos from './images/icono-amigos.png';
import iconTrofeos from './images/icono-trofeos.png';
import parents from './images/icono-padres.png';

import Img from '../../components/Img';
import Text, { presets } from '../../components/Text';
import Colors from '../../utils/Colors';
import { Pusher } from '../../components/Layouts';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import Score from './Score';
import Pet from '../../components/Pet';
import { makeSelectCurrentCategory, selectVisibleCategories } from '../../selectors/categories';
import { makeSelectCurrentCourse } from '../../selectors/couse';
import IMAGES from '../../images';
import WindowSize from '../../components/WindowSize';
import Modal from '../../components/Modal';
import ParentVerificationBox from '../../components/ParentVerificationBox';
import TitledBox from '../../components/TitledBox';
import SelectCategoriesStep from '../RegistrationPage/SelectCategoriesStep';
import BuyModal, { CategoryLimitHeader, GoProHeader } from '../BuyModal';
import BuyModalPopup from '../../components/BuyModalPopup';

function generateEvents(setFunction) {
  const fn = debounce(setFunction, 100);
  return {
    onMouseOver: () => fn(true),
    onMouseOut: () => fn(false),
    onClick: e => {
      e.preventDefault();
      fn(true);
    },
  };
}

function GameMenu(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    player,
    categoryId,
    category,
    color,
    listado,
    curso,
    isPro,
    toParentProfile,
    users,
    hasFriendsRequests,
    isSchoolProfile,
  } = props;

  const [gotoBuyModal, setGotoBuyModal] = useState(false);
  const [categoriesOpened, setCategoriesOpened] = useState(false);
  const [optionsOpened, setOptionsOpened] = useState(false);
  const [parentVerified, setParentVerified] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesModalOpen, setSelectedCategoriesModalOpen] = useState(false);
  const [buyModalIsOpen, setBuyModalIsOpen] = useState(false);

  const translate = termn => intl.formatMessage({ id: termn });
  const catClass = categoriesOpened ? 'opened' : '';
  const optionsClass = optionsOpened ? 'opened' : '';
  const intl = useIntl();
  const categoriesEvents = generateEvents(setCategoriesOpened);
  const optionsEvents = generateEvents(setOptionsOpened);

  const closeModalRequest = () => {
    setBuyModalIsOpen(false);
  };
  useEffect(() => {
    if (props.selectedCategories) {
      setSelectedCategories(Immutable.asMutable(props.selectedCategories));
    }
  }, [props.selectedCategories]);
  const verify = pass => setParentVerified(pass === props.password);
  const updateCategories = val => {
    props.update(props.player.id, props.player.nombre, val.join());
    setSelectedCategoriesModalOpen(false);
  };
  const selectCategory = (id, assigned) => {
    if (id === categoryId) return;
    if (!assigned) {
      setSelectedCategoriesModalOpen(true);
    } else if (assigned) {
      props.selectCategory(id);
    }
  };
  if (!player || !category) return <div />;

  const locale = Environment.getLanguage().split('-')[0];
  return (
    <WindowSize minSize={1024} view={<></>}>
      <Container className="menu-header">
        <Header>
          <Dropdown {...categoriesEvents}>
            <CategoryIcon src={IMAGES.categories[locale][category.clasetrofeo]} alt="" />
            <Text textColor={Colors.black}>{category.nombre}</Text>
            <Img src={chev} alt={translate('gameMenu.catego')} />
          </Dropdown>
          <PusherThird />
          <Score
            color={color}
            level={category.nivel}
            points={category.puntos}
            neuros={category.neuros}
          />
          <Pusher />
          <ImageButton unstyled navigation href="/FriendsManagerScreen">
            <Img src={iconAmigos} alt="" />
            {/* <Text textColor={Colors.black} preset="textS" txt="gameMenu.friends" /> */}
            {hasFriendsRequests && <FriendsPendingDot />}
          </ImageButton>
          <Separator />
          <ImageButton unstyled navigation href="/TrophiesScreen">
            <Img src={iconTrofeos} alt="" />
            {/* <Text textColor={Colors.black} preset="textS" txt="gameMenu.trophy" /> */}
          </ImageButton>
          <Separator />
          <Dropdown {...optionsEvents}>
            <Circle>
              <Avatar size={29} model={player.avatar} />
            </Circle>
            <Text textColor={Colors.black} preset="textS">
              {player?.nombre}
            </Text>
            <Img src={chev} alt={translate('gameMenu.options')} />
          </Dropdown>
          {!isPro && (
            <ButtonGoPro xsmall isButton onClick={() => setGotoBuyModal(true)}>
              <Text tagName="h1" preset="title6" txt="gameMenu.goPro" />
            </ButtonGoPro>
          )}
        </Header>
        <CategorySelector className={catClass} {...categoriesEvents}>
          {/* eslint-disable-next-line no-unused-vars */}
          {listado.map((a, i) => {
            const { nombre, clasetrofeo, nivel, id } = a;
            const asignado = Boolean(a.misiones);
            return (
              <Button unstyled isButton onClick={() => selectCategory(id, asignado)} key={id}>
                <Category className={id === categoryId ? 'selected' : ''}>
                  {!asignado ? (
                    <CategoryEgg>
                      <Img src={IMAGES.categories.bloqued} alt={nombre} width="50" />
                    </CategoryEgg>
                  ) : (
                    <CategoryPet category={clasetrofeo} level={nivel} isSmall />
                  )}
                  <Text preset="textXS" textColor={Colors.black}>
                    {nombre}
                  </Text>
                </Category>
              </Button>
            );
          })}
        </CategorySelector>
        <Options className={optionsClass} {...optionsEvents}>
          <Users>
            {users.map(user => (
              <UserButton onClick={() => props.play(user)} key={user.id}>
                <UserCircle>
                  <Avatar size={29} model={user.avatar} />
                </UserCircle>
                <UserName preset="textS">{user.nombre}</UserName>
              </UserButton>
            ))}
          </Users>
          {!isSchoolProfile && (
            <ParentsLink onClick={toParentProfile} unstyled txt="gameMenu.parentZone" />
          )}
          <OptionsLink unstyled txt="gameMenu.person" href="AvatarConfiguratorScreen" navigation />
          <Line />
          <OptionsLink unstyled txt="gameMenu.close" onClick={props.logout} />
        </Options>
        <Modal
          modalIsOpen={selectedCategoriesModalOpen}
          onRequestClose={() => setSelectedCategories(false)}
        >
          <ModalContainer>
            {!parentVerified ? (
              <ParentVerificationBox title="gameHome.modalParentTitle" onSend={verify} />
            ) : (
              <TitledBox txt="gameHome.modalParentTitle">
                <SelectCategoriesStep
                  hideMainTitle
                  multiSelect
                  tileSize={80}
                  buttonVisible
                  wrapper={CategoriesContainer}
                  subTitle="childProfile.selectSubjectTitle"
                  name={player.nombre}
                  data={curso.categorias}
                  onNext={updateCategories}
                  onUpdate={setSelectedCategories}
                  values={selectedCategories}
                />
              </TitledBox>
            )}
          </ModalContainer>
        </Modal>
        <Modal modalIsOpen={buyModalIsOpen} onRequestClose={() => setBuyModalIsOpen(false)}>
          <BuyModal header={<CategoryLimitHeader />} onRequestClose={closeModalRequest} />
        </Modal>
        <BuyModalPopup onOpen={gotoBuyModal} onClose={setGotoBuyModal} header={<GoProHeader />} />
      </Container>
    </WindowSize>
  );
}

GameMenu.propTypes = {
  dispatch: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    selectCategory(id) {
      // eslint-disable-next-line import/no-named-as-default-member
      dispatch(GameActions.selectCategory(id));
      dispatch(ChallengesActions.challengesPendingRequest());
    },
    update(id, name, categories) {
      // eslint-disable-next-line import/no-named-as-default-member
      dispatch(UserActions.childUpdateRequest(id, name, categories));
      dispatch(GameActions.getFriendsRequest());
    },
    logout() {
      // eslint-disable-next-line import/no-named-as-default-member
      dispatch(AuthActions.logout());
      dispatch(Environment.createNavigationAction('LoginScreen'));
    },
    toParentProfile() {
      dispatch(Environment.createNavigationAction('/ParentVerificationScreen?redirect=/ParentProfileScreen'));
      setTimeout(() => dispatch(GameActions.exitPlayer()), 10);
    },
    play(child) {
      dispatch(GameActions.clearSearch());
      dispatch(UserActions.clearFriendshipRequest());
      dispatch(GameActions.selectPlayer(child));
      dispatch(PokesActions.getPokesRequest(child.asignaturas_visibles[0].id));
      dispatch(GameActions.selectCategory(child.asignaturas_visibles[0].id));
      dispatch(Environment.createNavigationAction('GameHomeScreen'));
      dispatch(GameActions.getFriendsRequest());
    },
  };
}

const selectCurrentCategory = makeSelectCurrentCategory();
const selectCurrentCourse = makeSelectCurrentCourse();

const mapStateToProps = state => {
  if (!state.game.player || !state.masterTables.tables || state.game.player.asignaturas_visibles.length === 0) return {};
  try {
    const category = selectCurrentCategory(state);
    const categories = selectVisibleCategories(state);
    const curso = selectCurrentCourse(state);

    const { categorias } = curso;
    // eslint-disable-next-line camelcase
    const { asignaturas_visibles } = state.game.player;
    const listado = categorias.map(x => {
      let l = asignaturas_visibles.filter(y => y.id === x.id)[0];
      if (!l) l = x;
      l = l.asMutable();
      return l;
    });
    // console.log(listado);
    return {
      isPro: state.user.profile.info.es_pro,
      game: state.game,
      category,
      categories,
      listado,
      users: state.user.profile.hijos,
      categoryId: state.game.category,
      player: state.game.player,
      curso,
      hasFriendsRequests: !state.game.requests || state.game.requests.length > 0,
      selectedCategories: state.game.player
        ? state.game.player.asignaturas_visibles.map(x => x.id)
        : [],
      color: category ? Colors[category.clasetrofeo] : 'transparent',
      theme: category?.clasetrofeo,
      password: state.auth.password,
      isSchoolProfile: state.user.profile.info ? state.user.profile.info.version_coles : null,
    };
  } catch (e) {
    return {}
  }
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(GameMenu);

const Separator = styled.div`
  height: 60%;
  background-color: var(--very-light-pink);
  width: 1px;
`;
const ButtonGoPro = styled(Button)`
  padding: 10px;
`;
const Container = styled.div`
  position: relative;
`;
const Header = styled.header`
  height: var(--header-height);
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--very-light-pink-two);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  position: relative;
  z-index: 10;
`;

const Dropdown = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: 0 none;
  border: 0 none;
  height: 100%;

  > * {
    margin: 0 6px;
  }
`;
const ImageButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  > * {
    margin: 0 6px;
  }

  margin: 0 20px;
`;
const Circle = styled.div`
  background-color: var(--very-light-pink-three);
  border-radius: 50%;
  border: 1px solid var(--very-light-pink-two);
  padding: 8px;
`;
const PusherThird = styled.div`
  flex: 0.3 1 auto;
`;
const CategorySelector = styled.ul`
  background-color: var(--white);
  height: 235px;
  width: 100%;
  position: absolute;
  top: -194px;
  z-index: 5;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: transform 300ms ease-out;
  box-shadow: 0 5px 7.8px 0.2px rgba(2, 2, 1, 0.15);
  &.opened {
    transform: translateY(265px);
  }
`;
const Category = styled.li`
  width: 147px;
  height: 195px;
  padding: 52px 0 10px 0;
  object-fit: contain;
  border-radius: 20px;
  border: solid 1px transparent;
  text-align: center;
  transition: opacity 200ms linear;
  &.selected {
    border-color: var(--very-light-pink-two);
    background-color: var(--very-light-pink-three);
  }
  &:hover:not(.selected) {
    opacity: 0.6;
    cursor: pointer;
  }
`;
const CategoryPet = styled(Pet)`
  width: 140px;
  margin-bottom: 5px;
  transform-origin: bottom center;
  transition: transform 200ms ease;
  :hover {
    transform: scale(1.2);
  }
`;
const CategoryEgg = styled.div`
  width: 140px;
  height: 95px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto 5px;
  transform-origin: bottom center;
  transition: transform 200ms ease;
  :hover {
    transform: scale(1.2);
  }
`;
const Options = styled.nav`
  width: 208px;
  object-fit: contain;
  box-shadow: 0 5px 7.8px 0.2px rgba(2, 2, 1, 0.15);
  background-color: var(--white);
  position: absolute;
  top: 71px;
  right: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transform: translateY(-105%);
  transition: transform 300ms ease-out;
  &.opened {
    transform: translateY(0);
  }
`;
const ParentsLink = styled(Button)`
  ${presets.textS};
  color: ${Colors.white};
  height: 51px;
  padding: 11px 16px;
  object-fit: contain;
  border-radius: 20px;
  background-color: #99cc66;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 88%;
  padding-left: 55px;
  :hover {
    color: ${Colors.white};
    text-decoration: none;
  }
  &:after {
    content: '';
    background: url(${parents}) no-repeat left center;
    position: absolute;
    left: 9px;
    top: 12px;
    width: 50px;
    height: 28px;
  }
  margin: 0 auto;
`;
const OptionsLink = styled(Button)`
  ${presets.textS};
  color: ${Colors.black};
  margin: 16px 20px;
`;
// const ParentsImage = styled(Img)`
//   margin-right: 10px;
// `;
const Line = styled.div`
  background-color: #d6d6d6;
  height: 1px;
  width: 100%;
`;
const CategoryIcon = styled(Img)`
  width: 50px;
`;

const ModalContainer = styled.div`
  max-width: 610px;
`;

const CategoriesContainer = styled.div`
  width: 610px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;
const Users = styled.div`
  background-color: ${Colors.veryLightPinkThree};
  padding: 20px 15px;
  width: 100%;
  margin-bottom: 20px;
`;
const UserButton = styled(Button).attrs(() => ({
  unstyled: true,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;
const UserName = styled(Text)`
  flex: 1 1 auto;
  color: ${Colors.black};
  margin-left: 12px;
`;
const UserCircle = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${Colors.white};
  border: solid 1px ${Colors.veryLightPinkTwo};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FriendsPendingDot = styled.div`
  width: 14px;
  height: 14px;
  border: solid 1px var(--white);
  background-color: #ff3c3c;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: 48px;
`;
